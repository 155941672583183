import { api } from '../providers/api/index'

const cnaesServices = () => {
  const list = async ({
    // TODO: Resolver paginação
    // offset,
    // perPage,
    value
  } = {}) => {
    try {
      const response = await api.get(`/accreditation/cnaes`, {
        params: {
          // offset: offset ?? 1,
          // first: perPage ?? 10,
          value: value ?? undefined
        }
      })
      return response.data
    } catch (err) {
      throw err
    }
  }

  const edit = async data => {
    const body = {
      mcc: data.mcc,
      cnae: data.cnae,
      riskCategory: data.riskCategory,
      grupo: data.grupo,
      subGrupo: data.subGrupo,
      nomeMCC: data.nomeMCC,
      cartaoNaoPresente: data.cartaoNaoPresente,
      maquinaPresencial: data.maquinaPresencial,
      compraOnline: data.compraOnline,
      inativado: data.inativado
    }
    try {
      const response = await api.put(`/accreditation/cnaes/${data._id}`, body)
      return response
    } catch (err) {
      throw err
    }
  }

  const inativar = async ({ _id, inativado }) => {
    const body = {
      inativado
    }
    try {
      const response = await api.put(`/accreditation/cnaes/${_id}`, body)
      return response
    } catch (err) {
      throw err
    }
  }

  return { list, edit, inativar }
}

export default cnaesServices
