import { api, getHeaders } from './index'

export const getTechnologies = async () => {
  try {
    const response = await api.get(`/fiserv/capture-solutions`, {
      headers: getHeaders()
    })
    return response.data
  } catch (err) {
    throw err
  }
}
