import React from 'react'
import * as ModalComponents from './components'
import { MdClose } from 'react-icons/md'

const Modal = ({ isVisible, closeModal, title, children, onConfirm }) => {
  return (
    <ModalComponents.Container isVisible={isVisible}>
      <ModalComponents.Body>
        <ModalComponents.Header>
          <ModalComponents.CloseButton
            onClick={() => {
              closeModal()
            }}
          >
            <MdClose size={24} />
          </ModalComponents.CloseButton>
          <ModalComponents.Title> {title} </ModalComponents.Title>
        </ModalComponents.Header>

        <ModalComponents.Content>{children}</ModalComponents.Content>
        <ModalComponents.Footer>
          <ModalComponents.FooterButton
            onClick={() => {
              closeModal()
            }}
          >
            Fechar
          </ModalComponents.FooterButton>
          <ModalComponents.FooterButton onClick={onConfirm} primary>
            OK
          </ModalComponents.FooterButton>
        </ModalComponents.Footer>
      </ModalComponents.Body>
    </ModalComponents.Container>
  )
}

export default Modal
