import styled from 'styled-components'

const ItemsWrapper = styled.div`
  margin-top: 2.5em;
`

const Item = styled.div`
  background-color: white;
  padding: 2em;
  width: 50em;
  box-shadow: 0px 0px 8px -1px rgba(209, 209, 209, 1);
  margin-bottom: 2em;
`

const ItemStatusWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
`

const ItemInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.3em;
  border-bottom: 1px solid #f4f4f4;
`

const ItemInfo = styled.div`
  display: flex;
  margin-top: 1em;
  margin-bottom: 1em;
  align-items: center;
`

const ItemInfoLabel = styled.div`
  margin-right: 1em;
  font-weight: bold;
`

const AccreditationContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* padding: 5em; */
  /* width: 50em; */
`

const AccreditantionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
const AccreditationItem = styled.div``

const AccreditantionDescriptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin-bottom: 2em;
`

const AccreditationDescription = styled.div``

export {
  AccreditantionDescriptionWrapper,
  ItemsWrapper,
  Item,
  ItemInfo,
  ItemInfoLabel,
  ItemInfoWrapper,
  ItemStatusWrapper,
  AccreditationContent,
  AccreditantionWrapper,
  AccreditationItem,
  AccreditationDescription
}
