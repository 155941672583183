import React, { useContext, useState } from 'react'
import { RegisterEcContext } from '../../../Context/register-ec-provider'
import AppLayout from '../../../components/app-layout/app-layout'
import {
  CardContent,
  CardsWrapper,
  Container,
  Content,
  Header,
  StepCard,
  StepContainerCard
} from '../styles'
import { Spin, message } from 'antd'
import { CheckCircleFilled, LoadingOutlined } from '@ant-design/icons'

export const MainStepRegister = () => {
  const { step, steps, changeStep, loading } = useContext(RegisterEcContext)
  const [messageApi, contextHolder] = message.useMessage()

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

  const StepRegister = steps[step]

  if (!StepRegister) {
    return <div>Etapa não encontrada</div>
  }

  function isActive(stepRegister) {
    return stepRegister.active || stepRegister.finished
  }

  return (
    <AppLayout>
      <Spin className="page-loader" indicator={antIcon} spinning={loading}>
        {contextHolder}
        <Container>
          <Header>
            <StepContainerCard>
              <CardsWrapper>
                {Object.keys(steps).map((stepRegister, index) => {
                  const currentStep = steps[stepRegister]
                  return (
                    <StepCard
                      key={index}
                      isActive={isActive(steps[stepRegister])}
                      onClick={() => changeStep({ step: stepRegister })}
                    >
                      <currentStep.Icon
                        style={{ fontSize: '24px', color: '#606060' }}
                      />
                      <CardContent>
                        <p>
                          {steps[stepRegister].finished && (
                            <CheckCircleFilled style={{ color: 'green' }} />
                          )}{' '}
                          {steps[stepRegister].title}
                        </p>
                        <small>{steps[stepRegister].subtitle}</small>
                      </CardContent>
                      <span></span>
                    </StepCard>
                  )
                })}
              </CardsWrapper>
            </StepContainerCard>
          </Header>
          <Content>
            <StepRegister.Component messageApi={messageApi} />
          </Content>
        </Container>
      </Spin>
    </AppLayout>
  )
}
