import { api, getHeaders } from './index'

export const getBanks = async () => {
  try {
    const response = await api.get(`/fiserv/banks`, {
      headers: getHeaders()
    })

    response.data.push({
      bankNumber: '450',
      bankName: 'FITBANK'
    })

    return response.data
  } catch (err) {
    return []
  }
}
