import React, { useState } from 'react'

import { Button, Checkbox, Form, Spin, message } from 'antd'
import { PersonalInfo } from '../01-personal-info/index'
import ProposalSuccess from '../15-proposal-success/index'
import { sendProposal } from '../../providers/api/sendProposal'
import { LoadingOutlined } from '@ant-design/icons'
import AppLayout from '../../components/app-layout/app-layout'

const Terms = props => {
  const { previousStepData } = props

  const [nextSteps, setNextStep] = useState(false)
  const [prevStep, setPrevStep] = useState(false)
  const [formData, setFormData] = useState({})
  const [form] = Form.useForm()
  const [messageApi, contextHolder] = message.useMessage()

  const [spin, setSpint] = useState(false)
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

  const [deliveryExtract, setDeliveryExtract] = useState(false)

  const [termCheck, setTermCheck] = useState(false)

  const handleOnFinish = async _data => {
    setSpint(true)

    const payload = {
      ...previousStepData,
      optInTermsAcceptance: termCheck
    }

    if (
      payload.captureSolutions[0].serviceId === '334' ||
      payload.captureSolutions[0].serviceId === '399'
    ) {
      payload.workingDays = null
      payload.ecommerceDetails = {
        typeApplication: 'BOTH',
        functionalities: ['LINK_PAYMENT'],
        splitDetails: '',
        ecType: '',
        gatewayOrIntegratorDetails: {
          hasGatewayOrIntegrator: false,
          name: ''
        },
        antifraudDetails: {
          hasAntiFraudService: false,
          name: ''
        },
        howLearAboutOpportunities: [''],
        productOrServiceDetails: '',
        platformDetails: {
          link: ''
        },
        maxValueOfTransaction: 0,
        receiveInternationalCards: true,
        complementaryInformations: {
          stockCheckDetails: 'SIM',
          securityCheckDetails: 'SIM',
          documentCheckDetails: 'SIM',
          transactionRiskAware: true,
          agreeAdvancedReservationWithNonPresentCards: true
        }
      }
    }

    setFormData(payload)

    try {
      const response = await sendProposal(payload)

      setNextStep(true)
      setSpint(false)
    } catch (err) {
      setSpint(false)
      messageApi.open({
        type: 'error',
        content: 'Erro ao enviar a proposta! Por favor, tente novamente!'
      })
    }
  }

  return (
    <>
      <>
        {!nextSteps && !prevStep && !spin && (
          <AppLayout>
            <div
              className="form-container"
              style={{ width: 800, marginTop: '2.5em' }}
            >
              {contextHolder}

              <div
                style={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontSize: '14pt'
                }}
              >
                Declaração de Aceitação aos Termos e Condições Para Uso de
                Informações e Dados Pessoais
              </div>
              <div
                style={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontSize: '14pt'
                }}
              >
                Eu declaro que:
              </div>
              <div>
                <p>
                  <strong>i.</strong> As informações preenchidas neste
                  formulário foram devidamente conferidas por mim, estão
                  corretas e são verdadeiras;
                </p>
                <p>
                  <strong>ii.</strong> Obtive o consentimento expresso e
                  inequívoco do cliente para a coleta, processamento, utilização
                  e eventual compartilhamento e/ou transferência, inclusive com
                  terceiros, das informações e Dados Pessoais ora coletados para
                  as finalidades e propósitos descritos no contrato de
                  credenciamento, incluindo informações financeiras e dados
                  pessoais de sócios e representantes legais, conforme abaixo:
                </p>
                <p>
                  <strong>a)</strong> As informações e Dados Pessoais contidas
                  neste formulário poderão ser utilizados, acessados ou
                  transferidos por funcionários e departamentos da X-Pay para
                  fins de avaliações financeiras, reputacional, consultas a
                  bases e bureaus de crédito e dados públicos e para
                  oferecimento de produtos e serviços, de acordo com seu perfil.
                </p>
                <p>
                  <strong>b)</strong> As informações e Dados Pessoais poderão
                  ser acessados ou transferidos de/para trabalhadores
                  não-empregados, contratados, fornecedores, auditores e/ou
                  outros terceiros autorizados pela X-Pay para cumprir este
                  propósito particular (coletivamente “Terceiros Autorizados”).
                </p>
                <p>
                  <strong>c)</strong> As informações e Dados Pessoais serão
                  mantidos apenas na medida e enquanto forem necessários para
                  cumprir corretamente os requisitos relacionados aos propósitos
                  específicos, como requerido por Lei.
                </p>
                <p>
                  <strong>d)</strong> Dados Pessoais somente serão divulgados
                  entre e/ou transferidos para associados da X-Pay, Terceiros
                  Autorizados ou outros destinatários devidamente autorizados
                  que tenham necessidade de acessar tais dados para cumprir
                  propósitos específicos, execução de contrato ou cumprimento de
                  obrigação legal. Para este propósito, e conforme legislação em
                  vigor, define-se Dado Pessoal como “toda informação
                  relacionada a pessoa identificada ou identificável" Deste
                  modo, minha assinatura eletrônica neste formulário corresponde
                  à confirmação e autorização para efetivação do credenciamento,
                  a pedido do cliente acima descrito, bem como utilização das
                  informações e dados conforme acima e contrato de
                  credenciamento disponibilizado ao cliente eletronicamente.
                </p>
              </div>
              <Form
                useRef
                form={form}
                name="basic"
                // style={{ width: 800 }}
                onFinish={handleOnFinish}
              >
                <Form.Item name="term_agree" label="">
                  <Checkbox
                    checked={termCheck}
                    onChange={e => setTermCheck(e.target.checked)}
                    style={{ lineHeight: '32px' }}
                  >
                    Concordo
                  </Checkbox>
                </Form.Item>
                <Form.Item name="delivery_extract_edi_agree" label="">
                  <Checkbox
                    checked={deliveryExtract}
                    onChange={e => setDeliveryExtract(e.target.checked)}
                    style={{ lineHeight: '32px' }}
                  >
                    O estabelecimento autoriza a X-pay a enviar o Extrato EDI
                    para a CONCILIADORA
                  </Checkbox>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!termCheck}
                  >
                    Enviar Proposta
                  </Button>
                </Form.Item>

                <Form.Item>
                  <Button danger onClick={() => setPrevStep(true)}>
                    cancelar
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </AppLayout>
        )}
      </>
      {contextHolder}
      {spin && (
        <AppLayout>
          <Spin indicator={antIcon} spinning={spin} />
        </AppLayout>
      )}
      {nextSteps && <ProposalSuccess previousStepData={formData} />}

      {prevStep && <PersonalInfo formDataPreLoaded={previousStepData} />}
    </>
  )
}

export default Terms
