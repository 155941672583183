import styled from 'styled-components'

export const AppWrapper = styled.div`
  display: flex;
  min-width: 100vw;
  min-height: 100vh;
`

export const PageContent = styled.div`
  padding: 0 2em;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
`
