import React, { useContext, useEffect, useState } from 'react'
import { Button, Checkbox, Col, Form, Input, Radio, Row, Select } from 'antd'
import moment from 'moment'
import Cookies from 'js-cookies'
import { getBanks } from '../../../providers/api/banks'

import { PIX_TYPES } from '../../../utils/pix'
import {
  RegisterEcContext,
  StepRegisterEC
} from '../../../Context/register-ec-provider'

export const Step5Bank = () => {
  const { data, changeStep } = useContext(RegisterEcContext)

  const [wantAntecipation, setWantAntecipation] = useState(null)

  const [banks, setBanks] = useState([])
  const [form] = Form.useForm()
  const brandsIds = [
    '50',
    '51',
    '52',
    '53',
    '54',
    '55',
    '56',
    '59',
    '60',
    '61',
    '63'
  ]

  const handleOnFinish = dataStep => {
    const bankAccounts = []

    brandsIds.forEach(element => {
      bankAccounts.push({
        id: element,
        bankNumber: dataStep.bank_data_institution,
        agencyNumber: dataStep.bank_data_agency,
        agencyDigit:
          !dataStep.agencyDigit || dataStep.agencyDigit === ''
            ? '0'
            : dataStep.agencyDigit,
        accountNumber: dataStep.bank_data_account,
        accountDigit: dataStep.bank_data_digit,
        accountType:
          dataStep.bank_data_account_type === 'SAVINGS'
            ? 'CHECKING'
            : dataStep.bank_data_account_type,
        accountOwnerName: dataStep.accountOwnerName
      })
    })

    const payload = {
      ...dataStep,
      bankAccounts,
      bacenPermission: dataStep.bacenPermission,
      pixAdicional: {
        type: dataStep.pix_type,
        chave: dataStep.pix_chave,
        historico: [
          {
            usuarioNome: Cookies.getItem('userName'),
            type: PIX_TYPES[dataStep.pix_type],
            chave: dataStep.pix_chave,
            dateUpdate: moment().format('DD/MM/YYYY HH:mm:ss')
          }
        ]
      },
      wantAntecipation
    }

    changeStep({ step: StepRegisterEC.Step6Finish, data: payload })
  }

  useEffect(() => {
    const handleGetBanks = async () => {
      const response = await getBanks()
      const sortedByBanksNumber = response.sort(
        (a, b) => a.bankNumber - b.bankNumber
      )
      setBanks(sortedByBanksNumber)
    }

    handleGetBanks()
  }, [])

  useEffect(() => {
    const bankAcoount = (data?.bankAccounts || [])?.[0] || {}

    const dados = {
      accountOwnerName: data
        ? data.physicalPerson
          ? data.physicalPerson.name
          : data.legalPerson.socialReason
        : '',
      bacenPermission: data?.bacenPermission,
      pix_type: data?.pix_type || null,
      pix_chave: data?.pix_chave || '',
      bank_data_account_type: bankAcoount?.accountType || '',
      bank_data_institution: bankAcoount?.bankNumber || '',
      bank_data_account: bankAcoount?.accountNumber || '',
      bank_data_digit: bankAcoount?.accountDigit || '',
      bank_data_agency: bankAcoount?.agencyNumber || '',
      agencyDigit: bankAcoount?.agencyDigit || '',
      automatic_anticipation: data.automatic_anticipation
    }

    form.setFieldsValue(dados)
  }, [form, data])

  return (
    <div className="form-container">
      <Form
        useRef
        form={form}
        name="basic"
        style={{ width: 800 }}
        onFinish={handleOnFinish}
      >
        <Form.Item
          label="Nome dono da conta"
          name="accountOwnerName"
          rules={[
            {
              required: true,
              message: 'Informe o nome do dono da conta.'
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Tipo de conta"
          name="bank_data_account_type"
          rules={[{ required: true, message: 'Informe o Tipo de conta' }]}
        >
          <Select
            options={[
              { value: 'CHECKING', label: 'Conta Corrente' },
              { value: 'GUARANTEED', label: 'Conta Garantida' },
              { value: 'SAVINGS', label: 'Conta Poupança' },
              { value: 'PAYMENT', label: 'Conta Pagamento' }
            ]}
          />
        </Form.Item>

        <Form.Item
          label="Banco"
          name="bank_data_institution"
          rules={[{ required: true, message: 'Informe o banco.' }]}
        >
          <Select
            showSearch
            showArrow
            filterOption={(input, option) =>
              (option?.label.toUpperCase() ?? '').includes(input.toUpperCase())
            }
            options={banks.map(element => ({
              value: element.bankNumber,
              label: `${element.bankNumber} - ${element.bankName}`
            }))}
          />
        </Form.Item>

        <Row>
          <Col>
            <Form.Item
              label="Conta"
              name="bank_data_account"
              rules={[{ required: true, message: 'Informe a conta.' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col offset={1}>
            <Form.Item
              label="Dígito"
              name="bank_data_digit"
              rules={[{ required: true, message: 'Informe o dígito.' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item
              label="Agência"
              name="bank_data_agency"
              rules={[{ required: true, message: 'Informe a agência.' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col offset={1}>
            <Form.Item label="Dígito da agência" name="agencyDigit">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <Form.Item label="Tipo de PIX" name="pix_type">
              <Select
                options={Object.keys(PIX_TYPES).map(key => ({
                  value: key,
                  label: PIX_TYPES[key]
                }))}
              />
            </Form.Item>
          </Col>
          <Col offset={1}>
            <Form.Item label="Chave PIX" name="pix_chave">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Habilitar antecipação automática?"
          name="automatic_anticipation"
          rules={[
            {
              required: true,
              message: 'Informe uma resposta'
            }
          ]}
        >
          <Radio.Group onChange={e => setWantAntecipation(e.target.value)}>
            <Radio value={true}>Habilitar</Radio>
            <Radio value={false}>Não Habilitar</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label="Estou de acordo com a validação de dados junto ao Banco Central do Brasil"
          name="bacenPermission"
          valuePropName="checked"
          rules={[
            {
              required: true,
              message: 'Informe uma resposta'
            }
          ]}
        >
          <Checkbox />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Próxima Etapa
          </Button>
        </Form.Item>

        <Form.Item>
          <Button
            danger
            onClick={() => changeStep({ step: StepRegisterEC.Step4Product })}
          >
            Voltar
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
