import React, { useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Button, Form, Input, Spin } from 'antd'
import { PersonalInfo } from '../01-personal-info/index'
import CaptureSolutions from '../04-capture-solutions/index'
import { cleanMoneyValue, maskCurrency } from '../../utils/masks'
import AppLayout from '../../components/app-layout/app-layout'

const Invoicing = props => {
  const spinIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />

  const { previousStepData } = props
  const [requestLoading] = useState(false)
  const [nextSteps, setNextStep] = useState(false)
  const [prevStep, setPrevStep] = useState(false)
  const [formData, setFormData] = useState({})
  const [form] = Form.useForm()
  const formInitialValues = {
    avarage_ticket: '',
    anual_billing_credit_card: '',
    cards_billing: '',
    volume_transactions_days: 0
  }

  const formMaskFunctions = {
    avarage_ticket: value => maskCurrency(value),
    cards_billing: value => maskCurrency(value),
    anual_billing_credit_card: value => maskCurrency(value)
  }

  const handleInputChange = async inputChanged => {
    const currentFormInitialValues = form.getFieldsValue()

    const inputName = Object.keys(inputChanged)[0]
    const inputValue = inputChanged[inputName]

    if (formMaskFunctions[inputName]) {
      currentFormInitialValues[inputName] =
        formMaskFunctions[inputName](inputValue)
      form.setFieldsValue(currentFormInitialValues)

      form.getFieldsValue()
    }
  }

  const handleOnFinish = data => {
    data.anual_billing_credit_card = cleanMoneyValue(
      data.anual_billing_credit_card
    )
    data.anual_billing_credit_card =
      data.anual_billing_credit_card < 360000.01
        ? 360000.01
        : data.anual_billing_credit_card
    const payload = {
      ...previousStepData,
      annualVolumeOfSalesCard: data.anual_billing_credit_card,
      annualVolumeSalesCardGroup: cleanMoneyValue(data.cards_billing),
      averageTicket: cleanMoneyValue(data.avarage_ticket),
      volume_transactions_days: Number(data.volume_transactions_days)
    }
    setFormData(payload)
    setNextStep(true)
  }

  return (
    <>
      {!nextSteps && !prevStep && (
        <AppLayout>
          <p className="title-page-info"> Oferta</p>
          <Spin
            className="page-loader"
            indicator={spinIcon}
            spinning={requestLoading}
          >
            <div className="form-container">
              <Form
                useRef
                form={form}
                name="basic"
                onFinish={handleOnFinish}
                style={{ width: 800 }}
                initialValues={formInitialValues}
                onValuesChange={handleInputChange}
              >
                <Form.Item
                  label="Faturamento anual cartão de crédito"
                  name="anual_billing_credit_card"
                  rules={[
                    {
                      required: true,
                      message:
                        'Informe o faturamento anual no cartão de crédito.'
                    }
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Informe o faturamento total de Cartões do Grupo da Empresa"
                  name="cards_billing"
                  rules={[
                    {
                      required: true,
                      message: 'Informe o faturamento total de cartões.'
                    }
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Ticket Médio"
                  name="avarage_ticket"
                  rules={[
                    { required: true, message: 'Informe o ticket médio.' }
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Volume de transações diárias"
                  name="volume_transactions_days"
                  rules={[
                    {
                      required: true,
                      message: 'Informe o máximo de transações diárias.'
                    }
                  ]}
                >
                  <Input type="number" min={1} />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Próxima Etapa
                  </Button>
                </Form.Item>

                <Form.Item>
                  <Button danger onClick={() => setPrevStep(true)}>
                    cancelar
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Spin>
        </AppLayout>
      )}
      {prevStep && <PersonalInfo formDataPreLoaded={previousStepData} />}
      {nextSteps && <CaptureSolutions previousStepData={formData} />}
    </>
  )
}

export default Invoicing
