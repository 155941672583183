import React, { useEffect, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import {
  Button,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Spin,
  message
} from 'antd'
import { PersonalInfo } from '../01-personal-info/index'
import DeliveryContact from '../07-delivery-contact/index'
import { getTaxs } from '../../providers/api/taxs'
import { cleanCpfCnpj, cleanMoneyValue, maskCurrency } from '../../utils/masks'
import { getAntecipation } from '../../providers/api/antecipation'
import { getCnaes, getCnpjCnaes } from '../../providers/api/cnpj'
import { getCampaign } from '../../providers/api/campaign'
import AppLayout from '../../components/app-layout/app-layout'

const FeeEdit = props => {
  // TODO: quando não tiver taxa de antecipação

  // "campaignId": "103",
  // "cnae: 32432
  // annualBillingVolume
  // "fees": {
  //     "chargeModel": "502",
  //     "paymentDay": 5,
  //     "services": [
  //       {
  //         "serviceCode": 110,
  //         "numberOfInstallments": 12,
  //         "factor1FlexRateFee": 0,
  //         "factor2FlexRateFee": 2.99,
  //         "feeDetails": {
  //           "transactionTypeId": 999,
  //           "feeValue": 2.05,
  //           "mdrFlexRateFee": 2,
  //           "eventArea": "ALL"
  //         },
  //         "type": "TAX"
  //       }
  //     ]
  //   },
  // "manualPrepayment": {
  //     "autoPrepaymentFee": 4.39,
  //     "enabled": true,
  //     "releasedPrepaymentFee": 4.29,
  //     "unreleasedPrepaymentFee": 4.29
  //   },

  const { previousStepData, techs } = props

  /** Page data */
  const [antecipation, setAntecipation] = useState([])
  const [immutableAntecipation, setImmutableAntecipation] = useState([])
  const [taxs, setTaxs] = useState([])
  const [immutableTaxs, setImmutableTaxs] = useState([])

  const [campaign, setCampaign] = useState([])
  const [cnaesData, setCnaesData] = useState([])
  const [activeCampaignData, setActiveCampaignData] = useState(null)
  const [currentType, setCurrentType] = useState(null)
  const spinIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />

  /** Page control view */
  const [requestLoading, setRequestLoading] = useState(false)
  // const [showOptions, setShowOptions] = useState(true);
  // const [activeOption, setActiveOption] = useState(null)
  const [wantAntecipation, setWantAntecipation] = useState('')
  const [nextSteps, setNextStep] = useState(false)
  const [prevStep, setPrevStep] = useState(false)
  const [messageApi, contextHolder] = message.useMessage()

  /** Page table */
  const columns = [
    { title: 'Bandeira', dataIndex: 'brand' },
    { title: 'Transação', dataIndex: 'modalityEnum' },
    {
      title: 'Taxa - Clique para informar',
      dataIndex: 'minimumRate',
      editable: true,
      render: item => `${item} %`,
      onCell: record => ({
        record,
        editable: true,
        dataIndex: 'minimumRate',
        title: 'Taxa',
        handleSave
      })
    }
  ]

  /** Page form */
  const [formData, setFormData] = useState({})
  const [form] = Form.useForm()
  const [formInitialValues, setFormInitialValues] = useState({
    cnae: null,
    anual_billing: null,
    campaign: null
  })

  const handleInputChange = async inputChanged => {
    const currentFormInitialValues = form.getFieldsValue()

    const inputName = Object.keys(inputChanged)[0]
    const inputValue = inputChanged[inputName]

    const formMaskFunctions = {
      anual_billing: value => maskCurrency(value),
      avarage_ticket: value => maskCurrency(value),
      cards_billing: value => maskCurrency(value)
    }

    if (formMaskFunctions[inputName]) {
      currentFormInitialValues[inputName] =
        formMaskFunctions[inputName](inputValue)
      form.setFieldsValue(currentFormInitialValues)

      form.getFieldsValue()
    }
  }

  const handleGetCnaes = async () => {
    let response = []

    if (previousStepData.physicalPerson) {
      response = await getCnaes()
    } else {
      response = await getCnpjCnaes(
        cleanCpfCnpj(previousStepData.legalPerson.cnpj)
      )
    }
    setCnaesData(response)
  }

  const handleGetCampaign = async () => {
    setTaxs([])
    setImmutableTaxs([])
    const currentInitialValues = form.getFieldValue()

    if (!currentInitialValues.cnae || !currentInitialValues.anual_billing) {
      return
    }

    try {
      setRequestLoading(true)
      currentInitialValues.campaign = []
      setActiveCampaignData(null)
      setWantAntecipation(false)
      setCurrentType(null)

      form.setFieldValue(currentInitialValues)
      let response = []

      const typeTech = {
        physical: 2302,
        ecommerce: 2303
      }
      const physicalTechId = 300

      if (
        parseFloat(previousStepData.captureSolutions[0].serviceId) ===
        physicalTechId
      ) {
        response = [
          {
            id: typeTech.physical,
            idInstitution: '00000007',
            serviceContract: 125,
            description: 'Campanha ISO - Físico',
            initialDate: '2022-10-14',
            expireDate: '9999-12-31',
            basedInCampaign: null,
            basedInCampaignDescription: null,
            idEconomicGroup: null,
            economicGroup: null,
            lastModifiedDate: '30/05/2023 12:08:18',
            enablePriceQueue: true,
            campaignCode: null,
            hasMdr: true,
            hasMdrFlex: true
          }
        ]
      } else {
        response = [
          {
            id: typeTech.ecommerce,
            idInstitution: '00000007',
            serviceContract: 125,
            description: 'Campanha ISO - E-commmerce',
            initialDate: '2022-10-14',
            expireDate: '9999-12-31',
            basedInCampaign: null,
            basedInCampaignDescription: null,
            idEconomicGroup: null,
            economicGroup: null,
            lastModifiedDate: '30/05/2023 12:08:28',
            enablePriceQueue: true,
            campaignCode: null,
            hasMdr: true,
            hasMdrFlex: true
          }
        ]
      }

      setCampaign(response)
      setFormInitialValues(currentInitialValues)

      setRequestLoading(false)
    } catch (err) {
      setRequestLoading(false)
    }
  }

  const handleChooseCampaign = _e => {
    const currentInitialValues = form.getFieldValue()
    const campaignData = campaign.find(
      element => element.id === currentInitialValues.campaign
    )

    setActiveCampaignData(campaignData)
    setCurrentType(null)
    currentInitialValues.mr_or_mdrflex = null
    currentInitialValues.mdr_options = null
    setAntecipation([])
    setImmutableAntecipation([])
    setWantAntecipation(false)
    form.setFieldValue(currentInitialValues)

    const annualBilling = cleanMoneyValue(currentInitialValues.anual_billing)

    const routeType = annualBilling <= 300000 ? 'mdr' : 'mdr-flex'
    getTaxsData(routeType)
  }

  const handleSave = (row, editabledValue) => {
    console.log(editabledValue)
    console.log(immutableTaxs)
    const findTax = immutableTaxs.find(
      element =>
        element.brand === row.brand && element.modalityEnum === row.modalityEnum
    )

    if (!findTax) {
      return messageApi.open({
        type: 'error',
        content: 'Erro ao tentar editar taxa.'
      })
    } else {
      const error = editabledValue < findTax.minimumRate
      if (error) {
        return messageApi.open({
          type: 'error',
          content: 'A taxa não pode ser menor que a taxa mínima.'
        })
      } else {
        const newData = [...taxs]
        const index = newData.findIndex(
          item =>
            item.brand === row.brand && item.modalityEnum === row.modalityEnum
        )
        const item = newData[index]
        newData.splice(index, 1, {
          ...item,
          ...row
        })
        setTaxs(newData)
      }
    }
  }

  const getTaxsData = async type => {
    try {
      setTaxs([])
      setImmutableTaxs([])
      setRequestLoading(true)
      const translations = [
        { key: 'CREDIT', value: 'Crédito' },
        { key: 'DEBIT', value: 'Débito' },
        { key: 'SYSTEM_CREDIT', value: 'Crediário' },
        {
          key: 'LESS_THAN_OR_EQUAL_TO_SIX_INSTALLMENTS',
          value: 'Parcel. até 6x'
        },
        { key: 'BIGGER_THAN_SIX_INSTALLMENTS', value: 'Parcel. maior que 6x' }
      ]

      const currentInitialValues = form.getFieldValue()

      const payload = {
        cnae: currentInitialValues.cnae,
        campaignId: currentInitialValues.campaign,
        anualBilling: cleanMoneyValue(currentInitialValues.anual_billing),
        uf: previousStepData.address && previousStepData.address[0].state,
        document: previousStepData.physicalPerson
          ? cleanCpfCnpj(previousStepData.physicalPerson.cpf)
          : cleanCpfCnpj(previousStepData.legalPerson.cnpj),
        type
      }

      setCurrentType(type)

      const response = await getTaxs(payload)
      const transactionsResponse = []

      response?.taxs?.[0]?.brands.forEach(brand => {
        brand.rates.forEach(rate => {
          if (rate.modalityEnum !== 'SYSTEM_CREDIT') {
            const modalityEnum = translations.find(
              translation => translation.key === rate.modalityEnum
            )
            transactionsResponse.push({
              ...rate,
              minimumRate: (rate.minimumRate + 0.1).toFixed(2),
              suggestedRate: (rate.suggestedRate + 0.1).toFixed(2),
              modalityEnum: modalityEnum ? modalityEnum.value : '-',
              transactionId: rate.transactionId,
              brand: brand.brand
            })
          }
        })
      })

      setTaxs(transactionsResponse)
      setImmutableTaxs(transactionsResponse)
      setRequestLoading(false)
    } catch (err) {
      setTaxs([])
      setImmutableTaxs([])
      setRequestLoading(false)
    }
  }

  const handleGetAntecipation = async () => {
    try {
      setRequestLoading(true)
      const currentInitialValues = form.getFieldValue()

      const payload = {
        cnae: currentInitialValues.cnae,
        campaignId: currentInitialValues.campaign,
        anualBilling: cleanMoneyValue(currentInitialValues.anual_billing)
      }

      const response = await getAntecipation(payload)

      const currentAntecipation = { ...response[0] }
      if (!currentAntecipation.minAutoPrepayment) {
        setRequestLoading(false)

        setAntecipation([])
        setImmutableAntecipation([])
        return messageApi.open({
          type: 'warning',
          content:
            'taxas de antecipação para os dados informados não encontradas.'
        })
      }
      currentAntecipation.minAutoPrepayment = (
        currentAntecipation.minAutoPrepayment + 0.1
      ).toFixed(2)
      setAntecipation(currentAntecipation)
      setImmutableAntecipation(currentAntecipation)
      setRequestLoading(false)
    } catch (err) {
      setRequestLoading(false)
    }
  }

  const handleAntecipationChange = value => {
    if (value < immutableAntecipation.minAutoPrepayment) {
      return messageApi.open({
        type: 'error',
        content: 'A taxa não pode ser menor que a taxa mínima.'
      })
    }

    setAntecipation({ ...antecipation, minAutoPrepayment: value })
  }

  const handleOnFinish = () => {
    const currentInitialValues = form.getFieldValue()

    const payloadTosend = {
      cnae: currentInitialValues.cnae,
      anual_billing: currentInitialValues.anual_billing,
      campaign: currentInitialValues.campaign,
      type: currentType,
      wantAntecipation,
      antecipation:
        antecipation.length === 0 ? false : antecipation.minAutoPrepayment,
      fee: taxs
    }

    if (
      !currentInitialValues.cnae ||
      !currentInitialValues.anual_billing ||
      !currentInitialValues.campaign ||
      currentInitialValues.campaign?.length === 0 ||
      !currentType
    ) {
      // taxs.length === 0 - Removido, pois será registrado posteriormente
      return messageApi.open({
        type: 'error',
        content: 'Infome todos os campos.'
      })
    }

    if (wantAntecipation && antecipation.minAutoPrepayment) {
      if (!payloadTosend.antecipation) {
        return messageApi.open({
          type: 'error',
          content: 'Infome todos os campos.'
        })
      }
    }

    const findCampaign = campaign.find(
      element => element.id === currentInitialValues.campaign
    )

    const currentPrevState = { ...previousStepData }

    const monthlyIncome =
      cleanMoneyValue(currentInitialValues.anual_billing) / 12

    if (currentPrevState.legalPerson) {
      currentPrevState.legalPerson.monthlyAverageBilling = monthlyIncome
    } else if (currentPrevState.physicalPerson) {
      currentPrevState.physicalPerson.monthlyIncome = monthlyIncome
    }

    const brandsTransactionCode = [
      { id: '716', brand: 'MASTER', type: 'Débito' },
      { id: '102', brand: 'MASTER', type: 'Crédito' },
      { id: '102', brand: 'MASTER', type: 'Parcel. até 6x' },
      { id: '102', brand: 'MASTER', type: 'Parcel. maior que 6x' },
      { id: '200', brand: 'VISA', type: 'Débito' },
      { id: '201', brand: 'VISA', type: 'Crédito' },
      { id: '201', brand: 'VISA', type: 'Parcel. até 6x' },
      { id: '201', brand: 'VISA', type: 'Parcel. maior que 6x' },
      { id: '104', brand: 'CABRAL', type: 'Débito' },
      { id: '103', brand: 'CABRAL', type: 'Crédito' },
      { id: '103', brand: 'CABRAL', type: 'Parcel. até 6x' },
      { id: '103', brand: 'CABRAL', type: 'Parcel. maior que 6x' },
      { id: '109', brand: 'HIPERCARD', type: 'Crédito' },
      { id: '109', brand: 'HIPERCARD', type: 'Parcel. até 6x' },
      { id: '109', brand: 'HIPERCARD', type: 'Parcel. maior que 6x' },
      { id: '111', brand: 'ELO', type: 'Débito' },
      { id: '110', brand: 'ELO', type: 'Crédito' },
      { id: '110', brand: 'ELO', type: 'Parcel. até 6x' },
      { id: '110', brand: 'ELO', type: 'Parcel. maior que 6x' },
      { id: '110', brand: 'AMEX', type: 'Crédito' },
      { id: '110', brand: 'AMEX', type: 'Parcel. até 6x' },
      { id: '110', brand: 'AMEX', type: 'Parcel. maior que 6x' }
    ]

    const services = []

    taxs.forEach(tax => {
      const serviceCodeFind = brandsTransactionCode.find(
        element =>
          element.brand === tax.brand && element.type === tax.modalityEnum
      )

      if (serviceCodeFind) {
        // tax.transactionId.forEach(transaction => {

        if (
          tax.modalityEnum === 'Parcel. até 6x' ||
          tax.modalityEnum === 'Parcel. maior que 6x'
        ) {
          if (tax.modalityEnum === 'Parcel. até 6x') {
            for (let i = 1; i <= 6; i++) {
              services.push({
                serviceCode: serviceCodeFind.id,
                numberOfInstallments: i,
                feeDetails: {
                  transactionTypeId: tax.transactionId,
                  feeValue: currentType === 'mdr' ? tax.minimumRate : 0,
                  mdrFlexRateFee: currentType === 'mdr' ? 0 : tax.minimumRate,
                  eventArea: 'ALL'
                },
                type: 'TAX'
              })
            }
          } else {
            for (let i = 7; i <= 12; i++) {
              services.push({
                serviceCode: serviceCodeFind.id,
                numberOfInstallments: i,
                feeDetails: {
                  transactionTypeId: tax.transactionId,
                  feeValue: currentType === 'mdr' ? tax.minimumRate : 0,
                  mdrFlexRateFee: currentType === 'mdr' ? 0 : tax.minimumRate,
                  eventArea: 'ALL'
                },
                type: 'TAX'
              })
            }
          }
        } else {
          services.push({
            serviceCode: serviceCodeFind.id,
            feeDetails: {
              transactionTypeId: tax.transactionId,
              feeValue: currentType === 'mdr' ? tax.minimumRate : 0,
              mdrFlexRateFee: currentType === 'mdr' ? 0 : tax.minimumRate,
              eventArea: 'ALL'
            },
            type: 'TAX'
          })
        }
        // })
      }
    })

    const payload = {
      ...currentPrevState,
      cnae: currentInitialValues.cnae,
      campaignId: findCampaign.id,
      annualBillingVolume: cleanMoneyValue(currentInitialValues.anual_billing),
      prepaymentIndicator: wantAntecipation,
      internalTaxs: taxs,
      fees: {
        chargeModel: currentType === 'mdr' ? '502' : '501',
        feeDetails: {
          services
        }
      }
    }

    if (wantAntecipation) {
      payload.manualPrepayment = {
        autoPrepaymentFee: antecipation.minAutoPrepayment,
        enabled: true,
        releasedPrepaymentFee: antecipation.minAutoPrepayment,
        unreleasedPrepaymentFee: antecipation.minAutoPrepayment
      }
    }

    setFormData(payload)
    setNextStep(true)
  }

  useEffect(() => {
    if (wantAntecipation) {
      handleGetAntecipation()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wantAntecipation])

  useEffect(() => {
    handleGetCampaign()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getPagePreLoadedData = async () => {
      try {
        setRequestLoading(true)

        await handleGetCnaes()
      } catch (err) {
        console.error(err)
      } finally {
        setRequestLoading(false)
      }
    }

    getPagePreLoadedData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {!nextSteps && !prevStep && (
        <AppLayout>
          <p className="title-page-info">
            Informe as taxas * Taxas não preenchidas serão encaixadas na taxa
            minima.
          </p>

          <div className="table-container">
            <Spin
              className="page-loader"
              indicator={spinIcon}
              spinning={requestLoading}
            >
              <Form
                useRef
                form={form}
                name="formPJ"
                style={{ width: 800 }}
                initialValues={formInitialValues}
                onValuesChange={handleInputChange}
              >
                <>
                  <Form.Item
                    label="CNAE"
                    name="cnae"
                    rules={[{ required: true, message: 'Informe o CNAE' }]}
                  >
                    <Select
                      onBlur={handleGetCampaign}
                      showSearch
                      placeholder="Selecione um cnae"
                      defaultActiveFirstOption={true}
                      showArrow={true}
                      filterOption={true}
                      options={cnaesData.map(element => ({
                        value: element.id,
                        label: element.description
                      }))}
                    />
                  </Form.Item>
                  <Form.Item
                    onBlur={handleGetCampaign}
                    label="Faturamento Anual"
                    name="anual_billing"
                    rules={[
                      {
                        required: true,
                        message: 'Informe o faturamento anual.'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Selecione a Campanha"
                    name="campaign"
                    rules={[{ required: true, message: 'Informe a campanha.' }]}
                  >
                    <Select
                      // onBlur={handleChooseCampaign}
                      onChange={handleChooseCampaign}
                      placeholder="Selecione um campanha"
                      notFoundContent="Para buscar as campanhas informe o cnae e a renda anual."
                      style={{ width: 500 }}
                      options={campaign.map(element => {
                        // console.log(element);
                        return {
                          value: element.id,
                          label: element.description.replace('ISO', '')
                        }
                      })}
                    />
                  </Form.Item>
                  {activeCampaignData &&
                    activeCampaignData.hasMdr &&
                    activeCampaignData.hasMdrFlex && (
                      <>
                        {previousStepData.annualVolumeOfSalesCard >=
                          360000.01 && (
                          <Form.Item
                            label="Habilitar antecipação automatica ?"
                            name="mdr_options"
                            rules={[
                              {
                                required: true,
                                message: 'Informe uma resposta'
                              }
                            ]}
                          >
                            <Radio.Group
                              onChange={e =>
                                setWantAntecipation(e.target.value)
                              }
                            >
                              <Radio value={true}>Habilitar</Radio>
                              <Radio value={false}>Não Habilitar</Radio>
                            </Radio.Group>
                          </Form.Item>
                        )}

                        {wantAntecipation && antecipation.minAutoPrepayment && (
                          <Form.Item label="Taxa de antecipação automática">
                            <InputNumber
                              type="number"
                              min={0}
                              precision={2}
                              prefix="%"
                              step={0.1}
                              value={antecipation?.minAutoPrepayment}
                              onChange={handleAntecipationChange}
                              placeholder="0.00%"
                            />
                          </Form.Item>
                        )}
                      </>
                    )}
                </>
              </Form>
              <>
                {contextHolder}
                {/* <Table
                  style={{ marginBottom: '3em' }}
                  components={{
                    body: {
                      row: EditableRow,
                      cell: EditableCell,
                    }
                  }}
                  rowClassName={() => 'editable-row'}
                  bordered
                  dataSource={taxs}
                  columns={columns}
                  pagination={false}
                  set
                /> */}
              </>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={handleOnFinish}
                >
                  Próxima Etapa
                </Button>
              </Form.Item>

              <Form.Item>
                <Button danger onClick={() => setPrevStep(true)}>
                  cancelar
                </Button>
              </Form.Item>
            </Spin>
          </div>
        </AppLayout>
      )}
      {nextSteps && <DeliveryContact previousStepData={formData} />}

      {prevStep && <PersonalInfo formDataPreLoaded={previousStepData} />}
    </>
  )
}

export default FeeEdit
