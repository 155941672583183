import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const ContainerFilter = styled.div`
  display: flex;
`

export const ContainerTotalizador = styled.div`
  font-size: 16px;
  font-weight: 600;
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  & + & {
    padding-left: 20px;
  }
`
