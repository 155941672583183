import styled from 'styled-components'

const AddressTypeContainer = styled.div`
  padding: 5em;
  border-radius: 2em;
  background-color: white;
  -webkit-box-shadow: 0px 3px 16px -1px rgba(209, 209, 209, 1);
  -moz-box-shadow: 0px 3px 16px -1px rgba(209, 209, 209, 1);
  box-shadow: 0px 3px 16px -1px rgba(209, 209, 209, 1);
  margin-bottom: 2em;
  margin-top: 2em;
`

const AddressTypeContainerTitle = styled.div`
  margin-bottom: 2em;
  font-size: 12pt;
  color: #737272;
`

export { AddressTypeContainer, AddressTypeContainerTitle }
