import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as AppSidebarStyle from './component'
import Cookies from 'js-cookies'

import LogoImg from '../../../assets/img/logo.png'

export function AppSidebar(props) {
  const navigate = useNavigate()
  const [currentOption, setCurrentOptions] = useState('register-ec')
  const [openKeys, setCurrentOpenKeys] = useState(props.openKeys)
  const [expanded, setExpanded] = useState(window.innerWidth >= 1280)

  const isMobile = useCallback(() => {
    return window.innerWidth <= 769
  }, [])

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type
    }
  }

  const items = useMemo(() => {
    const result = [
      getItem('Cadastrar EC', 'register-ec'),
      getItem('Histórico de credenciamentos', 'historic-ec')
    ]

    if (Cookies.getItem('accessLevel') === '0') {
      result.push(
        ...[
          getItem('Gerenciar credenciamentos', 'manager-ec'),
          getItem('Análise de risco', 'risk-ec', undefined, [
            getItem('Gerenciamento de Risco', 'risk-management'),
            getItem('Regras', 'risk-ec-rules', undefined, [
              getItem('Blacklist', 'risk-ec-rules-blacklist'),
              getItem("Categorias de CNAE's", 'cnaes')
            ])
          ]),
          getItem('Monitoramento de transações', 'transaction-monitoring'),
          getItem('Análise de risco de transações', 'risk-transactions')
        ]
      )
    }
    return result
  }, [])

  useEffect(() => {
    if (props.currentOption) {
      setCurrentOptions(props.currentOption)
    }

    if (props.openKeys) {
      setCurrentOpenKeys(props.openKeys)
    }
  }, [props.currentOption, props.openKeys])

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (isMobile()) {
        setExpanded(window.innerWidth > 769)
      }
    })
  }, [isMobile])

  const handleOnClick = useCallback(
    e => {
      setCurrentOptions(e.key)
      if (e.key === 'register-ec') {
        navigate('/')
      } else if (e.key === 'manager-ec') {
        navigate('/accreditations-admin')
      } else if (e.key === 'risk-ec-rules-blacklist') {
        navigate('/risk-rules-blacklist')
      } else if (e.key === 'risk-management') {
        navigate('/risk-management')
      } else if (e.key === 'cnaes') {
        navigate('/cnaes')
      } else if (e.key === 'transaction-monitoring') {
        navigate('/transaction-monitoring')
      } else if (e.key === 'risk-transactions') {
        navigate('/risk-transactions')
      } else {
        navigate('/accreditations')
      }
    },
    [navigate]
  )

  const toggleSidebar = useCallback(() => {
    setExpanded(oldValue => !oldValue)
  }, [])

  return (
    <AppSidebarStyle.Container expanded={+expanded}>
      <AppSidebarStyle.PageHeader expanded={+expanded}>
        <img alt="x-payLogo" src={LogoImg}></img>
        <p>Portal de Credenciamento</p>
        <AppSidebarStyle.ButtonFloat size={24} onClick={toggleSidebar} />
      </AppSidebarStyle.PageHeader>
      <AppSidebarStyle.PageMenu
        onClick={handleOnClick}
        selectedKeys={[currentOption]}
        defaultOpenKeys={openKeys}
        mode="inline"
        items={items}
        key={item => item.key}
        expanded={+expanded}
      />
    </AppSidebarStyle.Container>
  )
}
