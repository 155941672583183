import CnaeActionMenu from './action-menu'

export function formatCnae(cnae) {
  const regex = /^(\d+)(\d)(\d{2})$/
  const match = cnae.match(regex)

  if (match) {
    const digitsBeforeDash = match[1]
    const singleDigit = match[2]
    const digitsAfterSlash = match[3]

    return `${digitsBeforeDash}-${singleDigit}/${digitsAfterSlash}`
  }
  return cnae
}

export const cnaesTableColumns = ({
  searchValue,
  refresh,
  filtroInativado
}) => [
  {
    title: 'CNAE',
    dataIndex: 'cnae',
    filteredValue: [searchValue],
    onFilter: (value = '', record) => {
      value = value.trim()
      if (!value && filtroInativado === null) return true

      const filterSelect =
        filtroInativado !== null && record.inativado === filtroInativado
      if (!Number.isNaN(parseInt(value.replace(/\D/g, '')))) {
        return (
          (String(record.cnae).includes(value.replace(/\D/g, '')) ||
            String(record.mcc).toLowerCase().includes(value.toLowerCase())) &&
          (filtroInativado === null || filterSelect)
        )
      }
      return (
        String(record.riskCategory)
          .toLowerCase()
          .includes(value.toLowerCase()) &&
        (filtroInativado === null || filterSelect)
      )
    },
    render: (_, record) => {
      return formatCnae(record.cnae)
    }
  },
  {
    title: 'Categoria de risco',
    dataIndex: 'riskCategory',
    responsive: ['sm']
  },
  {
    title: 'MCC (núm)',
    dataIndex: 'mcc',
    responsive: ['md']
  },
  {
    title: 'Nome MCC',
    dataIndex: 'nomeMCC',
    responsive: ['lg']
  },
  {
    title: 'Inativo',
    dataIndex: 'inativado',
    responsive: ['lg'],
    render: (_, record) => {
      return record.inativado ? 'Sim' : 'Não'
    }
  },
  {
    title: 'Ação',
    dataIndex: '_id',
    render: (_, record) => {
      return <CnaeActionMenu cnae={record} refresh={refresh} />
    }
  }
]
