import styled from 'styled-components'
import { Button, Input, Table } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

const SearchInput = styled(Input.Search)`
  max-width: 300px;
  margin-bottom: 20px;
`

const AddButton = styled(Button).attrs({
  type: 'primary',
  icon: <PlusOutlined color="#FFFFFF" />,
  children: 'Adicionar CNAE'
})`
  display: block;
  margin-bottom: 20px;
`

const SearchButton = styled.button`
  color: #fff;
  background-color: #1677ff;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
  font-size: 14px;
  height: 2em;
  padding: 4px 15px;
  border-radius: 6px;
  outline: none;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  border: 1px solid transparent;
  cursor: pointer;
`

const SearchLabel = styled.label``

const SearchMessage = styled.p`
  color: #777;
`

export const SearchTable = styled(Table)`
  margin-bottom: 20px;
`

export { SearchInput, SearchButton, SearchLabel, SearchMessage, AddButton }
