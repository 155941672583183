import React, { useState } from 'react'
import { Button, Checkbox, Col, Form, Row, TimePicker } from 'antd'
import { PersonalInfo } from '../01-personal-info/index'
import dayjs from 'dayjs'
import Terms from '../14-proposal-terms-acceptance/index'

const DeliveryHours = props => {
  const { previousStepData } = props
  const formInitialValues = {
    delivery_hours_week_days: [
      'Segunda-Feira',
      'Terça-Feira',
      'Quarta-Feira',
      'Quinta-Feira',
      'Sexta-Feira',
      'Sábado',
      'Domingo'
    ],
    delivery_hours_start: '',
    delivery_hours_end: ''
  }

  const [nextSteps, setNextStep] = useState(false)
  const [prevStep, setPrevStep] = useState(false)
  const [formData, setFormData] = useState({})
  const [form] = Form.useForm()

  const handleOnFinish = data => {
    data.delivery_hours_start = dayjs(data.delivery_hours_start).format('HH:mm')
    data.delivery_hours_end = dayjs(data.delivery_hours_end).format('HH:mm')

    const payload = {
      ...previousStepData,
      step_thirteen: data
    }

    setFormData(payload)
    setNextStep(true)
  }

  return (
    <>
      {!nextSteps && !prevStep && (
        <>
          <header
            className="portal-header"
            style={{
              marginBottom: '2em',
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center'
            }}
          >
            <img
              style={{ height: '50px' }}
              alt="x-payLogo"
              src={require('../../assets/img/logo.png')}
            ></img>
            <div style={{ fontSize: '16pt' }}>Portal de Credenciamento</div>
          </header>
          <p className="title-page-info"> Horário de Entrega</p>
          <div className="form-container">
            <Form
              useRef
              form={form}
              name="basic"
              style={{ width: 800 }}
              initialValues={formInitialValues}
              onFinish={handleOnFinish}
            >
              <Form.Item
                name="delivery_hours_week_days"
                label="Dias"
                rules={[{ required: true, message: 'Informe os dias' }]}
              >
                <Checkbox.Group>
                  <Row>
                    <Col span={8}>
                      <Checkbox value="segunda" style={{ lineHeight: '32px' }}>
                        Segunda-Feira
                      </Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox value="terça" style={{ lineHeight: '32px' }}>
                        Terça-Feira
                      </Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox value="quarta" style={{ lineHeight: '32px' }}>
                        Quarta-Feira
                      </Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox value="quinta" style={{ lineHeight: '32px' }}>
                        Quinta-Feira
                      </Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox value="sexta" style={{ lineHeight: '32px' }}>
                        Sexta-Feira
                      </Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox value="sabado" style={{ lineHeight: '32px' }}>
                        Sábado
                      </Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox value="domingo" style={{ lineHeight: '32px' }}>
                        Domingo
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </Form.Item>
              <Row>
                <Col>
                  <Form.Item
                    name="delivery_hours_start"
                    label="Abre às"
                    rules={[
                      {
                        required: true,
                        message: 'Informe o inicio do horário de entrega.'
                      }
                    ]}
                  >
                    <TimePicker format="HH:mm" />
                  </Form.Item>
                </Col>
                <Col offset={2}>
                  <Form.Item
                    name="delivery_hours_end"
                    label="Fecha às"
                    rules={[
                      {
                        required: true,
                        message: 'Informe o final do horário de entrega.'
                      }
                    ]}
                  >
                    <TimePicker format="HH:mm" />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Próxima Etapa
                </Button>
              </Form.Item>

              <Form.Item>
                <Button danger onClick={() => setPrevStep(true)}>
                  cancelar
                </Button>
              </Form.Item>
            </Form>
          </div>
        </>
      )}
      {nextSteps && <Terms previousStepData={formData} />}

      {prevStep && <PersonalInfo formDataPreLoaded={previousStepData} />}
    </>
  )
}

export default DeliveryHours
