import Cookies from 'js-cookies'
import { api } from './index'

export function getClientUtcOffset() {
  const offsetMinutes = new Date().getTimezoneOffset()
  const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60)
  const offsetSign = offsetMinutes > 0 ? '-' : '+'
  return `${offsetSign}${offsetHours}`
}

const login = async payload => {
  try {
    const response = await api.post(`/login`, payload)

    const { token, idCompany, idUser, accessLevel, userName } = response.data

    Cookies.setItem('token', token)
    Cookies.setItem('companyId', idCompany)
    Cookies.setItem('userId', idUser)
    Cookies.setItem('accessLevel', accessLevel)
    Cookies.setItem('userName', userName)
    Cookies.setItem('userUTC', getClientUtcOffset())

    return {
      status: true
    }
  } catch (err) {
    return {
      status: false
    }
  }
}

const checkLogin = async token => {
  try {
    const response = await api.get(`/login/check`, {
      headers: {
        Authorization: token
      }
    })

    const { idCompany, idUser, accessLevel, userName } = response.data

    Cookies.setItem('token', token)
    Cookies.setItem('companyId', idCompany)
    Cookies.setItem('userId', idUser)
    Cookies.setItem('accessLevel', accessLevel)
    Cookies.setItem('userName', userName)
    Cookies.setItem('userUTC', getClientUtcOffset())

    return {
      status: true
    }
  } catch (err) {
    return {
      status: false
    }
  }
}

export { login, checkLogin }
