import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes
} from 'react-router-dom'
import Login from '../pages/00-login/index'
import { PersonalInfo } from '../pages/01-personal-info/index'
import PersonalInfoAddress from '../pages/02-personal-info-address/index'
import Invoicing from '../pages/03-invoicing/index'
import CaptureSolutions from '../pages/04-capture-solutions/index'
import OfferDataStepFive from '../pages/05-offer-data/index'
import FeeEdit from '../pages/06-fee-edit/index'
import DeliveryContact from '../pages/07-delivery-contact/index'
import DeliveryData from '../pages/08-delivery-data/index'
import PartnerData from '../pages/09-partner-data/index'
import BankData from '../pages/10-bank-data/index'
import WorkingHours from '../pages/11-working-hours/index'
import DeliveryHours from '../pages/12-delivery-hours/index'
import EdiAdditionalData from '../pages/13-edi-additional-data/index'
import Terms from '../pages/14-proposal-terms-acceptance/index'
import ProposalSuccess from '../pages/15-proposal-success'
import AccreditationHistoric from '../pages/16-accreditantion-historic'
import AccreditationHistoricAdmin from '../pages/16-accreditantion-historic-admin'
import RiksRulesBlacklist from '../pages/17-risk/00-rules/00-blacklist/index'
import RiksManagement from '../pages/17-risk/01-risk-management'
import { fetcher } from '../providers/api'
import { SWRConfig } from 'swr'
import CnaesList from '../pages/18-Cnaes'
import TransactionsMonitoring from '../pages/19-transaction-monitoring'
import RiskTransactions from '../pages/20-risk-transactions'

const AppRoutes = () => {
  return (
    <SWRConfig
      value={{
        fetcher,
        revalidateOnFocus: true,
        revalidateOnReconnect: true
      }}
    >
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/:token?" element={<PersonalInfo />} />
          <Route path="/address" element={<PersonalInfoAddress />} />
          <Route path="/invoicing" element={<Invoicing />} />
          <Route path="/capture-solutions" element={<CaptureSolutions />} />
          <Route
            path="/professional-license-register"
            element={<OfferDataStepFive />}
          />
          <Route path="/delivery-contact" element={<DeliveryContact />} />
          <Route path="/delivery-data" element={<DeliveryData />} />
          <Route path="/partner-data" element={<PartnerData />} />
          <Route path="/bank-data" element={<BankData />} />
          <Route path="/working-hours" element={<WorkingHours />} />
          <Route path="/delivery-hours" element={<DeliveryHours />} />
          <Route path="/edi-additional-data" element={<EdiAdditionalData />} />
          <Route path="/proposal-terms-acceptance" element={<Terms />} />
          <Route path="/proposal-feedback" element={<ProposalSuccess />} />
          <Route path="/fee-edit" element={<FeeEdit />} />
          <Route path="/accreditations" element={<AccreditationHistoric />} />
          <Route
            path="/accreditations-admin"
            element={<AccreditationHistoricAdmin />}
          />
          <Route
            path="/risk-rules-blacklist"
            element={<RiksRulesBlacklist />}
          />
          <Route path="/risk-management" element={<RiksManagement />} />
          <Route path="/cnaes" element={<CnaesList />} />
          <Route
            path="/transaction-monitoring"
            element={<TransactionsMonitoring />}
          />
          <Route path="/risk-transactions" element={<RiskTransactions />} />

          <Route path="*" element={<Navigate to="/:token?" />} />
        </Routes>
      </Router>
    </SWRConfig>
  )
}

export default AppRoutes
