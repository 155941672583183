import { api, getHeaders } from './index'

export const getTaxs = async payload => {
  const { anualBilling, document, type, cnae, campaignId, uf } = payload

  try {
    const response = await api.get('/fiserv/taxs', {
      headers: getHeaders(),
      params: {
        campaignId,
        anualBilling,
        document,
        cnae,
        uf,
        type
      }
    })

    return response.data
  } catch (err) {
    throw err
  }
}
