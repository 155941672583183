import React, { useEffect, useState } from 'react'
import { DatePicker, Input, Select, Space, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import AppLayout from '../../components/app-layout/app-layout'
import { ContainerFilter, InputContainer, SearchTable } from './components'
import { tableColumns } from './tableColumns'
import useSwr from 'swr'
import { listMatriz } from '../../providers/api/accreditations'
import { statusTransactionsOptionsSelect } from '../../utils/status-transactions-options-select'
import { maskCurrency } from '../../utils/masks'
import moment from 'moment'
import { typeTransactions } from '../../utils/types-transactions'

const TransactionsMonitoring = () => {
  const formatDate = 'YYYY-MM-DD'
  const actualDate = moment().format(formatDate)
  const [searchValue, setSearchValue] = useState('')
  const [searchCNPJValue, setSearchCNPJValue] = useState('')
  const [selectValue, setSelectValue] = useState('')
  const [cardBrand, setCardBrand] = useState('')
  const [initialDate, setInitialDate] = useState(actualDate)
  const [type, setType] = useState('')
  const [finalDate, setFinalDate] = useState(actualDate)
  const [selectValueMatriz, setSelectValueMatriz] = useState('')
  const [page, setPage] = useState(1)
  const [companies, setCompanies] = useState([])

  const {
    data: { data = [], currentPage = 1 } = {},
    mutate,
    isLoading,
    isValidating
  } = useSwr(
    `/accreditation/transactions?page=${page}&cnpj=${searchCNPJValue.replace(/\D/g, '')}&matriz=${selectValueMatriz}&nomeCNPJ=${searchValue}&status=${selectValue}&cardBrand=${cardBrand}&initialDate=${initialDate}&finalDate=${finalDate}&type=${type}`
  )

  const { data: { count = 0, totalPages = 1, amount = 0 } = {} } = useSwr(
    `/accreditation/transactions/count?cnpj=${searchCNPJValue.replace(/\D/g, '')}&matriz=${selectValueMatriz}&nomeCNPJ=${searchValue}&status=${selectValue}&cardBrand=${cardBrand}&initialDate=${initialDate}&finalDate=${finalDate}&type=${type}`
  )

  useEffect(() => {
    listMatriz().then(companies => {
      setCompanies(
        companies.map(company => ({
          value: company.id,
          label: company.companyName
        }))
      )
    })
  }, [])

  return (
    <Spin
      spinning={isLoading || isValidating}
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
    >
      <AppLayout
        openKeys={['transaction-monitoring']}
        currentOption="transaction-monitoring"
      >
        <h2>Monitoramento de transações</h2>
        <div style={{ display: 'flex', gap: 20 }}>
          <h4>Total de transações: {count}</h4>
          <h4>Valor total de transações: {maskCurrency(amount)}</h4>
        </div>
        <p>Filtros iniciais são da data de hoje</p>
        <ContainerFilter>
          <InputContainer>
            <h4>Canal:</h4>
            <Select
              allowClear
              placeholder="Procurar por canal"
              options={companies}
              onChange={idMatriz => setSelectValueMatriz(idMatriz || '')}
            />
          </InputContainer>
          <InputContainer>
            <h4>CNPJ:</h4>
            <Input.Search
              placeholder="Procurar por CPF/CNPJ"
              value={searchCNPJValue}
              onSearch={value => {
                setSearchCNPJValue(value)
              }}
              onChange={e => setSearchCNPJValue(e.target.value)}
            />
          </InputContainer>
          <InputContainer>
            <h4>Loja:</h4>
            <Input.Search
              placeholder="Procurar por Nome"
              value={searchValue}
              onSearch={value => {
                setSearchValue(value)
              }}
              onChange={e => setSearchValue(e.target.value)}
            />
          </InputContainer>
          <InputContainer>
            <h4>Status:</h4>
            <Select
              allowClear
              placeholder="Procurar por Status"
              options={statusTransactionsOptionsSelect}
              onChange={status => setSelectValue(status || '')}
            ></Select>
          </InputContainer>
          <InputContainer>
            <h4>Tipo de transação:</h4>
            <Select
              allowClear
              placeholder="Procurar por tipo de transação"
              options={typeTransactions}
              onChange={type => setType(type || '')}
            />
          </InputContainer>
        </ContainerFilter>
        <ContainerFilter>
          <InputContainer>
            <h4>Bandeira:</h4>
            <Input.Search
              placeholder="Procurar por bandeira"
              value={cardBrand}
              onChange={e => setCardBrand(e.target.value)}
            />
          </InputContainer>
          <InputContainer>
            <h4>Data de início:</h4>
            <DatePicker
              allowClear
              onChange={e =>
                setInitialDate(e ? e.format('YYYY-MM-DD') : actualDate)
              }
              format="DD/MM/YYYY"
              placeholder="DD/MM/YYYY"
            />
          </InputContainer>
          <InputContainer>
            <h4>Data de fim:</h4>
            <DatePicker
              allowClear
              onChange={e =>
                setFinalDate(e ? e.format('YYYY-MM-DD') : actualDate)
              }
              format="DD/MM/YYYY"
              placeholder="DD/MM/YYYY"
            />
          </InputContainer>
        </ContainerFilter>

        <SearchTable
          pagination={{
            simple: true,
            position: ['topRight'],
            defaultCurrent: 1,
            total: count,
            showSizeChanger: false,
            size: totalPages,
            current: currentPage,
            onChange: page => setPage(page)
          }}
          bordered
          rowClassName={() => 'editable-row'}
          dataSource={data || []}
          rowKey="id"
          columns={tableColumns({
            searchValue,
            searchCNPJValue,
            selectValue,
            refresh: mutate
          })}
          locale={{ emptyText: 'Nenhuma transação encontrada.' }}
        />
      </AppLayout>
    </Spin>
  )
}

export default TransactionsMonitoring
