import React, { useCallback, useContext, useEffect, useState } from 'react'

import * as AccreditationHistoricComponents from './components'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import AppLayout from '../../components/app-layout/app-layout'
import {
  ArrowRightOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ControlOutlined,
  ExclamationOutlined,
  LoadingOutlined
} from '@ant-design/icons'
import {
  getCompany,
  getProposals,
  insertLojaSub,
  listMatriz,
  updateProposals
} from '../../providers/api/accreditations'
import { maskCpfCnpj, maskPhoneNumber, maskRg } from '../../utils/masks'
import moment from 'moment'
import { UserContext } from '../../Context/aplicationProvider'
import { checkLogin } from '../../providers/api/login'
import Cookies from 'js-cookies'
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Pagination,
  Radio,
  Select,
  Spin,
  Table,
  Tooltip,
  message
} from 'antd'
import dayjs from 'dayjs'
import { PIX_TYPES } from '../../utils/pix'
import overpricesDefault from '../../utils/overpricesDefault'
import { RegisterEcContext } from '../../Context/register-ec-provider'

const AccreditationHistoricAdmin = () => {
  const { technologies } = useContext(UserContext)
  const { getRates, rates } = useContext(RegisterEcContext)

  const navigate = useNavigate()
  const [proposals, setProposals] = useState([])
  const [pagination, setPagination] = useState({ currentPage: 1, count: 0 })
  const [statusFilter, setStatusFilter] = useState(null)
  const [requestFilter, setRequestFilter] = useState({
    page: 1
  })
  const [nameFilter, setNameFilter] = useState('')
  const [companyFilter, setCompanyFilter] = useState()
  const [initialDate, setInitialDate] = useState(null)
  const [finalDate, setFinalDate] = useState(null)
  const [selectedDate, setSelectedDate] = useState(null)
  const [hideenInputs, setHiddenInputs] = useState(false)
  const [uniqueKey, setUniqueKey] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isOpenModalHistory, setIsOpenModalHistory] = useState(false)
  const [spin, setSpint] = useState(false)
  const [messageApi, contextHolder] = message.useMessage()
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
  const accreditationStatusData = [
    {
      status: 'processing',
      icon: <ArrowRightOutlined />,
      label: 'Em Análise',
      class: 'accreditation-status-processing'
    },
    {
      status: 'waiting-risk-team',
      icon: <ArrowRightOutlined />,
      label: 'Aguardando Análise de Risco',
      class: 'accreditation-status-processing'
    },
    {
      status: 'request-update',
      icon: <ExclamationOutlined />,
      label: 'Ajuste Solicitado',
      class: 'accreditation-status-refused'
    },
    {
      status: 'waiting-se-config',
      icon: <ControlOutlined />,
      label: 'Aguardando configuração Software Express',
      class: 'accreditation-status-waiting-se-config'
    },
    {
      status: 'approved',
      icon: <CheckCircleOutlined />,
      label: 'Aprovado',
      class: 'accreditation-status-approved'
    },
    {
      status: 'refused',
      icon: <CloseCircleOutlined />,
      label: 'Reprovado',
      class: 'accreditation-status-refused'
    },
    {
      status: 'refused-by-risk-team',
      icon: <CloseCircleOutlined />,
      label: 'Reprovado Pelo Time de Risco',
      class: 'accreditation-status-refused'
    }
  ]

  const DynamicSelectedDate = [
    {
      label: 'Ultimos 30 dias',
      value: '30 dias'
    },
    {
      label: 'Ultimos 60 dias',
      value: '60 dias'
    },
    {
      label: 'Ultimos 90 dias',
      value: '90 dias'
    },
    {
      label: 'Período personalizado',
      value: 'personalizado'
    }
  ]

  const acquiresStatusData = [
    {
      status: 'processing',
      icon: <ArrowRightOutlined />,
      label: 'Aguardando Envio',
      class: 'accreditation-status-processing'
    },
    {
      status: 'sent-to-acquirer',
      icon: <ControlOutlined />,
      label: 'Proposta Enviada',
      class: 'accreditation-status-waiting-se-config'
    },
    {
      status: 'sent-to-acquirer-failed',
      icon: <CloseCircleOutlined />,
      label: 'Falha ao enviar proposta para adquirente',
      class: 'accreditation-status-refused'
    },
    {
      status: 'approved-by-acquirer',
      icon: <CheckCircleOutlined />,
      label: 'Aprovado',
      class: 'accreditation-status-approved'
    },
    {
      status: 'refused-by-acquirer',
      icon: <CloseCircleOutlined />,
      label: 'Recusado',
      class: 'accreditation-status-refused'
    }
  ]

  const accountTypes = [
    { value: 'CHECKING', label: 'Conta Corrente' },
    { value: 'GUARANTEED', label: 'Conta Garantida' },
    { value: 'CHECKING', label: 'Conta Poupança' },
    { value: 'PAYMENT', label: 'Conta Pagamento' }
  ]

  const [modalCurrentData, setModalCurrentData] = useState(false)
  const [form] = Form.useForm()

  useEffect(() => {
    async function getDataPage() {
      await getRates()
      const companies = await listMatriz()

      const uniqueCompanies = companies.map(company => ({
        idCompany: company.id,
        companyName: company.companyName
      }))

      setUniqueKey(uniqueCompanies)
    }

    getDataPage()
  }, [getRates])

  const handlegetProposals = useCallback(
    async payload => {
      setSpint(true)
      const requestPayload = payload || {
        page: pagination.currentPage
      }

      const response = await getProposals(requestPayload)

      setPagination({
        currentPage: response.currentPage,
        count: response.count
      })

      const proposalsWithCompany = []

      await Promise.all(
        (response?.data || []).map(async element => {
          const company = await getCompany({
            id: element.idCompany
          })

          proposalsWithCompany.push({
            ...element,
            idCompany: company.id,
            overprices:
              element?.overprices || JSON.stringify(overpricesDefault),
            companyName: company.companyName,
            technologiesqtd: technologies.filter(
              tech => tech.idCompany === company.id
            ).length,
            isRegisterSub: element.isRegisterSub,
            pfacMerchantId: element.pfacMerchantId
          })
          return element
        })
      )

      const sortedProposals = proposalsWithCompany.sort((a, b) => {
        return new Date(b.dtCreated) - new Date(a.dtCreated)
      })

      setProposals(sortedProposals)
      setSpint(false)
    },
    [pagination.currentPage, technologies]
  )

  const handlePagination = async page => {
    requestFilter.page = page
    await handlegetProposals({
      page,
      ...requestFilter
    })
  }

  async function onClearSelectFilter() {
    setNameFilter()
    setStatusFilter(null)
    setInitialDate()
    setFinalDate()
    setSelectedDate()
    setCompanyFilter()
    setRequestFilter(null)
    await handlegetProposals({
      page: 1
    })
  }

  function handleSelectedDate(filterDate) {
    const nowDays = moment().format('YYYY-MM-DD')
    const date30DaysAgo = moment().subtract(30, 'days').format('YYYY-MM-DD')
    const date60DaysAgo = moment().subtract(60, 'days').format('YYYY-MM-DD')
    const date90DaysAgo = moment().subtract(90, 'days').format('YYYY-MM-DD')

    if (filterDate === '30 dias') {
      setFinalDate(nowDays)
      setInitialDate(date30DaysAgo)
      setHiddenInputs(false)
    } else if (filterDate === '60 dias') {
      setFinalDate(nowDays)
      setInitialDate(date60DaysAgo)
      setHiddenInputs(false)
    } else if (filterDate === '90 dias') {
      setFinalDate(nowDays)
      setInitialDate(date90DaysAgo)
      setHiddenInputs(false)
    } else if (filterDate === 'personalizado') {
      setHiddenInputs(true)
      setFinalDate()
      setInitialDate()
    } else {
      setFinalDate()
      setInitialDate()
      setHiddenInputs(false)
    }
  }

  useEffect(() => {
    handleSelectedDate(selectedDate)
  }, [selectedDate])

  const handleFilter = async () => {
    const payload = {}

    if (selectedDate) {
      if (selectedDate === 'personalizado') {
        if (initialDate && finalDate) {
          if (moment(initialDate.$d).isAfter(finalDate.$d)) {
            messageApi.open({
              type: 'error',
              content: 'Data inicial não pode ser maior que a data final!'
            })
            return
          }
          payload.idCompany = companyFilter
          payload.proposal_status = statusFilter
          payload.startDate = moment(initialDate.$d).format('YYYY-MM-DD')
          payload.endDate = moment(finalDate.$d).format('YYYY-MM-DD')
          payload.name = nameFilter
        } else {
          messageApi.open({
            type: 'error',
            content: 'Data inicial e data final devem esta preenchidos!'
          })
          return
        }
      } else {
        payload.idCompany = companyFilter
        payload.proposal_status = statusFilter
        payload.startDate = initialDate
        payload.endDate = finalDate
        payload.name = nameFilter
      }
    } else {
      payload.idCompany = companyFilter
      payload.proposal_status = statusFilter
      payload.name = nameFilter
    }

    setRequestFilter(payload)
    payload.page = 1

    await handlegetProposals(payload)
  }

  const handleCheckAuth = async () => {
    const token = Cookies.getItem('token')
    if (token) {
      const response = await checkLogin(token)

      if (!response.status) {
        Cookies.removeItem('token')
        return navigate('/login')
      }

      if (Cookies.getItem('token')) {
      }
    } else {
      navigate('/login')
    }
  }

  const showModal = data => {
    setModalCurrentData(data)
    setIsModalOpen(true)
  }

  const getHistoryPix = (pixAdicional, { type, chave }) => {
    const historico = pixAdicional?.historico || []

    const isModifyType = pixAdicional?.type && pixAdicional?.type !== type
    const isModifyKey = pixAdicional?.chave && pixAdicional?.chave !== chave

    const isModify = isModifyType || isModifyKey

    if (isModify) {
      historico.push({
        usuarioNome: Cookies.getItem('userName'),
        type: PIX_TYPES[type],
        chave,
        dateUpdate: moment().format('DD/MM/YYYY HH:mm:ss')
      })
    }

    return historico
  }

  const getOverpriceUpdate = payload => {
    const overprices = {
      overprice_pix: payload.overprice_pix,
      'overprice_debit-card': payload.overprice_debit_card,
      'overprice_credit-card': payload.overprice_credit_card,
      overprice_boleto: payload.overprice_boleto,
      overprice_ted: payload.overprice_ted
    }

    const newOverprices = []

    Object.keys(overprices).forEach(key => {
      const [, typeOverprice] = key.split('_')
      newOverprices.push({
        type: typeOverprice,
        amount: Number(overprices[key]) || 0
      })
    })

    return _.merge(overpricesDefault, newOverprices)
  }

  const handleOk = async () => {
    setIsModalOpen(false)
    setModalCurrentData(false)

    const {
      overprice_pix,
      overprice_boleto,
      overprice_credit_card,
      overprice_debit_card,
      overprice_ted,
      pix_type,
      pix_chave
    } = form.getFieldsValue()

    const pixAdicional = JSON.parse(modalCurrentData.pixAdicional || '{}')

    const historicoPix = getHistoryPix(pixAdicional, {
      type: pix_type,
      chave: pix_chave
    })

    await updateInfoProposal({
      id: modalCurrentData.id,
      pixAdicional: {
        type:
          pix_type ||
          JSON.parse(modalCurrentData.pixAdicional || '{}')?.type ||
          '',
        chave:
          pix_chave ||
          JSON.parse(modalCurrentData.pixAdicional || '{}')?.chave ||
          '',
        historico: historicoPix
      },
      overprices: getOverpriceUpdate({
        overprice_pix,
        overprice_boleto,
        overprice_credit_card,
        overprice_debit_card,
        overprice_ted
      })
    })

    await handlegetProposals()
  }

  const updateInfoProposal = useCallback(async ({ id, ...rest }) => {
    try {
      await updateProposals({
        id,
        ...rest
      })
    } catch (err) {
      console.log(err)
    }
  }, [])

  const handleCancel = () => {
    setIsModalOpen(false)
    setModalCurrentData(false)
  }

  const toggleModalInfoHistoryPix = useCallback(() => {
    setIsOpenModalHistory(value => !value)
  }, [])

  const formMaskFunctions = {
    document: value => maskCpfCnpj(value),
    cellphone: value => maskPhoneNumber(value),
    telephone: value => maskPhoneNumber(value)
  }

  const handleInputsMask = inputChanged => {
    const currentFormInitialValues = form.getFieldsValue()

    const inputName = Object.keys(inputChanged)[0]

    if (formMaskFunctions[inputName]) {
      currentFormInitialValues[inputName] = formMaskFunctions[inputName](
        inputChanged[inputName]
      )

      form.setFieldsValue(currentFormInitialValues)
    }
  }

  const handleUpdateProposalStatus = async (values, id) => {
    try {
      setSpint(true)
      await updateProposals({
        id,
        observation: values.observation,
        proposal_status: values.proposal_status
      })
      const updatedProposal = proposals.map(proposal => {
        if (proposal.id === id) {
          return {
            ...proposal,
            proposal_status: values.proposal_status,
            observation: values.observation
          }
        } else {
          return proposal
        }
      })

      setProposals(updatedProposal)
      setSpint(false)
      messageApi.open({
        type: 'success',
        content: 'Status da proposta atualizado com sucesso!'
      })
    } catch (err) {
      setSpint(false)
      messageApi.open({
        type: 'error',
        content:
          'Erro ao atualizar status da proposta! Por favor, tente novamente!'
      })
      throw err
    }
  }

  const handleUpdateAdiqProposalStatus = async (values, id) => {
    try {
      setSpint(true)
      await updateProposals({
        id,
        adiq_proposal_status: values.adiq_proposal_status
      })
      const updatedProposal = proposals.map(proposal => {
        if (proposal.id === id) {
          return {
            ...proposal,
            adiq_proposal_status: values.adiq_proposal_status
          }
        } else {
          return proposal
        }
      })

      setProposals(updatedProposal)
      setSpint(false)
      messageApi.open({
        type: 'success',
        content: 'Status da ADIQ atualizado com sucesso!'
      })
    } catch (err) {
      setSpint(false)
      messageApi.open({
        type: 'error',
        content: 'Erro ao atualizar status da ADIQ! Por favor, tente novamente!'
      })
      throw err
    }
  }

  function getQtdTecnologia(element) {
    if (!element.adiq_proposal_data) {
      return 'Não informado'
    }
    const newForm = element.new || false
    const totalTerminals = element.adiq_proposal_data.captureSolutions.reduce(
      (total, capture) => {
        const numberOfTerminals = !newForm
          ? capture.numberOfTerminals || 0
          : capture.qtd || 0
        return total + numberOfTerminals
      },
      0
    )

    return totalTerminals > 0 ? totalTerminals : 'Não informado'
  }

  const hasChangeOverpricePercent = useCallback(e => {
    if (Number(e.target.value) > 100) {
      e.target.value = 100
      return
    }

    if (Number(e.target.value) < 0) {
      e.target.value = 0
    }
  }, [])

  const getOverpriceField = useCallback((overprices, type) => {
    if (!overprices) {
      return 0
    }
    const overprice = JSON.parse(overprices).find(ov => ov.type === type)
    if (!overprice) {
      return 0
    }
    return overprice.amount || 0
  }, [])

  const handleAddLojaSub = useCallback(
    async idCredenciamento => {
      try {
        setSpint(true)
        await insertLojaSub(idCredenciamento)
        await handlegetProposals(requestFilter)
        messageApi.open({
          type: 'success',
          content: 'MID disponível da loja'
        })
      } catch (err) {
        console.log(err)
        messageApi.open({
          type: 'error',
          content:
            'Algo deu errado ao inserir loja no modelo SUB, verifique os dados e tente novamente.'
        })
      } finally {
        setSpint(false)
      }
    },
    [messageApi, handlegetProposals, requestFilter]
  )

  useEffect(() => {
    if (modalCurrentData) {
      const fiservData = modalCurrentData?.fiserv_proposal_data || {}
      const dados = {
        document: modalCurrentData.cnpj
          ? maskCpfCnpj(modalCurrentData.cnpj)
          : maskCpfCnpj(modalCurrentData.cpf),
        faturamentoAnual: Number(fiservData?.annualBillingVolume || 0).toFixed(
          2
        ),
        faturamentoMensal: Number(
          fiservData?.legalPerson?.monthlyAverageBilling || 0
        ).toFixed(2),
        ticketMedio: Number(fiservData?.averageTicket || 0).toFixed(2),
        name: modalCurrentData.cnpj
          ? modalCurrentData.social_name
          : modalCurrentData.full_name,
        fantasyName: modalCurrentData.cnpj
          ? modalCurrentData.adiq_proposal_data.legalPerson.fantasyName
          : null,
        foundatingDate_birthDate: modalCurrentData.cnpj
          ? dayjs(
              modalCurrentData.adiq_proposal_data.legalPerson.openDate,
              'YYYY-MM-DD'
            )
          : dayjs(
              modalCurrentData.adiq_proposal_data.physicalPerson.birthDate,
              'YYYY-MM-DD'
            ),
        email: modalCurrentData.adiq_proposal_data.address[0].email,
        cnae: modalCurrentData.adiq_proposal_data.cnae,
        formConstitution:
          modalCurrentData.adiq_proposal_data.legalPerson?.formConstitution,
        address: modalCurrentData.adiq_proposal_data.address[0].street,
        address_number: modalCurrentData.adiq_proposal_data.address[0].number,
        address_neighborhood:
          modalCurrentData.adiq_proposal_data.address[0].neighborhood,
        address_city: modalCurrentData.adiq_proposal_data.address[0].city,
        address_state: modalCurrentData.adiq_proposal_data.address[0].state,
        address_zipcode: modalCurrentData.adiq_proposal_data.address[0].zipCode,
        fixedDdd: modalCurrentData.adiq_proposal_data.address[0].fixedDdd,
        fixedNumber: modalCurrentData.adiq_proposal_data.address[0].fixedNumber,
        mobileDdd: modalCurrentData.adiq_proposal_data.address[0].mobileDdd,
        mobileNumber:
          modalCurrentData.adiq_proposal_data.address[0].mobileNumber,
        delivery_name:
          modalCurrentData.adiq_proposal_data.contacts[0].firstName +
          ' ' +
          modalCurrentData.adiq_proposal_data.contacts[0].lastName,
        delivery_document: maskCpfCnpj(
          modalCurrentData.adiq_proposal_data.contacts[0].cpf
        ),
        delivery_birthdate: dayjs(
          modalCurrentData.adiq_proposal_data.contacts[0].birthDate,
          'YYYY-MM-DD'
        ),
        bank_data_institution:
          modalCurrentData.adiq_proposal_data.bankAccounts[0].bankNumber,
        bank_data_account_type: accountTypes.find(
          account =>
            account.value ===
            modalCurrentData.adiq_proposal_data.bankAccounts[0].accountType
        ).label,
        bank_data_agency:
          modalCurrentData.adiq_proposal_data.bankAccounts[0].agencyNumber,
        agencyDigit:
          modalCurrentData.adiq_proposal_data.bankAccounts[0].agencyDigit,
        bank_data_account:
          modalCurrentData.adiq_proposal_data.bankAccounts[0].accountNumber,
        bank_data_digit:
          modalCurrentData.adiq_proposal_data.bankAccounts[0].accountDigit,
        rg: modalCurrentData.adiq_proposal_data.rg
          ? modalCurrentData.adiq_proposal_data.rg
          : 'Não informado',
        political_exposed:
          typeof modalCurrentData.adiq_proposal_data.political_exposed !==
          'undefined'
            ? modalCurrentData.adiq_proposal_data.political_exposed
            : false,
        political_exposed_position:
          modalCurrentData.adiq_proposal_data?.political_exposed_position,
        political_exposed_government_agency:
          modalCurrentData.adiq_proposal_data
            ?.political_exposed_government_agency,
        overprice_pix: getOverpriceField(modalCurrentData.overprices, 'pix'),
        overprice_credit_card: getOverpriceField(
          modalCurrentData.overprices,
          'credit-card'
        ),
        overprice_debit_card: getOverpriceField(
          modalCurrentData.overprices,
          'debit-card'
        ),
        overprice_boleto: getOverpriceField(
          modalCurrentData.overprices,
          'boleto'
        ),
        overprice_ted: getOverpriceField(modalCurrentData.overprices, 'ted'),
        idCompany: modalCurrentData.idCompany,
        pix_type:
          JSON.parse(modalCurrentData?.pixAdicional || '{}')?.type || '',
        pix_chave:
          JSON.parse(modalCurrentData?.pixAdicional || '{}')?.chave || '',
        automatic_anticipation:
          modalCurrentData?.adiq_proposal_data?.prepaymentIndicator || false
      }

      if (modalCurrentData?.new) {
        dados.automatic_anticipation =
          modalCurrentData?.adiq_proposal_data?.automatic_anticipation || false
        dados.formPlano =
          modalCurrentData?.adiq_proposal_data?.physicalPerson?.formPlano || ''
        dados.is_voucher =
          modalCurrentData?.adiq_proposal_data?.is_voucher || false

        const vouchers = modalCurrentData?.adiq_proposal_data?.vouchers || []
        vouchers.forEach((voucher, index) => {
          dados[`voucher_${index}_bandeira`] = voucher?.bandeira || ''
          dados[`voucher_${index}_cod_ec`] = voucher?.codeEc || ''
        })
      }

      form.setFieldsValue(dados)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalCurrentData])

  useEffect(() => {
    handleCheckAuth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handlegetProposals()
  }, [handlegetProposals])

  return (
    <Spin indicator={antIcon} spinning={spin}>
      {contextHolder}
      <AppLayout currentOption="manager-ec">
        <Select
          allowClear
          placeholder="Filtrar por status"
          style={{ width: 400, marginTop: '2.5em', marginLeft: '1.3em' }}
          onChange={e => setStatusFilter(e)}
          value={statusFilter}
          onClear={onClearSelectFilter}
          options={accreditationStatusData.map(status => ({
            label: status.label,
            value: status.status
          }))}
        />
        <Select
          allowClear
          placeholder="Filtrar por canal"
          style={{ width: 400, marginTop: '2.5em', marginLeft: '1.3em' }}
          onChange={e => setCompanyFilter(e)}
          onClear={onClearSelectFilter}
          value={companyFilter}
          options={
            uniqueKey &&
            uniqueKey.map(company => ({
              label: company.companyName,
              value: company.idCompany
            }))
          }
        />
        <Input
          placeholder="Filtrar por razão social ou nome"
          style={{ width: 400, marginTop: '2.5em', marginLeft: '1.3em' }}
          onChange={e => setNameFilter(e.target.value)}
          value={nameFilter}
          onClear={onClearSelectFilter}
        />
        <Select
          allowClear
          placeholder="Filtrar por data de pedido"
          style={{ width: 400, marginTop: '2.5em', marginLeft: '1.3em' }}
          onChange={e => setSelectedDate(e)}
          onClear={onClearSelectFilter}
          value={selectedDate}
          options={DynamicSelectedDate.map(element => ({
            label: element.label,
            value: element.value
          }))}
        />

        {hideenInputs && (
          <>
            <DatePicker
              placeholder="Data inicial"
              format={'DD/MM/YYYY'}
              style={{ width: 270, marginTop: '2.5em', marginLeft: '1.4em' }}
              onChange={e => setInitialDate(e)}
              value={initialDate}
              onClear={onClearSelectFilter}
            />

            <DatePicker
              placeholder="Data final"
              format={'DD/MM/YYYY'}
              style={{ width: 270, marginTop: '2.5em', marginLeft: '0.5em' }}
              onChange={e => setFinalDate(e)}
              value={finalDate}
              onClear={onClearSelectFilter}
            />
          </>
        )}
        <Button
          onClick={handleFilter}
          style={{ width: 150, marginLeft: '2em' }}
          type="primary"
        >
          Filtrar
        </Button>
        <Button
          onClick={onClearSelectFilter}
          style={{ width: 150, marginLeft: '1em', marginTop: '2.5em' }}
          type="primary"
        >
          Limpar
        </Button>
        <Pagination
          onChange={handlePagination}
          style={{ marginTop: '2.5em' }}
          pageSize={10}
          showSizeChanger={false}
          defaultCurrent={1}
          total={pagination.count}
          current={pagination.currentPage}
        />
        <AccreditationHistoricComponents.ItemsWrapper>
          {proposals.length > 0 ? (
            proposals.map(element => {
              const findStatus = accreditationStatusData.find(
                status => status.status === element.proposal_status
              )
              const findFisevStatus = acquiresStatusData.find(
                status => status.status === element.fiserv_proposal_status
              )
              const findAdiqStatus = acquiresStatusData.find(
                status => status.status === element.adiq_proposal_status
              )

              if (findStatus) {
                return (
                  <AccreditationHistoricComponents.Item key={element.id}>
                    <AccreditationHistoricComponents.ItemInfo>
                      <AccreditationHistoricComponents.ItemInfoLabel>
                        {element.companyName} -{' '}
                      </AccreditationHistoricComponents.ItemInfoLabel>
                      <div>{element.id}</div>
                    </AccreditationHistoricComponents.ItemInfo>
                    <Spin indicator={antIcon} spinning={spin}>
                      <AccreditationHistoricComponents.ItemInfoWrapper>
                        <AccreditationHistoricComponents.ItemInfo>
                          <AccreditationHistoricComponents.ItemInfoLabel>
                            Status atual do credenciamento:{' '}
                          </AccreditationHistoricComponents.ItemInfoLabel>
                          <div className={findStatus.class}>
                            {' '}
                            {findStatus.label}{' '}
                          </div>
                        </AccreditationHistoricComponents.ItemInfo>

                        <AccreditationHistoricComponents.ItemInfo>
                          <AccreditationHistoricComponents.ItemInfoLabel>
                            {element.cnpj ? 'Razão Social:' : 'Nome:'}{' '}
                          </AccreditationHistoricComponents.ItemInfoLabel>
                          <div>
                            {element.cnpj
                              ? element.social_name
                              : element.full_name}
                          </div>
                        </AccreditationHistoricComponents.ItemInfo>

                        <AccreditationHistoricComponents.ItemInfo>
                          <AccreditationHistoricComponents.ItemInfoLabel>
                            {'Qtd Tecnologia:'}{' '}
                          </AccreditationHistoricComponents.ItemInfoLabel>
                          <div>{getQtdTecnologia(element)}</div>
                        </AccreditationHistoricComponents.ItemInfo>

                        <AccreditationHistoricComponents.ItemInfo>
                          <AccreditationHistoricComponents.ItemInfoLabel>
                            {element.cnpj || element.cpf ? 'CPF/CNPJ:' : 'RG:'}{' '}
                          </AccreditationHistoricComponents.ItemInfoLabel>
                          <div>
                            {element.cnpj || element.cpf
                              ? maskCpfCnpj(element.cnpj || element.cpf)
                              : maskRg(element.rg)}
                          </div>
                        </AccreditationHistoricComponents.ItemInfo>

                        <AccreditationHistoricComponents.ItemInfo>
                          <AccreditationHistoricComponents.ItemInfoLabel>
                            Data do pedido:{' '}
                          </AccreditationHistoricComponents.ItemInfoLabel>
                          <div>
                            {moment(String(element.dtCreated))
                              .subtract('3', 'hour')
                              .format('DD/MM/YYYY HH:mm:ss')}
                          </div>
                        </AccreditationHistoricComponents.ItemInfo>
                        {element.proposal_status !== 'refused-by-risk-team' && (
                          <>
                            <AccreditationHistoricComponents.ItemInfo>
                              <AccreditationHistoricComponents.ItemInfoLabel>
                                Atualizar Credenciamento:
                              </AccreditationHistoricComponents.ItemInfoLabel>
                            </AccreditationHistoricComponents.ItemInfo>
                            <Form
                              onFinish={values =>
                                handleUpdateProposalStatus(values, element.id)
                              }
                            >
                              <AccreditationHistoricComponents.ItemInfo>
                                <Form.Item
                                  initialValue={element.proposal_status}
                                  label="Status"
                                  name="proposal_status"
                                >
                                  <Select
                                    style={{ width: 250, marginRight: '1em' }}
                                    options={[
                                      {
                                        label: 'Em análise',
                                        value: 'processing'
                                      },
                                      {
                                        label: 'Ajuste Solicitado',
                                        value: 'request-update'
                                      },
                                      {
                                        label: 'Configuração Software express',
                                        value: 'waiting-se-config'
                                      },
                                      { label: 'Aprovado', value: 'approved' },
                                      { label: 'Reprovado', value: 'refused' }
                                    ]}
                                  />
                                </Form.Item>
                              </AccreditationHistoricComponents.ItemInfo>

                              <AccreditationHistoricComponents.ItemInfo>
                                <Form.Item
                                  initialValue={element.observation}
                                  label="Observação"
                                  name="observation"
                                >
                                  <Input.TextArea
                                    value={element.observation}
                                    rows="6"
                                    cols="40"
                                  />
                                </Form.Item>
                              </AccreditationHistoricComponents.ItemInfo>

                              <AccreditationHistoricComponents.ItemInfo>
                                <Form.Item>
                                  <Button type="primary" htmlType="submit">
                                    ATUALIZAR
                                  </Button>
                                </Form.Item>
                              </AccreditationHistoricComponents.ItemInfo>
                            </Form>
                          </>
                        )}

                        <AccreditationHistoricComponents.ItemInfo>
                          <AccreditationHistoricComponents.ItemInfoLabel>
                            Dados credenciamento:
                          </AccreditationHistoricComponents.ItemInfoLabel>
                          <Button onClick={() => showModal(element)}>
                            Visualizar dados
                          </Button>
                        </AccreditationHistoricComponents.ItemInfo>
                      </AccreditationHistoricComponents.ItemInfoWrapper>
                    </Spin>
                    {element.proposal_status !== 'refused-by-risk-team' && (
                      <>
                        <AccreditationHistoricComponents.ItemInfoWrapper>
                          <AccreditationHistoricComponents.ItemInfo>
                            <AccreditationHistoricComponents.ItemInfoLabel>
                              Status Atual FISERV:
                            </AccreditationHistoricComponents.ItemInfoLabel>
                            <div className={findFisevStatus.class}>
                              {findFisevStatus.label}
                            </div>
                          </AccreditationHistoricComponents.ItemInfo>

                          {element.fiserv_proposal_status ===
                            'sent-to-acquirer-failed' && (
                            <AccreditationHistoricComponents.ItemInfo>
                              <AccreditationHistoricComponents.ItemInfoLabel>
                                Error:
                              </AccreditationHistoricComponents.ItemInfoLabel>
                              <div style={{ width: 200 }}>
                                {JSON.stringify(element.fiserv_proposal_error)}
                              </div>
                            </AccreditationHistoricComponents.ItemInfo>
                          )}

                          <AccreditationHistoricComponents.ItemInfo>
                            <AccreditationHistoricComponents.ItemInfoLabel>
                              PFAC Screening Merchant ID:
                            </AccreditationHistoricComponents.ItemInfoLabel>
                            <p>{element.pfacMerchantId || 'Nada consta'}</p>
                          </AccreditationHistoricComponents.ItemInfo>
                        </AccreditationHistoricComponents.ItemInfoWrapper>

                        <AccreditationHistoricComponents.ItemInfoWrapper>
                          <AccreditationHistoricComponents.ItemInfo>
                            <AccreditationHistoricComponents.ItemInfoLabel>
                              Loja Sub MID:
                            </AccreditationHistoricComponents.ItemInfoLabel>
                            {!element.isRegisterSub ? (
                              <Button
                                type="primary"
                                onClick={() => handleAddLojaSub(element.id)}
                              >
                                ADICIONAR
                              </Button>
                            ) : (
                              <p>{element.commercialEstablishmentMid}</p>
                            )}
                          </AccreditationHistoricComponents.ItemInfo>
                        </AccreditationHistoricComponents.ItemInfoWrapper>

                        <Spin indicator={antIcon} spinning={spin}>
                          <AccreditationHistoricComponents.ItemInfoWrapper>
                            <AccreditationHistoricComponents.ItemInfo>
                              <AccreditationHistoricComponents.ItemInfoLabel>
                                Status Atual ADIQ:{' '}
                              </AccreditationHistoricComponents.ItemInfoLabel>
                              <div className={findAdiqStatus.class}>
                                {findAdiqStatus.label}
                              </div>
                            </AccreditationHistoricComponents.ItemInfo>

                            <Form
                              onFinish={values =>
                                handleUpdateAdiqProposalStatus(
                                  values,
                                  element.id
                                )
                              }
                            >
                              <AccreditationHistoricComponents.ItemInfo>
                                <Form.Item
                                  initialValue={element.adiq_proposal_status}
                                  name="adiq_proposal_status"
                                >
                                  <Select
                                    style={{ width: 250, marginRight: '1em' }}
                                    options={[
                                      {
                                        value: 'processing',
                                        label: 'Aguardando envio'
                                      },
                                      {
                                        value: 'sent-to-acquirer',
                                        label: 'Proposta Enviada'
                                      },
                                      {
                                        value: 'approved-by-acquirer',
                                        label: 'Aprovado'
                                      },
                                      {
                                        value: 'refused-by-acquirer',
                                        label: 'Reprovado'
                                      }
                                    ]}
                                  />
                                </Form.Item>

                                <Form.Item>
                                  <Button type="primary" htmlType="submit">
                                    ATUALIZAR STATUS ADIQ
                                  </Button>
                                </Form.Item>
                              </AccreditationHistoricComponents.ItemInfo>
                            </Form>
                          </AccreditationHistoricComponents.ItemInfoWrapper>
                        </Spin>
                      </>
                    )}
                  </AccreditationHistoricComponents.Item>
                )
              } else {
                return <span key={element.id}></span>
              }
            })
          ) : (
            <p>
              Nenhuma proposta encontrada, revise os filtros ou aperte em
              'Limpar' e tente novamente
            </p>
          )}
        </AccreditationHistoricComponents.ItemsWrapper>
      </AppLayout>
      <Modal
        cancelText="Cancelar"
        forceRender
        width="60%"
        title={'Dados do credenciamento - ' + modalCurrentData.id}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          style={{
            width: '100%'
          }}
          useRef
          form={form}
          name="step_one"
          layout="horizontal"
          onValuesChange={handleInputsMask}
        >
          <p className="title-page-info"> Dados cadastrais</p>
          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="CPF/CNPJ"
                name="document"
                rules={[{ required: true, message: 'Informe o CPF/CNPJ' }]}
              >
                <Input disabled readOnly maxLength={18} />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
            {/* <AccreditationHistoricComponents.AccreditationItem >
                                <Form.Item
                                    label="RG"
                                    name="rg"
                                    >
                                        <Input disabled readOnly maxLength={9} />
                                </Form.Item>
                            </AccreditationHistoricComponents.AccreditationItem>
                          */}
          </AccreditationHistoricComponents.AccreditantionWrapper>

          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Nome / Razão Social"
                name="name"
                rules={[
                  { required: true, message: 'Informe o Nome / Razão Social.' }
                ]}
              >
                <Input />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
          </AccreditationHistoricComponents.AccreditantionWrapper>

          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              {modalCurrentData.cnpj &&
                modalCurrentData.adiq_proposal_data.legalPerson.fantasyName && (
                  <AccreditationHistoricComponents.AccreditationItem>
                    <Form.Item
                      label="Nome Fantasia"
                      name="fantasyName"
                      rules={[
                        { required: true, message: ' Informe o Nome Fantasia.' }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </AccreditationHistoricComponents.AccreditationItem>
                )}
            </AccreditationHistoricComponents.AccreditationItem>

            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Data da fundação / Data Nascimento"
                name="foundatingDate_birthDate"
                rules={[
                  {
                    required: true,
                    message: 'Informe a Data da fundação / Data de nascimento.'
                  }
                ]}
              >
                <DatePicker
                  placeholder="Selecione uma data"
                  format="DD/MM/YYYY"
                />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
          </AccreditationHistoricComponents.AccreditantionWrapper>

          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="E-mail"
                name="email"
                rules={[{ required: true, message: 'Informe o email' }]}
              >
                <Input />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="CNAE"
                name="cnae"
                rules={[{ required: true, message: 'Informe o CNAE' }]}
              >
                <Input />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
          </AccreditationHistoricComponents.AccreditantionWrapper>

          <AccreditationHistoricComponents.AccreditantionWrapper>
            {modalCurrentData.cnpj &&
              modalCurrentData.adiq_proposal_data.legalPerson.fantasyName && (
                <AccreditationHistoricComponents.AccreditationItem>
                  <Form.Item
                    label="Tipo de Constituição"
                    name="formConstitution"
                    rules={[
                      {
                        required: true,
                        message: 'Informe o Tipo de Constituição'
                      }
                    ]}
                  >
                    <Select
                      options={[
                        { value: 1, label: 'Empresário Individual - EI' },
                        {
                          value: 2,
                          label: 'Microempreendedor Individual - MEI'
                        },
                        {
                          value: 3,
                          label:
                            'Empresa Individual de Responsabilidade Limitada - EIRELI'
                        },
                        { value: 4, label: 'Sociedade Empresária' },
                        { value: 5, label: 'Sociedade Simples' },
                        { value: 6, label: 'Sociedade Anônima - SA' },
                        { value: 7, label: 'Sociedade Limitada - LTDA' },
                        { value: 8, label: 'Micro Empresa - ME' },
                        { value: 9, label: 'Empresa Pequeno Porte' },
                        { value: 10, label: 'Empresa Médio Porte' },
                        { value: 11, label: 'Empresa Grande Porte' }
                      ]}
                    />
                  </Form.Item>
                </AccreditationHistoricComponents.AccreditationItem>
              )}
          </AccreditationHistoricComponents.AccreditantionWrapper>

          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Faturamento anual"
                name="faturamentoAnual"
                rules={[
                  {
                    required: true,
                    message: 'Informe o faturamento anual da empresa'
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Faturamento mensal"
                name="faturamentoMensal"
                rules={[
                  {
                    required: true,
                    message: 'Informe o faturamento mensal da empresa'
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Ticket médio"
                name="ticketMedio"
                rules={[
                  {
                    required: true,
                    message: 'Informe o ticket médio da empresa'
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
          </AccreditationHistoricComponents.AccreditantionWrapper>

          {modalCurrentData?.adiq_proposal_data?.physicalPerson?.formPlano && (
            <AccreditationHistoricComponents.AccreditantionWrapper>
              <AccreditationHistoricComponents.AccreditationItem
                style={{ width: 300 }}
              >
                <Form.Item
                  label="Plano"
                  name="formPlano"
                  disabled
                  rules={[{ required: true, message: 'Informe o plano' }]}
                >
                  <Select
                    options={rates?.map(rate => ({
                      value: rate.Id,
                      label: rate.Nome
                    }))}
                  />
                </Form.Item>
              </AccreditationHistoricComponents.AccreditationItem>
            </AccreditationHistoricComponents.AccreditantionWrapper>
          )}

          <p className="title-page-info"> Endereço Fiscal</p>
          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Endereço"
                name="address"
                rules={[
                  { required: true, message: ' Informe o Nome Endereço.' }
                ]}
              >
                <Input />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Número"
                name="address_number"
                rules={[
                  { required: true, message: ' Informe o Número do Endereço.' }
                ]}
              >
                <Input />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
          </AccreditationHistoricComponents.AccreditantionWrapper>
          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Bairro"
                name="address_neighborhood"
                rules={[{ required: true, message: ' Informe o Bairro.' }]}
              >
                <Input />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>

            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Cidade"
                name="address_city"
                rules={[{ required: true, message: ' Informe a Cidade.' }]}
              >
                <Input />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
          </AccreditationHistoricComponents.AccreditantionWrapper>

          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Estado"
                name="address_state"
                rules={[{ required: true, message: ' Informe o Estado' }]}
              >
                <Input />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>

            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="CEP"
                name="address_zipcode"
                rules={[{ required: true, message: ' Informe o CEP.' }]}
              >
                <Input />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
          </AccreditationHistoricComponents.AccreditantionWrapper>

          <p className="title-page-info"> Telefones</p>
          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="DDD Telefone fixo"
                name="fixedDdd"
                rules={[
                  { required: true, message: 'Informe o DDD do telefone fixo' }
                ]}
              >
                <Input maxLength={3} />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Número Telefone Fixo"
                name="fixedNumber"
                rules={[
                  { required: true, message: 'Informe o Número Telefone Fixo' }
                ]}
              >
                <Input />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
          </AccreditationHistoricComponents.AccreditantionWrapper>

          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="DDD Celular"
                name="mobileDdd"
                rules={[
                  { required: true, message: 'Informe o DDD do Celular' }
                ]}
              >
                <Input maxLength={3} />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Número Celular"
                name="mobileNumber"
                rules={[
                  { required: true, message: 'Informe o Número do Celular.' }
                ]}
              >
                <Input />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
          </AccreditationHistoricComponents.AccreditantionWrapper>

          <p className="title-page-info"> Representante Legal</p>
          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Nome Representante Legal"
                name="delivery_name"
                rules={[
                  {
                    required: true,
                    message: 'Informe o Nome do Representante.'
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="CPF Representante"
                name="delivery_document"
                rules={[
                  { required: true, message: 'Informe o CPF do Representante.' }
                ]}
              >
                <Input maxLength={14} />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
          </AccreditationHistoricComponents.AccreditantionWrapper>

          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Data de Nascimento Representante"
                name="delivery_birthdate"
                rules={[
                  {
                    required: true,
                    message: 'Informe a Data de Nascimento do Representante.'
                  }
                ]}
              >
                <DatePicker
                  placeholder="Selecione uma data"
                  format="DD/MM/YYYY"
                />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
          </AccreditationHistoricComponents.AccreditantionWrapper>

          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Pessoa Politicamente Exposta?"
                name="political_exposed"
                rules={[{ required: true, message: 'Informe uma resposta' }]}
              >
                <Radio.Group>
                  <Radio value={true}>Sim</Radio>
                  <Radio value={false}>Não</Radio>
                </Radio.Group>
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
          </AccreditationHistoricComponents.AccreditantionWrapper>

          {typeof modalCurrentData.adiq_proposal_data?.political_exposed !==
            'undefined' &&
            !modalCurrentData.adiq_proposal_data?.political_exposed && (
              <AccreditationHistoricComponents.AccreditantionWrapper>
                <AccreditationHistoricComponents.AccreditationItem>
                  <Form.Item
                    label="Cargo"
                    name="political_exposed_position"
                    rules={[{ required: true, message: 'Informe o cargo.' }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Orgão do Governo"
                    name="political_exposed_government_agency"
                    rules={[
                      { required: true, message: 'Informe o orgão do governo.' }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </AccreditationHistoricComponents.AccreditationItem>
              </AccreditationHistoricComponents.AccreditantionWrapper>
            )}

          <p className="title-page-info"> Dados Bancários</p>
          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Código do Banco"
                name="bank_data_institution"
                rules={[
                  { required: true, message: 'Informe o código do Banco.' }
                ]}
              >
                <Input />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
          </AccreditationHistoricComponents.AccreditantionWrapper>

          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Tipo de conta"
                name="bank_data_account_type"
                rules={[{ required: true, message: 'Informe o Tipo de conta' }]}
              >
                <Select
                  style={{ width: 400 }}
                  options={[
                    { value: 'CHECKING', label: 'Conta Corrente' },
                    { value: 'GUARANTEED', label: 'Conta Garantida' },
                    { value: 'CHECKING', label: 'Conta Poupança' },
                    { value: 'PAYMENT', label: 'Conta Pagamento' }
                  ]}
                />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
          </AccreditationHistoricComponents.AccreditantionWrapper>

          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Agência"
                name="bank_data_agency"
                rules={[{ required: true, message: 'Informe a agência.' }]}
              >
                <Input />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item label="Dígito da agência" name="agencyDigit">
                <Input />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
          </AccreditationHistoricComponents.AccreditantionWrapper>

          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Conta"
                name="bank_data_account"
                rules={[{ required: true, message: 'Informe a conta.' }]}
              >
                <Input />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Dígito"
                name="bank_data_digit"
                rules={[{ required: true, message: 'Informe o dígito.' }]}
              >
                <Input />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
          </AccreditationHistoricComponents.AccreditantionWrapper>

          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Antecipação automática"
                name="automatic_anticipation"
                rules={[{ required: true, message: 'Informe a conta.' }]}
              >
                <Radio.Group
                // onChange={e => setWantAntecipation(e.target.value)}
                >
                  <Radio value={true}>Habilitar</Radio>
                  <Radio value={false}>Não Habilitar</Radio>
                </Radio.Group>
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
          </AccreditationHistoricComponents.AccreditantionWrapper>

          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                tooltip={{ title: 'As informações de PIX serão atualizadas.' }}
                label="Tipo de PIX"
                name="pix_type"
              >
                <Select
                  style={{ width: 150 }}
                  options={Object.keys(PIX_TYPES).map(key => ({
                    value: key,
                    label: PIX_TYPES[key]
                  }))}
                />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item label="Chave PIX" name="pix_chave">
                <Input />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
          </AccreditationHistoricComponents.AccreditantionWrapper>

          {modalCurrentData?.adiq_proposal_data?.vouchers?.map((_, index) => (
            <AccreditationHistoricComponents.AccreditantionWrapper key={index}>
              <AccreditationHistoricComponents.AccreditationItem>
                <Form.Item
                  label={`Voucher(${index + 1}) - Bandeira`}
                  name={`voucher_${index}_bandeira`}
                >
                  <Input />
                </Form.Item>
              </AccreditationHistoricComponents.AccreditationItem>
              <AccreditationHistoricComponents.AccreditationItem>
                <Form.Item
                  label={`Voucher(${index + 1}) - Cod. do Estabelecimento`}
                  name={`voucher_${index}_cod_ec`}
                >
                  <Input />
                </Form.Item>
              </AccreditationHistoricComponents.AccreditationItem>
            </AccreditationHistoricComponents.AccreditantionWrapper>
          ))}

          <Button
            onClick={toggleModalInfoHistoryPix}
            style={{ width: 'max-content' }}
            type="primary"
          >
            Visualizar histórico de pix
          </Button>

          <p className="title-page-info">
            <Tooltip title="As informações da margem de ganho serão atualizadas.">
              Margem de ganho
            </Tooltip>
          </p>
          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Pix"
                name="overprice_pix"
                rules={[
                  {
                    required: true,
                    message: 'Informe a margem de ganho para pix.'
                  }
                ]}
              >
                <Input
                  type="number"
                  max={100}
                  min={0}
                  onInput={hasChangeOverpricePercent}
                />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
          </AccreditationHistoricComponents.AccreditantionWrapper>

          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Cartão de crédito"
                name="overprice_credit_card"
                rules={[
                  {
                    required: true,
                    message: 'Informe a margem de ganho para cartão de crédito.'
                  }
                ]}
              >
                <Input
                  type="number"
                  max={100}
                  min={0}
                  onInput={hasChangeOverpricePercent}
                />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
          </AccreditationHistoricComponents.AccreditantionWrapper>

          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Cartão de débito"
                name="overprice_debit_card"
                rules={[
                  {
                    required: true,
                    message: 'Informe a margem de ganho para cartão de débito.'
                  }
                ]}
              >
                <Input
                  type="number"
                  max={100}
                  min={0}
                  onInput={hasChangeOverpricePercent}
                />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
          </AccreditationHistoricComponents.AccreditantionWrapper>

          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Boleto"
                name="overprice_boleto"
                rules={[
                  {
                    required: true,
                    message: 'Informe a margem de ganho para boleto.'
                  }
                ]}
              >
                <Input
                  type="number"
                  max={100}
                  min={0}
                  onInput={hasChangeOverpricePercent}
                />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
          </AccreditationHistoricComponents.AccreditantionWrapper>

          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="TED"
                name="overprice_ted"
                rules={[
                  {
                    required: true,
                    message: 'Informe a margem de ganho para TED.'
                  }
                ]}
              >
                <Input
                  type="number"
                  max={100}
                  min={0}
                  onInput={hasChangeOverpricePercent}
                />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
          </AccreditationHistoricComponents.AccreditantionWrapper>

          {modalCurrentData.adiq_proposal_data?.legalPerson && (
            <>
              <p className="title-page-info"> Sócios</p>
              {modalCurrentData.adiq_proposal_data.legalPerson.companyPartners.map(
                (element, index) => (
                  <AccreditationHistoricComponents.AccreditantionDescriptionWrapper
                    key={index}
                  >
                    <AccreditationHistoricComponents.AccreditationDescription>
                      <div>
                        <strong>CPF/CNPJ:</strong>
                      </div>
                      <div>{maskCpfCnpj(element.cnpjCpf)}</div>
                    </AccreditationHistoricComponents.AccreditationDescription>
                    <AccreditationHistoricComponents.AccreditationDescription>
                      <div>
                        <strong>Nome Sócio:</strong>
                      </div>
                      <div>{element.firstName + ' ' + element.lastName}</div>
                    </AccreditationHistoricComponents.AccreditationDescription>
                    <AccreditationHistoricComponents.AccreditationDescription>
                      <div>
                        <strong>RG do Sócio:</strong>
                      </div>
                      <div>
                        {element?.rg ? maskRg(element.rg) : 'Não informado'}
                      </div>
                    </AccreditationHistoricComponents.AccreditationDescription>
                    <AccreditationHistoricComponents.AccreditationDescription>
                      <div>
                        <strong>Data de nascimento do Sócio</strong>
                      </div>
                      <div>
                        {element.birthDate
                          ? dayjs(element.birthDate, 'YYYY-MM-DD').format(
                              'DD/MM/YYYY'
                            )
                          : 'Não informado'}
                      </div>
                    </AccreditationHistoricComponents.AccreditationDescription>
                  </AccreditationHistoricComponents.AccreditantionDescriptionWrapper>
                )
              )}
            </>
          )}
        </Form>
      </Modal>

      <Modal
        okText="Fechar"
        forceRender
        width="60%"
        title="Histórico atualizações PIX"
        open={isOpenModalHistory}
        footer={null}
        onCancel={toggleModalInfoHistoryPix}
      >
        <Table
          style={{ width: '100%' }}
          pagination={{ simple: true, position: ['bottomCenter'] }}
          bordered
          dataSource={
            JSON.parse(modalCurrentData?.pixAdicional || '{}')?.historico || []
          }
          columns={[
            {
              title: 'Nome do usuário',
              dataIndex: 'usuarioNome'
            },
            {
              title: 'Nova chave PIX',
              dataIndex: 'pixKeyType',
              render(_, record) {
                const infos = [record.type || '', record.chave || ''].filter(
                  value => value.length
                )
                return infos.join(' - ')
              }
            },
            {
              title: 'Data de atualização',
              dataIndex: 'dateUpdate'
            }
          ]}
          rowKey="id"
          locale={{ emptyText: 'O histórico não possui dados.' }}
        />
      </Modal>
    </Spin>
  )
}

export default AccreditationHistoricAdmin
