import { UserContext, UserProvider } from './aplicationProvider'
import { RegisterEcProvider } from './register-ec-provider'

export const AppContext = ({ children }) => {
  return (
    <UserProvider>
      <RegisterEcProvider>{children}</RegisterEcProvider>
    </UserProvider>
  )
}
