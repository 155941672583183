import axios, { isAxiosError } from 'axios'
import Cookies from 'js-cookies'
import { getClientUtcOffset } from './login'

const ACCREDITATION_API_BASE_URL = process.env.REACT_APP_API_URL
// const ACCREDITATION_API_BASE_URL = 'https://accreditation.x-pay.app';

const getHeaders = () => {
  const token = Cookies.getItem('token')
  const companyId = Cookies.getItem('companyId')
  const userId = Cookies.getItem('userId')
  const userUTC = Cookies.getItem('userUTC')

  return {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json; charset=utf-8',
    Authorization: token,
    companyId,
    userId,
    userUTC
  }
}

const api = axios.create({
  baseURL: ACCREDITATION_API_BASE_URL
})

api.interceptors.request.use(
  request => {
    request.headers.set('Access-Control-Allow-Origin', '*')
    request.headers.set('Content-Type', 'application/json; charset=utf-8')
    request.headers.set('Authorization', Cookies.getItem('token'))
    request.headers.set('companyId', Cookies.getItem('companyId'))
    request.headers.set('userId', Cookies.getItem('userId'))
    request.headers.set('accessLevel', Cookies.getItem('accessLevel'))
    request.headers.set('userUTC', getClientUtcOffset())
    return request
  },
  error => error
)

api.interceptors.response.use(
  response => response,
  error => {
    try {
      if (isAxiosError(error)) {
        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line no-console
          console.log(error)
          console.log(
            `\n Method: ${error.response?.config?.method} \n Status: ${
              error?.response?.status
            }\n Message: ${
              error?.response?.data?.message
            }\n Description: ${JSON.stringify(
              error?.response?.data?.data
            )}\n Route: ${error?.response?.config?.url}`
          )
        }

        if (error.status === 403 || error.response?.status === 403) {
          Cookies.keys().forEach(item => {
            Cookies.removeItem(item)
          })
          window.location = '/login'
        }
      }
    } catch (error) {
      console.log(error)
    }
    return Promise.reject(error)
  }
)

export const fetcher = (url, args) =>
  api.get(url, { params: { ...args } }).then(({ data }) => data)

export { ACCREDITATION_API_BASE_URL, getHeaders, api }
