import React, { createContext, useCallback, useState } from 'react'
import {
  BankFilled,
  HomeFilled,
  IdcardFilled,
  InfoCircleFilled,
  ProductFilled,
  ProfileFilled
} from '@ant-design/icons'
import { Step1Company } from '../pages/01-personal-info/steps/Step1Company'
import { Step2Address } from '../pages/01-personal-info/steps/Step2Address'
import { Step3Responsabel } from '../pages/01-personal-info/steps/Step3AResponsable'
import { Step4Product } from '../pages/01-personal-info/steps/Step4Product'
import { Step5Bank } from '../pages/01-personal-info/steps/Step5Bank'
import { Step6Finish } from '../pages/01-personal-info/steps/Step6Finish'
import { cleanCpfCnpj, onlyNumbers } from '../utils/masks'
import { getCnaes, getCnpjCnaes } from '../providers/api/cnpj'
import { getDefaultRates, getPlanDetails } from '../providers/api/rates'

export const RegisterEcContext = createContext({})

export const StepRegisterEC = {
  Step1Company: 'Step1Company',
  Step2Address: 'Step2Address',
  Step3Responsabel: 'Step3Responsabel',
  Step4Product: 'Step4Product',
  Step5Bank: 'Step5Bank',
  Step6Finish: 'Step6Finish'
}

export function RegisterEcProvider({ children }) {
  const [step, setStep] = useState(StepRegisterEC.Step1Company)
  const [steps, setSteps] = useState({
    [StepRegisterEC.Step1Company]: {
      title: 'Empresa',
      subtitle: 'Dados comerciais',
      active: true,
      finished: false,
      Component: Step1Company,
      Icon: ProfileFilled
    },
    [StepRegisterEC.Step2Address]: {
      title: 'Endereço',
      subtitle: 'Endereço comercial',
      active: false,
      finished: false,
      Component: Step2Address,
      Icon: HomeFilled
    },
    [StepRegisterEC.Step3Responsabel]: {
      title: 'Responsável',
      subtitle: 'Responsável pelo negócio',
      active: false,
      finished: false,
      Component: Step3Responsabel,
      Icon: IdcardFilled
    },
    [StepRegisterEC.Step4Product]: {
      title: 'Produtos',
      subtitle: 'Configurações dos produtos',
      active: false,
      finished: false,
      Component: Step4Product,
      Icon: ProductFilled
    },
    [StepRegisterEC.Step5Bank]: {
      title: 'Dados bancários',
      subtitle: 'Domicílio bancário',
      active: false,
      finished: false,
      Component: Step5Bank,
      Icon: BankFilled
    },
    [StepRegisterEC.Step6Finish]: {
      title: 'Confirmar',
      subtitle: 'Confirmação do cadastro',
      active: false,
      finished: false,
      Component: Step6Finish,
      Icon: InfoCircleFilled
    }
  })
  const [data, setData] = useState({})
  const [cnaesData, setCnaesData] = useState([])
  const [rates, setRates] = useState([])
  const [spin, setSpint] = useState(false)
  const [plan, setPlan] = useState([])

  const changeStep = useCallback(
    ({ step: stepClicked, data: dataStep = {}, isFinished }) => {
      const isDataValidStep =
        !!Object.keys(data).length || !!Object.keys(dataStep).length
      if (
        (step === stepClicked && !isFinished) ||
        !(step in StepRegisterEC) ||
        !isDataValidStep
      ) {
        return
      }

      setStep(stepClicked)
      let isFinishSearch = false
      setSteps(old => {
        Object.keys(old).forEach(stepOldKey => {
          if (stepOldKey === stepClicked || isFinishSearch) {
            isFinishSearch = true
            old[stepOldKey].active = stepOldKey === stepClicked || isFinished
            old[stepOldKey].finished = isFinished || false
          } else {
            old[stepOldKey].active = true
            old[stepOldKey].finished = true
          }
        })
        return old
      })
      if (dataStep) {
        setData({ ...data, ...dataStep })
      }
    },
    [step, data]
  )

  const getCnaesData = useCallback(async cpfCnpj => {
    let response = []

    if (!cpfCnpj) {
      return response
    }

    const isPF = onlyNumbers(cpfCnpj).length === 11
    if (isPF) {
      response = await getCnaes()
    } else {
      response = await getCnpjCnaes(cleanCpfCnpj(cpfCnpj))
    }

    setCnaesData(response)
  }, [])

  const getRates = useCallback(async () => {
    const rates = await getDefaultRates()

    const filterRates = rates.reduce((acc, rate) => {
      if (String(rate.Nome).toLowerCase().includes('tabela taxas')) {
        const [nome] = rate.Nome.split('%')
        acc.push({
          ...rate,
          Nome: `${nome}%`.trim()
        })
      }
      return acc
    }, [])

    setRates(filterRates)

    return filterRates
  }, [])

  const getPlan = useCallback(async planId => {
    if (!planId) {
      setPlan([])
    } else {
      setPlan([])

      const response = await getPlanDetails(planId)

      setPlan(response)
    }
  }, [])

  const setLoading = useCallback((loading = false) => {
    setSpint(loading)
  }, [])

  const resetData = useCallback(() => {
    setData({})
    setStep(StepRegisterEC.Step1Company)
    setSteps(stepOld => {
      Object.keys(stepOld).forEach((stepOldKey, index) => {
        stepOld[stepOldKey].active = index === 0
        stepOld[stepOldKey].finished = false
      })
      return stepOld
    })
  }, [])

  return (
    <RegisterEcContext.Provider
      value={{
        step,
        steps,
        changeStep,
        data,
        cnaesData,
        getCnaesData,
        rates,
        getRates,
        plan,
        getPlan,
        setLoading,
        loading: spin,
        resetData
      }}
    >
      {children}
    </RegisterEcContext.Provider>
  )
}
