import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Col, Form, Input, Row, Select } from 'antd'
import moment from 'moment'
import Cookies from 'js-cookies'
import { PersonalInfo } from '../01-personal-info/index'
import { getBanks } from '../../providers/api/banks'
import WorkingHours from '../11-working-hours/index'
import AppLayout from '../../components/app-layout/app-layout'

import { PIX_TYPES } from '../../utils/pix'

const BankData = props => {
  const { previousStepData } = props
  const [formInitialValues, setFormInitialValues] = useState({
    partner_cpf: '',
    accountOwnerName: previousStepData
      ? previousStepData.physicalPerson
        ? previousStepData.physicalPerson.name
        : previousStepData.legalPerson.socialReason
      : '',
    partner_name: '',
    partner_birth_date: '',
    partner_nacionality: '',
    partner_company_participation: '',
    bacenPermission: false,
    pix_mesmo_domicilio_bancario: true,
    pix_type: null,
    pix_chave: ''
  })

  const [nextSteps, setNextStep] = useState(false)
  const [prevStep, setPrevStep] = useState(false)
  const [formData, setFormData] = useState({})
  const [banks, setBanks] = useState([])
  const [form] = Form.useForm()
  const brandsIds = [
    '50',
    '51',
    '52',
    '53',
    '54',
    '55',
    '56',
    '59',
    '60',
    '61',
    '63'
  ]

  const handleOnFinish = data => {
    const bankAccounts = []

    brandsIds.forEach(element => {
      bankAccounts.push({
        id: element,
        bankNumber: data.bank_data_institution,
        agencyNumber: data.bank_data_agency,
        agencyDigit:
          !data.agencyDigit || data.agencyDigit === '' ? '0' : data.agencyDigit,
        accountNumber: data.bank_data_account,
        accountDigit: data.bank_data_digit,
        accountType:
          data.bank_data_account_type === 'SAVINGS'
            ? 'CHECKING'
            : data.bank_data_account_type,
        accountOwnerName: data.accountOwnerName
      })
    })

    const payload = {
      ...previousStepData,
      bankAccounts,
      bacenPermission: data.bacenPermission,
      pixAdicional: {
        type: data.pix_type,
        chave: data.pix_chave,
        historico: [
          {
            usuarioNome: Cookies.getItem('userName'),
            type: PIX_TYPES[data.pix_type],
            chave: data.pix_chave,
            dateUpdate: moment().format('DD/MM/YYYY HH:mm:ss')
          }
        ]
      }
    }

    setFormData(payload)
    setNextStep(true)
  }

  const handleGetBanks = async () => {
    const response = await getBanks()

    const compareByBankNumber = (a, b) => {
      return a.bankNumber - b.bankNumber
    }

    const sortedByBanksNumber = response.sort(compareByBankNumber)

    setBanks(sortedByBanksNumber)
  }

  useEffect(() => {
    handleGetBanks()
  }, [])

  return (
    <>
      {!nextSteps && !prevStep && (
        <AppLayout>
          <p className="title-page-info">
            {' '}
            Dados bancários para recebimento de vendas
          </p>
          <div className="form-container">
            <Form
              useRef
              form={form}
              name="basic"
              style={{ width: 800 }}
              initialValues={formInitialValues}
              onFinish={handleOnFinish}
            >
              <Form.Item
                label="Nome dono da conta"
                name="accountOwnerName"
                rules={[
                  {
                    required: true,
                    message: 'Informe o nome do dono da conta.'
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Tipo de conta"
                name="bank_data_account_type"
                rules={[{ required: true, message: 'Informe o Tipo de conta' }]}
              >
                <Select
                  options={[
                    { value: 'CHECKING', label: 'Conta Corrente' },
                    { value: 'GUARANTEED', label: 'Conta Garantida' },
                    { value: 'SAVINGS', label: 'Conta Poupança' },
                    { value: 'PAYMENT', label: 'Conta Pagamento' }
                  ]}
                />
              </Form.Item>

              <Form.Item
                label="Banco"
                name="bank_data_institution"
                rules={[{ required: true, message: 'Informe o banco.' }]}
              >
                <Select
                  showSearch
                  showArrow
                  filterOption={(input, option) =>
                    (option?.label.toUpperCase() ?? '').includes(
                      input.toUpperCase()
                    )
                  }
                  options={banks.map(element => ({
                    value: element.bankNumber,
                    label: `${element.bankNumber} - ${element.bankName}`
                  }))}
                />
              </Form.Item>

              <Row>
                <Col>
                  <Form.Item
                    label="Conta"
                    name="bank_data_account"
                    rules={[{ required: true, message: 'Informe a conta.' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col offset={1}>
                  <Form.Item
                    label="Dígito"
                    name="bank_data_digit"
                    rules={[{ required: true, message: 'Informe o dígito.' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item
                    label="Agência"
                    name="bank_data_agency"
                    rules={[{ required: true, message: 'Informe a agência.' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col offset={1}>
                  <Form.Item label="Dígito da agência" name="agencyDigit">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={10}>
                  <Form.Item label="Tipo de PIX" name="pix_type">
                    <Select
                      options={Object.keys(PIX_TYPES).map(key => ({
                        value: key,
                        label: PIX_TYPES[key]
                      }))}
                    />
                  </Form.Item>
                </Col>
                <Col offset={1}>
                  <Form.Item label="Chave PIX" name="pix_chave">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                label="Estou de acordo com a validação de dados junto ao Banco Central do Brasil"
                name="bacenPermission"
              >
                <Checkbox
                  checked={form.getFieldValue('bacenPermission')}
                  onChange={e => {
                    form.setFieldValue('bacenPermission', e.target.checked)
                    setFormInitialValues({
                      ...formInitialValues,
                      bacenPermission: e.target.checked
                    })
                  }}
                />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Próxima Etapa
                </Button>
              </Form.Item>

              <Form.Item>
                <Button danger onClick={() => setPrevStep(true)}>
                  cancelar
                </Button>
              </Form.Item>
            </Form>
          </div>
        </AppLayout>
      )}
      {nextSteps && <WorkingHours previousStepData={formData} />}

      {prevStep && <PersonalInfo formDataPreLoaded={previousStepData} />}
    </>
  )
}

export default BankData
