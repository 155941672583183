import React, { useContext, useEffect, useState } from 'react'

import * as AccreditationHistoricComponents from './components'
import { useNavigate } from 'react-router-dom'
import AppLayout from '../../../components/app-layout/app-layout'
import {
  ArrowRightOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ControlOutlined,
  ExclamationOutlined,
  LoadingOutlined
} from '@ant-design/icons'
import {
  getCompany,
  getProposals,
  listMatriz,
  saveMccCnaeRisk,
  updateProposals
} from '../../../providers/api/accreditations'
import { sendProposalRiskTeam } from '../../../providers/api/sendProposal'
import { maskCpfCnpj, maskPhoneNumber, maskRg } from '../../../utils/masks'
import moment from 'moment'
import { checkLogin } from '../../../providers/api/login'
import Cookies from 'js-cookies'
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Pagination,
  Radio,
  Select,
  Spin,
  Tag,
  message
} from 'antd'
import dayjs from 'dayjs'
import { PIX_TYPES } from '../../../utils/pix'
import { RegisterEcContext } from '../../../Context/register-ec-provider'

const RiskManagement = () => {
  const { getRates, rates } = useContext(RegisterEcContext)

  const navigate = useNavigate()
  const [proposals, setProposals] = useState([])
  const [pagination, setPagination] = useState({ currentPage: 1, count: 0 })
  const [requestFilter, setRequestFilter] = useState({
    page: 1
  })
  const [statusFilter, setStatusFilter] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isRiskLevelModalOpen, setIsRiskLevelModalOpen] = useState(false)
  const [initialDate, setInitialDate] = useState(null)
  const [finalDate, setFinalDate] = useState(null)
  const [selectedDate, setSelectedDate] = useState(null)
  const [hideenInputs, setHiddenInputs] = useState(false)
  const [spin, setSpint] = useState(false)
  const [companyFilter, setCompanyFilter] = useState()
  const [uniqueKey, setUniqueKey] = useState([])
  const [nameFilter, setNameFilter] = useState('')
  const [messageApi, contextHolder] = message.useMessage()
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
  const accreditationStatusData = [
    {
      status: 'processing',
      icon: <ArrowRightOutlined />,
      label: 'Em Análise',
      class: 'accreditation-status-processing'
    },
    {
      status: 'waiting-risk-team',
      icon: <ArrowRightOutlined />,
      label: 'Aguardando Análise de Risco',
      class: 'accreditation-status-processing'
    },
    {
      status: 'request-update',
      icon: <ExclamationOutlined />,
      label: 'Ajuste Solicitado',
      class: 'accreditation-status-refused'
    },
    {
      status: 'waiting-se-config',
      icon: <ControlOutlined />,
      label: 'Aguardando configuração Software Express',
      class: 'accreditation-status-waiting-se-config'
    },
    {
      status: 'approved',
      icon: <CheckCircleOutlined />,
      label: 'Aprovado',
      class: 'accreditation-status-approved'
    },
    {
      status: 'refused',
      icon: <CloseCircleOutlined />,
      label: 'Reprovado',
      class: 'accreditation-status-refused'
    },
    {
      status: 'refused-by-risk-team',
      icon: <CloseCircleOutlined />,
      label: 'Reprovado Pelo Time de Risco',
      class: 'accreditation-status-refused'
    }
  ]

  const DynamicSelectedDate = [
    {
      label: 'Ultimos 30 dias',
      value: '30 dias'
    },
    {
      label: 'Ultimos 60 dias',
      value: '60 dias'
    },
    {
      label: 'Ultimos 90 dias',
      value: '90 dias'
    },
    {
      label: 'Período personalizado',
      value: 'personalizado'
    }
  ]

  const acquiresStatusData = [
    {
      status: 'processing',
      icon: <ArrowRightOutlined />,
      label: 'Aguardando Envio',
      class: 'accreditation-status-processing'
    },
    {
      status: 'sent-to-acquirer',
      icon: <ControlOutlined />,
      label: 'Proposta Enviada',
      class: 'accreditation-status-waiting-se-config'
    },
    {
      status: 'sent-to-acquirer-failed',
      icon: <CloseCircleOutlined />,
      label: 'Falha ao enviar proposta para adquirente',
      class: 'accreditation-status-refused'
    },
    {
      status: 'approved-by-acquirer',
      icon: <CheckCircleOutlined />,
      label: 'Aprovado',
      class: 'accreditation-status-approved'
    },
    {
      status: 'refused-by-acquirer',
      icon: <CloseCircleOutlined />,
      label: 'Recusado',
      class: 'accreditation-status-refused'
    }
  ]

  const accountTypes = [
    { value: 'CHECKING', label: 'Conta Corrente' },
    { value: 'GUARANTEED', label: 'Conta Garantida' },
    { value: 'CHECKING', label: 'Conta Poupança' },
    { value: 'PAYMENT', label: 'Conta Pagamento' }
  ]

  useEffect(() => {
    async function getDataPage() {
      const companies = await listMatriz()
      await getRates()

      const uniqueCompanies = companies.map(company => ({
        idCompany: company.id,
        companyName: company.companyName
      }))

      setUniqueKey(uniqueCompanies)
    }
    getDataPage()
  }, [getRates])

  function handleSelectedDate(filterDate) {
    const nowDays = moment().format('YYYY-MM-DD')
    const date30DaysAgo = moment().subtract(30, 'days').format('YYYY-MM-DD')
    const date60DaysAgo = moment().subtract(60, 'days').format('YYYY-MM-DD')
    const date90DaysAgo = moment().subtract(90, 'days').format('YYYY-MM-DD')

    if (filterDate === '30 dias') {
      setFinalDate(nowDays)
      setInitialDate(date30DaysAgo)
      setHiddenInputs(false)
    } else if (filterDate === '60 dias') {
      setFinalDate(nowDays)
      setInitialDate(date60DaysAgo)
      setHiddenInputs(false)
    } else if (filterDate === '90 dias') {
      setFinalDate(nowDays)
      setInitialDate(date90DaysAgo)
      setHiddenInputs(false)
    } else if (filterDate === 'personalizado') {
      setHiddenInputs(true)
      setFinalDate()
      setInitialDate()
    } else {
      setFinalDate()
      setInitialDate()
      setHiddenInputs(false)
    }
  }

  useEffect(() => {
    handleSelectedDate(selectedDate)
  }, [selectedDate])

  const handleFilter = async () => {
    const payload = {}

    if (selectedDate) {
      if (selectedDate === 'personalizado') {
        if (initialDate && finalDate) {
          if (moment(initialDate.$d).isAfter(finalDate.$d)) {
            messageApi.open({
              type: 'error',
              content: 'Data inicial não pode ser maior que a data final!'
            })
            return
          }
          payload.idCompany = companyFilter
          payload.proposal_status = statusFilter
          payload.startDate = moment(initialDate.$d).format('YYYY-MM-DD')
          payload.endDate = moment(finalDate.$d).format('YYYY-MM-DD')
          payload.name = nameFilter
        } else {
          messageApi.open({
            type: 'error',
            content: 'Data inicial e data final devem esta preenchidos!'
          })
          return
        }
      } else {
        payload.idCompany = companyFilter
        payload.proposal_status = statusFilter
        payload.startDate = initialDate
        payload.endDate = finalDate
        payload.name = nameFilter
      }
    } else {
      payload.idCompany = companyFilter
      payload.proposal_status = statusFilter
      payload.name = nameFilter
    }

    setRequestFilter(payload)
    payload.page = 1

    await handlegetProposals(payload)
  }

  const [modalCurrentData, setModalCurrentData] = useState(false)
  const [form] = Form.useForm()

  const [riskLevelCurrentData, setLevelRiskCurrentData] = useState(false)
  const [riskForm] = Form.useForm()

  const riskLevels = [
    {
      label: 'High Risk Merchants',
      value: 'High Risk Merchants',
      component: <Tag color="warning">High Risk Merchants</Tag>
    },
    {
      label: 'Low Risk Merchants',
      value: 'Low Risk Merchants',
      component: <Tag color="processing">Low Risk Merchants</Tag>
    },
    {
      label: 'Moderate Risk Merchants',
      value: 'Moderate Risk Merchants',
      component: <Tag color="warning">Moderate Risk Merchants</Tag>
    },
    {
      label: 'Prohibited Merchants',
      value: 'Prohibited Merchants',
      component: <Tag color="error">Prohibited Merchants</Tag>
    },
    {
      label: 'Restricted Merchants',
      value: 'Restricted Merchants',
      component: <Tag color="error">Restricted Merchants</Tag>
    }
  ]

  const handlegetProposals = async payload => {
    try {
      setSpint(true)
      const requestPayload = payload || {
        page: pagination.currentPage
      }

      const response = await getProposals(requestPayload)

      setPagination({
        currentPage: response.currentPage,
        count: response.count
      })

      const proposalsWithCompany = await Promise.all(
        response.data.map(async element => {
          const company = await getCompany({
            id: element.idCompany
          })
          return {
            ...element,
            companyName: company.companyName
          }
        })
      )

      const sortedProposals = proposalsWithCompany.sort((a, b) => {
        return new Date(b.dtCreated) - new Date(a.dtCreated)
      })

      setProposals(sortedProposals)
    } catch (error) {
      messageApi.open({
        type: 'error',
        content: 'Erro ao buscar propostas! Por favor, tente novamente!'
      })
      throw error
    } finally {
      setSpint(false)
    }
  }

  const handlePagination = async page => {
    requestFilter.page = page
    await handlegetProposals({
      page,
      ...requestFilter
    })
  }

  const handleCheckAuth = async () => {
    const token = Cookies.getItem('token')
    if (token) {
      const response = await checkLogin(token)

      if (!response.status) {
        Cookies.removeItem('token')
        return navigate('/login')
      }

      // if (Cookies.getItem('token')) {
      // }
    } else {
      navigate('/login')
    }
  }

  // Dados credenciamento modal

  const showModal = data => {
    setModalCurrentData(data)
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
    setModalCurrentData(false)
  }

  const handleCancel = () => {
    setModalCurrentData({})
    setIsModalOpen(false)
    setModalCurrentData(false)
  }

  // Fim dados credenciamento modal

  // Nivel de risco modal
  const showRiskLevelModal = data => {
    riskForm.setFieldsValue({
      cnae: data.fiserv_proposal_data.cnae,
      mcc: String(data.adiq_proposal_data.mcc),
      risk_category: data.risk_level
    })
    setModalCurrentData(data)
    setLevelRiskCurrentData(data)
    setIsRiskLevelModalOpen(true)
  }

  const handleOkRiskLevelModal = () => {
    setIsRiskLevelModalOpen(false)
    setModalCurrentData(false)
    setLevelRiskCurrentData(false)
  }

  const handleCancelRiskLevelModal = () => {
    setModalCurrentData({})
    setIsRiskLevelModalOpen(false)
    setModalCurrentData(false)
    setLevelRiskCurrentData(false)
  }

  // Fim nivel de risco modal

  const formMaskFunctions = {
    document: value => maskCpfCnpj(value),
    cellphone: value => maskPhoneNumber(value),
    telephone: value => maskPhoneNumber(value)
  }

  const handleInputsMask = inputChanged => {
    const currentFormInitialValues = form.getFieldsValue()

    const inputName = Object.keys(inputChanged)[0]

    if (formMaskFunctions[inputName]) {
      currentFormInitialValues[inputName] = formMaskFunctions[inputName](
        inputChanged[inputName]
      )

      form.setFieldsValue(currentFormInitialValues)
    }
  }

  const handleUpdateProposalStatus = async (values, id) => {
    try {
      setSpint(true)

      if (
        values.proposal_status === 'refused-by-risk-team' ||
        values.proposal_status === 'waiting-risk-team' ||
        values.proposal_status === 'processing'
      ) {
        await updateProposals({
          id,
          observation: values.observation,
          proposal_status: values.proposal_status
        })
        const updatedProposal = proposals.map(proposal => {
          if (proposal.id === id) {
            return {
              ...proposal,
              proposal_status: values.proposal_status,
              observation: values.observation
            }
          } else {
            return proposal
          }
        })
        setProposals(updatedProposal)
      } else {
        await sendProposalRiskTeam(id)
        handlegetProposals()
      }

      setSpint(false)
      messageApi.open({
        type: 'success',
        content: 'Status da proposta atualizado com sucesso!'
      })
    } catch (err) {
      setSpint(false)
      messageApi.open({
        type: 'error',
        content:
          'Erro ao atualizar status da proposta! Por favor, tente novamente!'
      })
      throw err
    }
  }

  const saveRiskLavel = async data => {
    setSpint(true)

    const proposalId = riskLevelCurrentData.id

    await saveMccCnaeRisk(data)
    await updateProposals({
      id: proposalId,
      risk_level: data.risk_category
    })

    const updateProposal = [...proposals]
    const findProposal = updateProposal.findIndex(
      proposal => proposal.id === proposalId
    )
    if (findProposal !== -1) {
      updateProposal[findProposal].risk_level = data.risk_category
    }

    setIsRiskLevelModalOpen(false)
    setProposals(updateProposal)

    setSpint(false)
  }

  const handleSaveCancellationChargeBack = async (data, id, index) => {
    setSpint(true)
    await updateProposals({
      id,
      cancellation_chargeback_days:
        data[`cancellation_chargeback_days_${index}`],
      cancellation_chargeback_percent:
        data[`cancellation_chargeback_percent_${index}`]
    })

    const proposalsNew = proposals.map(element => {
      if (element.id !== id) {
        return element
      } else {
        const payload = {
          ...element
        }

        payload.cancellation_chargeback_days =
          data[`cancellation_chargeback_days_${index}`]

        payload.cancellation_chargeback_percent =
          data[`cancellation_chargeback_percent_${index}`]

        return payload
      }
    })

    setProposals(proposalsNew)

    setSpint(false)
  }

  const editCancellationChargeBack = async id => {
    const proposalsNew = proposals.map(element => {
      if (element.id !== id) {
        return element
      } else {
        delete element.cancellation_chargeback_days
        delete element.cancellation_chargeback_percent

        return element
      }
    })

    setProposals(proposalsNew)
  }

  useEffect(() => {
    if (modalCurrentData) {
      const dados = {
        document: modalCurrentData.cnpj
          ? maskCpfCnpj(modalCurrentData.cnpj)
          : maskCpfCnpj(modalCurrentData.cpf),
        name: modalCurrentData.cnpj
          ? modalCurrentData.social_name
          : modalCurrentData.full_name,
        fantasyName: modalCurrentData.cnpj
          ? modalCurrentData.adiq_proposal_data.legalPerson.fantasyName
          : null,
        foundatingDate_birthDate: modalCurrentData.cnpj
          ? dayjs(
              modalCurrentData.adiq_proposal_data.legalPerson.openDate,
              'YYYY-MM-DD'
            )
          : dayjs(
              modalCurrentData.adiq_proposal_data.physicalPerson.birthDate,
              'YYYY-MM-DD'
            ),
        email: modalCurrentData.adiq_proposal_data.address[0].email,
        formConstitution:
          modalCurrentData.adiq_proposal_data.legalPerson?.formConstitution,
        address: modalCurrentData.adiq_proposal_data.address[0].street,
        address_number: modalCurrentData.adiq_proposal_data.address[0].number,
        address_neighborhood:
          modalCurrentData.adiq_proposal_data.address[0].neighborhood,
        address_city: modalCurrentData.adiq_proposal_data.address[0].city,
        address_state: modalCurrentData.adiq_proposal_data.address[0].state,
        address_zipcode: modalCurrentData.adiq_proposal_data.address[0].zipCode,
        fixedDdd: modalCurrentData.adiq_proposal_data.address[0].fixedDdd,
        fixedNumber: modalCurrentData.adiq_proposal_data.address[0].fixedNumber,
        mobileDdd: modalCurrentData.adiq_proposal_data.address[0].mobileDdd,
        mobileNumber:
          modalCurrentData.adiq_proposal_data.address[0].mobileNumber,
        delivery_name:
          modalCurrentData.adiq_proposal_data.contacts[0].firstName +
          ' ' +
          modalCurrentData.adiq_proposal_data.contacts[0].lastName,
        delivery_document: maskCpfCnpj(
          modalCurrentData.adiq_proposal_data.contacts[0].cpf
        ),
        delivery_birthdate: dayjs(
          modalCurrentData.adiq_proposal_data.contacts[0].birthDate,
          'YYYY-MM-DD'
        ),
        bank_data_institution:
          modalCurrentData.adiq_proposal_data.bankAccounts[0].bankNumber,
        bank_data_account_type: accountTypes.find(
          account =>
            account.value ===
            modalCurrentData.adiq_proposal_data.bankAccounts[0].accountType
        ).label,
        bank_data_agency:
          modalCurrentData.adiq_proposal_data.bankAccounts[0].agencyNumber,
        agencyDigit:
          modalCurrentData.adiq_proposal_data.bankAccounts[0].agencyDigit,
        bank_data_account:
          modalCurrentData.adiq_proposal_data.bankAccounts[0].accountNumber,
        bank_data_digit:
          modalCurrentData.adiq_proposal_data.bankAccounts[0].accountDigit,
        rg: modalCurrentData.adiq_proposal_data.rg
          ? modalCurrentData.adiq_proposal_data.rg
          : 'Não informado',
        political_exposed:
          typeof modalCurrentData.adiq_proposal_data.political_exposed !==
          'undefined'
            ? modalCurrentData.adiq_proposal_data.political_exposed
            : false,
        political_exposed_position:
          modalCurrentData.adiq_proposal_data?.political_exposed_position,
        political_exposed_government_agency:
          modalCurrentData.adiq_proposal_data
            ?.political_exposed_government_agency,
        cnae: String(modalCurrentData.fiserv_proposal_data.cnae),
        automatic_anticipation:
          modalCurrentData?.adiq_proposal_data?.prepaymentIndicator || false
      }

      if (modalCurrentData?.new) {
        dados.automatic_anticipation =
          modalCurrentData?.adiq_proposal_data?.automatic_anticipation || false
        dados.formPlano =
          modalCurrentData?.adiq_proposal_data?.physicalPerson?.formPlano || ''
        dados.is_voucher =
          modalCurrentData?.adiq_proposal_data?.is_voucher || false

        const vouchers = modalCurrentData?.adiq_proposal_data?.vouchers || []
        vouchers.forEach((voucher, index) => {
          dados[`voucher_${index}_bandeira`] = voucher?.bandeira || ''
          dados[`voucher_${index}_cod_ec`] = voucher?.codeEc || ''
        })
      }

      form.setFieldsValue(dados)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalCurrentData])

  useEffect(() => {
    handleCheckAuth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handlegetProposals()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // useEffect(()=>{
  // // setProposals(proposals)
  // }, [proposals])

  // useEffect(()=>{
  //     console.log(cancellationChargeBack)
  // })

  async function onClearSelectFilter() {
    setNameFilter()
    setStatusFilter(null)
    setInitialDate()
    setFinalDate()
    setSelectedDate()
    setCompanyFilter()
    setRequestFilter(null)
    await handlegetProposals({
      page: 1
    })
  }

  return (
    <Spin indicator={antIcon} spinning={spin}>
      {contextHolder}
      <AppLayout
        openKeys={['risk-ec', 'risk-management']}
        currentOption={'risk-management'}
      >
        <Select
          allowClear
          placeholder="Filtrar por status"
          style={{ width: 400, marginTop: '2.5em', marginLeft: '1.3em' }}
          onChange={e => setStatusFilter(e)}
          value={statusFilter}
          onClear={onClearSelectFilter}
          options={accreditationStatusData.map(status => ({
            label: status.label,
            value: status.status
          }))}
        />
        <Select
          allowClear
          placeholder="Filtrar por canal"
          style={{ width: 400, marginTop: '2.5em', marginLeft: '1.3em' }}
          onChange={e => setCompanyFilter(e)}
          onClear={onClearSelectFilter}
          value={companyFilter}
          options={
            uniqueKey &&
            uniqueKey.map(company => ({
              label: company.companyName,
              value: company.idCompany
            }))
          }
        />
        <Input
          placeholder="Filtrar por razão social ou nome"
          style={{ width: 400, marginTop: '2.5em', marginLeft: '1.3em' }}
          onChange={e => setNameFilter(e.target.value)}
          value={nameFilter}
          onClear={onClearSelectFilter}
        />
        <Select
          allowClear
          placeholder="Filtrar por data de pedido"
          style={{ width: 400, marginTop: '2.5em', marginLeft: '1.3em' }}
          onChange={e => setSelectedDate(e)}
          onClear={onClearSelectFilter}
          value={selectedDate}
          options={DynamicSelectedDate.map(element => ({
            label: element.label,
            value: element.value
          }))}
        />

        {hideenInputs && (
          <>
            <DatePicker
              placeholder="Data inicial"
              format={'DD/MM/YYYY'}
              style={{ width: 270, marginTop: '2.5em', marginLeft: '1.4em' }}
              onChange={e => setInitialDate(e)}
              value={initialDate}
              onClear={onClearSelectFilter}
            />

            <DatePicker
              placeholder="Data final"
              format={'DD/MM/YYYY'}
              style={{ width: 270, marginTop: '2.5em', marginLeft: '0.5em' }}
              onChange={e => setFinalDate(e)}
              value={finalDate}
              onClear={onClearSelectFilter}
            />
          </>
        )}

        <Button
          onClick={handleFilter}
          style={{ width: 150, marginLeft: '2em' }}
          type="primary"
        >
          Filtrar
        </Button>
        <Button
          onClick={onClearSelectFilter}
          style={{ width: 150, marginLeft: '1em', marginTop: '2.5em' }}
          type="primary"
        >
          Limpar
        </Button>
        <Pagination
          onChange={handlePagination}
          style={{ marginTop: '2.5em' }}
          pageSize={10}
          showSizeChanger={false}
          defaultCurrent={1}
          total={pagination.count}
          current={pagination.currentPage}
        />
        <AccreditationHistoricComponents.ItemsWrapper>
          {proposals.length > 0 ? (
            proposals.map((element, index) => {
              const findStatus = accreditationStatusData.find(
                status => status.status === element.proposal_status
              )

              let risk_level = element.risk_level ? element.risk_level : false
              if (risk_level) {
                risk_level = riskLevels.find(
                  risk => risk.value === element.risk_level
                )
              }

              if (findStatus) {
                return (
                  <AccreditationHistoricComponents.Item key={index}>
                    <AccreditationHistoricComponents.ItemInfo>
                      <AccreditationHistoricComponents.ItemInfoLabel>
                        {element.companyName} -{' '}
                      </AccreditationHistoricComponents.ItemInfoLabel>
                      <div>{element.id}</div>
                    </AccreditationHistoricComponents.ItemInfo>
                    <Spin indicator={antIcon} spinning={spin}>
                      <AccreditationHistoricComponents.ItemInfoWrapper>
                        <AccreditationHistoricComponents.ItemInfo>
                          <AccreditationHistoricComponents.ItemInfoLabel>
                            Status atual do credenciamento:{' '}
                          </AccreditationHistoricComponents.ItemInfoLabel>
                          <div className={findStatus.class}>
                            {' '}
                            {findStatus.label}{' '}
                          </div>
                        </AccreditationHistoricComponents.ItemInfo>

                        <AccreditationHistoricComponents.ItemInfo>
                          <AccreditationHistoricComponents.ItemInfoLabel>
                            {element.cnpj ? 'Razão Social:' : 'Nome:'}{' '}
                          </AccreditationHistoricComponents.ItemInfoLabel>
                          <div>
                            {element.cnpj
                              ? element.social_name
                              : element.full_name}
                          </div>
                        </AccreditationHistoricComponents.ItemInfo>

                        <AccreditationHistoricComponents.ItemInfo>
                          <AccreditationHistoricComponents.ItemInfoLabel>
                            MCC:{' '}
                          </AccreditationHistoricComponents.ItemInfoLabel>
                          <div>{element.fiserv_proposal_data.mcc}</div>
                        </AccreditationHistoricComponents.ItemInfo>

                        <AccreditationHistoricComponents.ItemInfo>
                          <AccreditationHistoricComponents.ItemInfoLabel>
                            Nível de risco do CNAE:{' '}
                          </AccreditationHistoricComponents.ItemInfoLabel>
                          <div>
                            {element.risk_level && risk_level?.component ? (
                              <div>
                                <span style={{ marginRight: '10px' }}>
                                  {element.fiserv_proposal_data.cnae}
                                </span>
                                <span>{risk_level.component}</span>
                              </div>
                            ) : (
                              <>
                                <span>{element.fiserv_proposal_data.cnae}</span>
                                <Button
                                  style={{ marginLeft: '1em' }}
                                  type="primary"
                                  onClick={() => showRiskLevelModal(element)}
                                >
                                  Classificar
                                </Button>
                              </>
                            )}{' '}
                          </div>
                        </AccreditationHistoricComponents.ItemInfo>

                        <AccreditationHistoricComponents.ItemInfo>
                          <AccreditationHistoricComponents.ItemInfoLabel>
                            Data do pedido:{' '}
                          </AccreditationHistoricComponents.ItemInfoLabel>
                          <div>
                            {moment(String(element.dtCreated))
                              .subtract('3', 'hour')
                              .format('DD/MM/YYYY HH:mm:ss')}
                          </div>
                        </AccreditationHistoricComponents.ItemInfo>

                        <AccreditationHistoricComponents.ItemInfo>
                          <AccreditationHistoricComponents.ItemInfoLabel>
                            Presente na blacklist:{' '}
                          </AccreditationHistoricComponents.ItemInfoLabel>
                          <Tag
                            color={
                              element.is_in_black_list ? 'error' : 'success'
                            }
                          >
                            {element.is_in_black_list ? 'SIM' : 'NÃO'}
                          </Tag>
                        </AccreditationHistoricComponents.ItemInfo>

                        <AccreditationHistoricComponents.ItemInfo>
                          <AccreditationHistoricComponents.ItemInfoLabel>
                            Dados credenciamento:
                          </AccreditationHistoricComponents.ItemInfoLabel>
                          <Button onClick={() => showModal(element)}>
                            Visualizar dados
                          </Button>
                        </AccreditationHistoricComponents.ItemInfo>

                        <Divider />

                        <div style={{ marginTop: '1em', marginBottom: '2em' }}>
                          {' '}
                          <strong>Transações: </strong>
                        </div>

                        {!element.cancellation_chargeback_days &&
                          !element.cancellation_chargeback_percent && (
                            <>
                              <Form
                                onFinish={values =>
                                  handleSaveCancellationChargeBack(
                                    values,
                                    element.id,
                                    index
                                  )
                                }
                              >
                                <Form.Item
                                  label="A cada (em dias):"
                                  name={`cancellation_chargeback_days_${index}`}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Informe quantidade em DIAS'
                                    }
                                  ]}
                                >
                                  <Input style={{ width: 150 }} />
                                </Form.Item>

                                <Form.Item
                                  label="Permitir quantidade de cancelamentos e estornos (em porcentagem)"
                                  name={`cancellation_chargeback_percent_${index}`}
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        'Informe a quantidade de cancelamentos e estornos'
                                    }
                                  ]}
                                >
                                  <Input style={{ width: 150 }} />
                                </Form.Item>

                                <div style={{ marginBottom: '2em' }}>
                                  <strong>
                                    {' '}
                                    Exemplo: a cada 10 dias permitir 10% de
                                    cancelamentos e estornos
                                  </strong>
                                </div>

                                <AccreditationHistoricComponents.ItemInfo>
                                  <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                      SALVAR
                                    </Button>
                                  </Form.Item>
                                </AccreditationHistoricComponents.ItemInfo>
                              </Form>
                            </>
                          )}
                        {element.cancellation_chargeback_days &&
                          element.cancellation_chargeback_percent && (
                            <>
                              <AccreditationHistoricComponents.ItemInfo>
                                <AccreditationHistoricComponents.ItemInfoLabel>
                                  A cada (em dias):{' '}
                                </AccreditationHistoricComponents.ItemInfoLabel>
                                <div>
                                  {element.cancellation_chargeback_days}
                                </div>
                              </AccreditationHistoricComponents.ItemInfo>

                              <AccreditationHistoricComponents.ItemInfo>
                                <AccreditationHistoricComponents.ItemInfoLabel>
                                  Permitir quantidade de cancelamentos e
                                  estornos (em porcentagem):{' '}
                                </AccreditationHistoricComponents.ItemInfoLabel>
                                <div>
                                  {element.cancellation_chargeback_percent} %{' '}
                                </div>
                              </AccreditationHistoricComponents.ItemInfo>
                              <AccreditationHistoricComponents.ItemInfo>
                                <Button
                                  type="primary"
                                  onClick={() =>
                                    editCancellationChargeBack(element.id)
                                  }
                                >
                                  Editar
                                </Button>
                              </AccreditationHistoricComponents.ItemInfo>
                            </>
                          )}

                        <Divider />
                        <AccreditationHistoricComponents.ItemInfo>
                          <AccreditationHistoricComponents.ItemInfoLabel>
                            Atualizar Credenciamento:{' '}
                          </AccreditationHistoricComponents.ItemInfoLabel>
                        </AccreditationHistoricComponents.ItemInfo>
                        <Form
                          onFinish={values =>
                            handleUpdateProposalStatus(values, element.id)
                          }
                        >
                          <AccreditationHistoricComponents.ItemInfo>
                            <Form.Item
                              initialValue={element.proposal_status}
                              label="Status"
                              name="proposal_status"
                            >
                              <Select
                                style={{ width: 250, marginRight: '1em' }}
                                options={[
                                  {
                                    label: 'Enviar para adquirentes',
                                    value: 'processing'
                                  },
                                  {
                                    label: 'Aguardando Análise de risco',
                                    value: 'waiting-risk-team'
                                  },
                                  {
                                    label: 'Reprovado',
                                    value: 'refused-by-risk-team'
                                  }
                                ]}
                              />
                            </Form.Item>
                          </AccreditationHistoricComponents.ItemInfo>

                          <AccreditationHistoricComponents.ItemInfo>
                            <Form.Item>
                              <Button type="primary" htmlType="submit">
                                ATUALIZAR
                              </Button>
                            </Form.Item>
                          </AccreditationHistoricComponents.ItemInfo>
                        </Form>
                      </AccreditationHistoricComponents.ItemInfoWrapper>
                    </Spin>
                  </AccreditationHistoricComponents.Item>
                )
              } else {
                return <span key={index}></span>
              }
            })
          ) : (
            <p>
              Nenhuma proposta encontrada, revise os filtros ou aperte em
              'Limpar' e tente novamente
            </p>
          )}
        </AccreditationHistoricComponents.ItemsWrapper>
      </AppLayout>
      <Modal
        cancelText="Cancelar"
        forceRender
        width="60%"
        title={'Dados do credenciamento - ' + modalCurrentData.id}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          style={{
            padding: 8
          }}
          useRef
          form={form}
          name="step_one"
          layout="horizontal"
          onValuesChange={handleInputsMask}
        >
          <p className="title-page-info"> Dados cadastrais</p>
          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="CPF/CNPJ"
                name="document"
                rules={[{ required: true, message: 'Informe o CPF/CNPJ' }]}
              >
                <Input disabled readOnly maxLength={18} />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
            {/* <AccreditationHistoricComponents.AccreditationItem >
                                <Form.Item
                                    label="RG"
                                    name="rg"
                                    >
                                        <Input disabled readOnly maxLength={9} />
                                </Form.Item>
                            </AccreditationHistoricComponents.AccreditationItem>
                          */}
          </AccreditationHistoricComponents.AccreditantionWrapper>

          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Nome / Razão Social"
                name="name"
                rules={[
                  { required: true, message: 'Informe o Nome / Razão Social.' }
                ]}
              >
                <Input />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
          </AccreditationHistoricComponents.AccreditantionWrapper>

          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              {modalCurrentData.cnpj &&
                modalCurrentData.adiq_proposal_data.legalPerson.fantasyName && (
                  <AccreditationHistoricComponents.AccreditationItem>
                    <Form.Item
                      label="Nome Fantasia"
                      name="fantasyName"
                      rules={[
                        {
                          required: true,
                          message: ' Informe o Nome Fantasia.'
                        }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </AccreditationHistoricComponents.AccreditationItem>
                )}
            </AccreditationHistoricComponents.AccreditationItem>

            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Data da fundação / Data Nascimento"
                name="foundatingDate_birthDate"
                rules={[
                  {
                    required: true,
                    message: 'Informe a Data da fundação / Data de nascimento.'
                  }
                ]}
              >
                <DatePicker
                  placeholder="Selecione uma data"
                  format="DD/MM/YYYY"
                />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
          </AccreditationHistoricComponents.AccreditantionWrapper>

          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="E-mail"
                name="email"
                rules={[{ required: true, message: 'Informe o email' }]}
              >
                <Input />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="CNAE"
                name="cnae"
                rules={[{ required: true, message: 'Informe o CNAE' }]}
              >
                <Input />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
          </AccreditationHistoricComponents.AccreditantionWrapper>

          <AccreditationHistoricComponents.AccreditantionWrapper>
            {modalCurrentData.cnpj &&
              modalCurrentData.adiq_proposal_data.legalPerson.fantasyName && (
                <AccreditationHistoricComponents.AccreditationItem>
                  <Form.Item
                    label="Tipo de Constituição"
                    name="formConstitution"
                    rules={[
                      {
                        required: true,
                        message: 'Informe o Tipo de Constituição'
                      }
                    ]}
                  >
                    <Select
                      options={[
                        { value: 1, label: 'Empresário Individual - EI' },
                        {
                          value: 2,
                          label: 'Microempreendedor Individual - MEI'
                        },
                        {
                          value: 3,
                          label:
                            'Empresa Individual de Responsabilidade Limitada - EIRELI'
                        },
                        { value: 4, label: 'Sociedade Empresária' },
                        { value: 5, label: 'Sociedade Simples' },
                        { value: 6, label: 'Sociedade Anônima - SA' },
                        { value: 7, label: 'Sociedade Limitada - LTDA' },
                        { value: 8, label: 'Micro Empresa - ME' },
                        { value: 9, label: 'Empresa Pequeno Porte' },
                        { value: 10, label: 'Empresa Médio Porte' },
                        { value: 11, label: 'Empresa Grande Porte' }
                      ]}
                    />
                  </Form.Item>
                </AccreditationHistoricComponents.AccreditationItem>
              )}
          </AccreditationHistoricComponents.AccreditantionWrapper>

          {modalCurrentData?.adiq_proposal_data?.physicalPerson?.formPlano && (
            <AccreditationHistoricComponents.AccreditantionWrapper>
              <AccreditationHistoricComponents.AccreditationItem
                style={{ width: 300 }}
              >
                <Form.Item
                  label="Plano"
                  name="formPlano"
                  disabled
                  rules={[{ required: true, message: 'Informe o plano' }]}
                >
                  <Select
                    options={rates?.map(rate => ({
                      value: rate.Id,
                      label: rate.Nome
                    }))}
                  />
                </Form.Item>
              </AccreditationHistoricComponents.AccreditationItem>
            </AccreditationHistoricComponents.AccreditantionWrapper>
          )}

          <p className="title-page-info"> Endereço Fiscal</p>
          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Endereço"
                name="address"
                rules={[
                  { required: true, message: ' Informe o Nome Endereço.' }
                ]}
              >
                <Input />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Número"
                name="address_number"
                rules={[
                  { required: true, message: ' Informe o Número do Endereço.' }
                ]}
              >
                <Input />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
          </AccreditationHistoricComponents.AccreditantionWrapper>
          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Bairro"
                name="address_neighborhood"
                rules={[{ required: true, message: ' Informe o Bairro.' }]}
              >
                <Input />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>

            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Cidade"
                name="address_city"
                rules={[{ required: true, message: ' Informe a Cidade.' }]}
              >
                <Input />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
          </AccreditationHistoricComponents.AccreditantionWrapper>

          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Estado"
                name="address_state"
                rules={[{ required: true, message: ' Informe o Estado' }]}
              >
                <Input />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>

            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="CEP"
                name="address_zipcode"
                rules={[{ required: true, message: ' Informe o CEP.' }]}
              >
                <Input />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
          </AccreditationHistoricComponents.AccreditantionWrapper>

          <p className="title-page-info"> Telefones</p>
          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="DDD Telefone fixo"
                name="fixedDdd"
                rules={[
                  { required: true, message: 'Informe o DDD do telefone fixo' }
                ]}
              >
                <Input maxLength={3} />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Número Telefone Fixo"
                name="fixedNumber"
                rules={[
                  { required: true, message: 'Informe o Número Telefone Fixo' }
                ]}
              >
                <Input maxLength={3} />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
          </AccreditationHistoricComponents.AccreditantionWrapper>

          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="DDD Celular"
                name="mobileDdd"
                rules={[
                  { required: true, message: 'Informe o DDD do Celular' }
                ]}
              >
                <Input maxLength={3} />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Número Celular"
                name="mobileNumber"
                rules={[
                  { required: true, message: 'Informe o Número do Celular.' }
                ]}
              >
                <Input maxLength={3} />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
          </AccreditationHistoricComponents.AccreditantionWrapper>

          <p className="title-page-info"> Representante Legal</p>
          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Nome Representante Legal"
                name="delivery_name"
                rules={[
                  {
                    required: true,
                    message: 'Informe o Nome do Representante.'
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="CPF Representante"
                name="delivery_document"
                rules={[
                  {
                    required: true,
                    message: 'Informe o CPF do Representante.'
                  }
                ]}
              >
                <Input maxLength={14} />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
          </AccreditationHistoricComponents.AccreditantionWrapper>

          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Data de Nascimento Representante"
                name="delivery_birthdate"
                rules={[
                  {
                    required: true,
                    message: 'Informe a Data de Nascimento do Representante.'
                  }
                ]}
              >
                <DatePicker
                  placeholder="Selecione uma data"
                  format="DD/MM/YYYY"
                />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
          </AccreditationHistoricComponents.AccreditantionWrapper>

          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Pessoa Politicamente Exposta?"
                name="political_exposed"
                rules={[{ required: true, message: 'Informe uma resposta' }]}
              >
                <Radio.Group>
                  <Radio value={true}>Sim</Radio>
                  <Radio value={false}>Não</Radio>
                </Radio.Group>
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
          </AccreditationHistoricComponents.AccreditantionWrapper>

          {typeof modalCurrentData.adiq_proposal_data?.political_exposed !==
            'undefined' &&
            !modalCurrentData.adiq_proposal_data?.political_exposed && (
              <AccreditationHistoricComponents.AccreditantionWrapper>
                <AccreditationHistoricComponents.AccreditationItem>
                  <Form.Item
                    label="Cargo"
                    name="political_exposed_position"
                    rules={[{ required: true, message: 'Informe o cargo.' }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Orgão do Governo"
                    name="political_exposed_government_agency"
                    rules={[
                      {
                        required: true,
                        message: 'Informe o orgão do governo.'
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </AccreditationHistoricComponents.AccreditationItem>
              </AccreditationHistoricComponents.AccreditantionWrapper>
            )}

          <p className="title-page-info"> Dados Bancários</p>
          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Código do Banco"
                name="bank_data_institution"
                rules={[
                  { required: true, message: 'Informe o código do Banco.' }
                ]}
              >
                <Input />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
          </AccreditationHistoricComponents.AccreditantionWrapper>

          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Tipo de conta"
                name="bank_data_account_type"
                rules={[{ required: true, message: 'Informe o Tipo de conta' }]}
              >
                <Select
                  style={{ width: 400 }}
                  options={[
                    { value: 'CHECKING', label: 'Conta Corrente' },
                    { value: 'GUARANTEED', label: 'Conta Garantida' },
                    { value: 'CHECKING', label: 'Conta Poupança' },
                    { value: 'PAYMENT', label: 'Conta Pagamento' }
                  ]}
                />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
          </AccreditationHistoricComponents.AccreditantionWrapper>

          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Agência"
                name="bank_data_agency"
                rules={[{ required: true, message: 'Informe a agência.' }]}
              >
                <Input />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item label="Dígito da agência" name="agencyDigit">
                <Input />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
          </AccreditationHistoricComponents.AccreditantionWrapper>

          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Conta"
                name="bank_data_account"
                rules={[{ required: true, message: 'Informe a conta.' }]}
              >
                <Input />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Dígito"
                name="bank_data_digit"
                rules={[{ required: true, message: 'Informe o dígito.' }]}
              >
                <Input />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
          </AccreditationHistoricComponents.AccreditantionWrapper>

          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                label="Antecipação automática"
                name="automatic_anticipation"
                rules={[{ required: true, message: 'Informe a conta.' }]}
              >
                <Radio.Group
                // onChange={e => setWantAntecipation(e.target.value)}
                >
                  <Radio value={true}>Habilitar</Radio>
                  <Radio value={false}>Não Habilitar</Radio>
                </Radio.Group>
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
          </AccreditationHistoricComponents.AccreditantionWrapper>

          <AccreditationHistoricComponents.AccreditantionWrapper>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item
                tooltip={{ title: 'As informações de PIX serão atualizadas.' }}
                label="Tipo de PIX"
                name="pix_type"
              >
                <Select
                  style={{ width: 150 }}
                  options={Object.keys(PIX_TYPES).map(key => ({
                    value: key,
                    label: PIX_TYPES[key]
                  }))}
                />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
            <AccreditationHistoricComponents.AccreditationItem>
              <Form.Item label="Chave PIX" name="pix_chave">
                <Input />
              </Form.Item>
            </AccreditationHistoricComponents.AccreditationItem>
          </AccreditationHistoricComponents.AccreditantionWrapper>

          {modalCurrentData?.adiq_proposal_data?.vouchers?.map((_, index) => (
            <AccreditationHistoricComponents.AccreditantionWrapper key={index}>
              <AccreditationHistoricComponents.AccreditationItem>
                <Form.Item
                  label={`Voucher(${index + 1}) - Bandeira`}
                  name={`voucher_${index}_bandeira`}
                >
                  <Input />
                </Form.Item>
              </AccreditationHistoricComponents.AccreditationItem>
              <AccreditationHistoricComponents.AccreditationItem>
                <Form.Item
                  label={`Voucher(${index + 1}) - Cod. do Estabelecimento`}
                  name={`voucher_${index}_cod_ec`}
                >
                  <Input />
                </Form.Item>
              </AccreditationHistoricComponents.AccreditationItem>
            </AccreditationHistoricComponents.AccreditantionWrapper>
          ))}

          {modalCurrentData.adiq_proposal_data?.legalPerson && (
            <>
              <p className="title-page-info"> Sócios</p>
              {modalCurrentData.adiq_proposal_data.legalPerson.companyPartners.map(
                (element, index) => (
                  <AccreditationHistoricComponents.AccreditantionDescriptionWrapper
                    key={index}
                  >
                    <AccreditationHistoricComponents.AccreditationDescription>
                      <div>
                        <strong>CPF/CNPJ:</strong>
                      </div>
                      <div>{maskCpfCnpj(element.cnpjCpf)}</div>
                    </AccreditationHistoricComponents.AccreditationDescription>
                    <AccreditationHistoricComponents.AccreditationDescription>
                      <div>
                        <strong>Nome Sócio:</strong>
                      </div>
                      <div>{element.firstName + ' ' + element.lastName}</div>
                    </AccreditationHistoricComponents.AccreditationDescription>
                    <AccreditationHistoricComponents.AccreditationDescription>
                      <div>
                        <strong>RG do Sócio:</strong>
                      </div>
                      <div>
                        {element?.rg ? maskRg(element.rg) : 'Não informado'}
                      </div>
                    </AccreditationHistoricComponents.AccreditationDescription>
                  </AccreditationHistoricComponents.AccreditantionDescriptionWrapper>
                )
              )}
            </>
          )}
        </Form>
      </Modal>
      <Modal
        cancelText="Cancelar"
        forceRender
        width="60%"
        title={'Classificação Nível de Risco'}
        open={isRiskLevelModalOpen}
        onOk={handleOkRiskLevelModal}
        onCancel={handleCancelRiskLevelModal}
      >
        <Form
          form={riskForm}
          style={{
            padding: '2em',
            width: '80%'
          }}
          useRef
          layout="horizontal"
          onFinish={values => saveRiskLavel(values)}
        >
          <Form.Item label="MCC" name="mcc">
            <Input disabled readOnly />
          </Form.Item>
          <Form.Item label="CNAE" name="cnae">
            <Input disabled readOnly />
          </Form.Item>

          <Form.Item
            label="Nível de risco"
            name="risk_category"
            rules={[{ required: true, message: 'Selecione o nível de risco.' }]}
          >
            <Select
              style={{ width: 250, marginRight: '1em' }}
              options={[
                { label: 'High Risk Merchants', value: 'High Risk Merchants' },
                { label: 'Low Risk Merchants', value: 'Low Risk Merchants' },
                {
                  label: 'Moderate Risk Merchants',
                  value: 'Moderate Risk Merchants'
                },
                {
                  label: 'Prohibited Merchants',
                  value: 'Prohibited Merchants'
                },
                {
                  label: 'Restricted Merchants',
                  value: 'Restricted Merchants'
                }
              ]}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {' '}
              Salvar{' '}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Spin>
  )
}

export default RiskManagement
