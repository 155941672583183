import React, { useCallback, useEffect, useState } from 'react'

import {
  Alert,
  Button,
  Descriptions,
  Form,
  Input,
  Modal,
  Row,
  Spin,
  Table,
  Typography,
  message
} from 'antd'
import AppLayout from '../../../../components/app-layout/app-layout'
import { LoadingOutlined } from '@ant-design/icons'
import {
  cleanCpfCnpj,
  maskCpf,
  maskCpfCnpj,
  maskPhoneNumber
} from '../../../../utils/masks'
import useSwr from 'swr'
import { api } from '../../../../providers/api'
import { blackListTableColumns } from './column'

const formMaskFunctions = {
  cpf: value => maskCpf(value),
  cnpj: value => maskCpfCnpj(value),
  phones: value => maskPhoneNumber(value),
  'phone-1': value => maskPhoneNumber(value),
  'phone-2': value => maskPhoneNumber(value)
}

const RiksRulesBlacklist = () => {
  const { data, error, isLoading, mutate, isValidating } = useSwr(
    '/accreditation/blacklist'
  )
  const [isSubmitting, setSubmitting] = useState(false)
  const [isAddECModalOpen, setAddECModalOpen] = useState(false)
  const [editingECId, setEditingECId] = useState(null)
  const [deletingEC, setDeletingEC] = useState(null)
  const [hasError, setError] = useState(false)
  const [messageApi, contextHolder] = message.useMessage()

  const [form] = Form.useForm()
  const [addECForm] = Form.useForm()
  const [editECForm] = Form.useForm()

  const openEditingModal = record => {
    setEditingECId(record._id)
    editECForm.setFieldsValue({
      ...record,
      'phone-1': record.phones[0],
      'phone-2': record.phones[1]
    })
  }

  const closeAddECModal = useCallback(async () => {
    addECForm.resetFields()
    setAddECModalOpen(false)
    setError(false)
  }, [addECForm])

  const closeEditECModal = useCallback(async () => {
    editECForm.resetFields()
    setEditingECId(null)
    setError(false)
  }, [editECForm])

  const onSubmitAddECForm = useCallback(
    async form => {
      if (isSubmitting) return
      try {
        setSubmitting(true)
        const body = {
          cpf: cleanCpfCnpj(form.cpf || ''),
          cnpj: cleanCpfCnpj(form.cnpj || ''),
          rg: cleanCpfCnpj(form.rg || ''),
          socialName: form.socialName || '',
          fantasyName: form.fantasyName || '',
          phones: [form?.['phone-1'], form?.['phone-2']]
            .filter(it => !!it)
            .map(it => it.replace(/\D+/gi, ''))
        }

        await api.post('/accreditation/blacklist', body)

        mutate().catch()

        closeAddECModal()

        messageApi.open({
          type: 'success',
          content: 'Registro salvo com sucesso'
        })
      } catch (error) {
        setError(true)
      } finally {
        new Audio('/notification.mp3').play()
        setSubmitting(false)
      }
    },
    [closeAddECModal, isSubmitting, messageApi, mutate]
  )

  const onSubmitEditECForm = useCallback(
    async form => {
      if (isSubmitting) return
      try {
        setSubmitting(true)
        const body = {
          _id: editingECId,
          cpf: cleanCpfCnpj(form.cpf || ''),
          cnpj: cleanCpfCnpj(form.cnpj || ''),
          rg: cleanCpfCnpj(form.rg || ''),
          socialName: form.socialName || '',
          fantasyName: form.fantasyName || '',
          phones: [form?.['phone-1'], form?.['phone-2']]
            .filter(it => !!it)
            .map(it => it.replace(/\D+/gi, ''))
        }
        await api.post('/accreditation/blacklist', body)
        mutate().catch()
        closeEditECModal()
        messageApi.open({
          type: 'success',
          content: 'Registro salvo com sucesso'
        })
      } catch (error) {
        setError(true)
      } finally {
        new Audio('/notification.mp3').play()
        setSubmitting(false)
      }
    },
    [isSubmitting, messageApi, mutate, closeEditECModal, editingECId]
  )

  const deleteEC = useCallback(async () => {
    if (isSubmitting) return
    setSubmitting(true)
    try {
      await api.post(`/accreditation/delete-item-blacklist/${deletingEC._id}`)
      mutate().catch()
      setDeletingEC(null)
      messageApi.success({
        content: 'Registro excluído com sucesso'
      })
    } catch (error) {
      messageApi.error({
        content: 'Não foi possível excluir o registro'
      })
    } finally {
      setSubmitting(false)
    }
  }, [deletingEC?._id, isSubmitting, messageApi, mutate])

  const handleInputsMask = inputChanged => {
    const currentFormInitialValues = form.getFieldsValue()

    const inputName = Object.keys(inputChanged)[0]

    if (formMaskFunctions[inputName]) {
      if (inputName === 'phones') {
        const newPhones = []

        currentFormInitialValues.phones.map(element => {
          const cleanPhone = element
            .replace('(', '')
            .replace(')', '')
            .replace('-', '')
            .replace(' ', '')
          return newPhones.push(maskPhoneNumber(cleanPhone))
        })

        currentFormInitialValues.phones = newPhones
      } else {
        currentFormInitialValues[inputName] = formMaskFunctions[inputName](
          inputChanged[inputName]
        )
      }

      form.setFieldsValue(currentFormInitialValues)
    }
  }

  const handleInputsMaskOnModal = inputChanged => {
    const currentFormInitialValues = form.getFieldsValue()

    const inputName = Object.keys(inputChanged)[0]

    if (formMaskFunctions[inputName]) {
      if (inputName === 'phones') {
        const newPhones = []

        currentFormInitialValues.phones.map(element => {
          const cleanPhone = element
            .replace('(', '')
            .replace(')', '')
            .replace('-', '')
            .replace(' ', '')
          return newPhones.push(maskPhoneNumber(cleanPhone))
        })

        currentFormInitialValues.phones = newPhones
      } else {
        currentFormInitialValues[inputName] = formMaskFunctions[inputName](
          inputChanged[inputName]
        )
      }

      addECForm.setFieldsValue(currentFormInitialValues)
    }
  }

  useEffect(() => {
    if (error && !isLoading)
      messageApi.open({
        type: 'error',
        content: 'Algo aconteceu, se persistir contate nosso suporte'
      })
  }, [error, isLoading, messageApi])

  return (
    <Spin
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      spinning={isLoading || isValidating}
    >
      {contextHolder}
      <AppLayout
        openKeys={['risk-ec', 'risk-ec-rules', 'risk-ec-rules-blacklist']}
        currentOption="risk-ec-rules-blacklist"
      >
        <Form form={form} component={false} onValuesChange={handleInputsMask}>
          <Button
            disabled={isLoading}
            onClick={() => setAddECModalOpen(true)}
            type="primary"
            style={{
              marginBottom: 50,
              marginTop: '5em'
            }}
          >
            Adicionar Registro
          </Button>

          <Table
            title={() => 'Blacklist'}
            pagination={{ simple: true, position: ['topRight'] }}
            bordered
            rowClassName={() => 'editable-row'}
            dataSource={data?.data}
            columns={blackListTableColumns({
              isLoading,
              onEditClick: openEditingModal,
              onDeleteClick: record => setDeletingEC(record)
            })}
            rowKey="_id"
            locale={{ emptyText: 'A blacklist não possui dados.' }}
            style={{ paddingBottom: '2em' }}
          />
        </Form>

        <Modal
          open={isAddECModalOpen}
          closable={!isSubmitting}
          centered
          onCancel={closeAddECModal}
          bodyStyle={{ overflowY: 'auto' }}
          footer={
            <>
              {!!hasError && (
                <Alert
                  message="Não foi possível salvar o registro"
                  type="error"
                  banner
                  style={{ marginBottom: 10 }}
                />
              )}
              <Row justify={'end'} style={{ gap: 10 }}>
                <Button disabled={isSubmitting} onClick={closeAddECModal}>
                  Voltar
                </Button>
                <Button
                  loading={isSubmitting}
                  type="primary"
                  onClick={addECForm.submit}
                >
                  Salvar
                </Button>
              </Row>
            </>
          }
        >
          <Form
            form={addECForm}
            onFinish={onSubmitAddECForm}
            onValuesChange={handleInputsMaskOnModal}
          >
            <Typography.Title
              style={{ textAlign: 'center', marginBottom: 14 }}
              level={2}
            >
              Adicionar Blacklist
            </Typography.Title>
            <Typography.Title level={4} />

            <Form.Item label="CPF" name="cpf">
              <Input disabled={isSubmitting} />
            </Form.Item>
            <Form.Item
              label="CNPJ"
              name="cnpj"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Input disabled={isSubmitting} />
            </Form.Item>
            <Form.Item label="RG" name="rg">
              <Input disabled={isSubmitting} />
            </Form.Item>
            <Form.Item
              label="Razão Social"
              name="socialName"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Input disabled={isSubmitting} />
            </Form.Item>
            <Form.Item label="Nome Fantasia" name="fantasyName">
              <Input disabled={isSubmitting} />
            </Form.Item>

            <Form.Item label="Telefone 1" name="phone-1">
              <Input disabled={isSubmitting} />
            </Form.Item>
            <Form.Item label="Telefone 2" name="phone-2">
              <Input disabled={isSubmitting} />
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          open={!!editingECId}
          closable={!isSubmitting}
          onCancel={closeEditECModal}
          bodyStyle={{ overflowY: 'auto' }}
          footer={
            <>
              {!!hasError && (
                <Alert
                  message="Não foi possível editar o registro"
                  type="error"
                  banner
                  style={{ marginBottom: 10 }}
                />
              )}
              <Row justify={'end'} style={{ gap: 10 }}>
                <Button disabled={isSubmitting} onClick={closeEditECModal}>
                  Voltar
                </Button>
                <Button
                  loading={isSubmitting}
                  type="primary"
                  onClick={editECForm.submit}
                >
                  Salvar
                </Button>
              </Row>
            </>
          }
        >
          <Form
            form={editECForm}
            onFinish={onSubmitEditECForm}
            onValuesChange={handleInputsMaskOnModal}
          >
            <Typography.Title
              style={{ textAlign: 'center', marginBottom: 14 }}
              level={2}
            >
              Editar Blacklist
            </Typography.Title>
            <Typography.Title level={4} />

            <Form.Item label="CPF" name="cpf">
              <Input disabled={isSubmitting} />
            </Form.Item>
            <Form.Item
              label="CNPJ"
              name="cnpj"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Input disabled={isSubmitting} />
            </Form.Item>
            <Form.Item label="RG" name="rg">
              <Input disabled={isSubmitting} />
            </Form.Item>
            <Form.Item
              label="Razão Social"
              name="socialName"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Input disabled={isSubmitting} />
            </Form.Item>
            <Form.Item label="Nome Fantasia" name="fantasyName">
              <Input disabled={isSubmitting} />
            </Form.Item>

            <Form.Item label="Telefone 1" name="phone-1">
              <Input disabled={isSubmitting} />
            </Form.Item>
            <Form.Item label="Telefone 2" name="phone-2">
              <Input disabled={isSubmitting} />
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          open={!!deletingEC}
          closable={!isSubmitting}
          centered
          onCancel={() => setDeletingEC(null)}
          onOk={deleteEC}
          cancelText="Voltar"
          okType="primary"
          okText="Excluir"
          okButtonProps={{ danger: true, loading: isSubmitting }}
        >
          <div>
            <Typography.Title
              style={{ textAlign: 'center', marginBottom: 14 }}
              level={2}
            >
              Certeza que deseja excluir?
            </Typography.Title>
            <Descriptions
              title={deletingEC?.fantasyName || deletingEC?.socialName}
              bordered
              colon
              column={1}
            >
              <Descriptions.Item label="CPF">
                {deletingEC?.cpf || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="CNPJ">
                {deletingEC?.cnpj || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="R">
                {deletingEC?.rg || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Razão Social">
                {deletingEC?.socialName || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Nome Fantasia">
                {deletingEC?.fantasyName || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Telefone 1">
                {deletingEC?.phones?.[0] || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Telefone 2">
                {deletingEC?.phones?.[1] || '-'}
              </Descriptions.Item>
            </Descriptions>
          </div>
        </Modal>
      </AppLayout>
    </Spin>
  )
}

export default RiksRulesBlacklist
