import styled, { css } from 'styled-components'

export const ContainerRiskTransaction = styled.div``

export const Container = styled.div`
  height: 90vh;
`

export const HeaderRiskTransaction = styled.header`
  display: flex;
  gap: 20px;
  overflow-x: auto;
  margin-bottom: 36px;
`

export const ItemRiskTransaction = styled.div`
  padding: 20px;
  background-color: #ddd;
  color: black;
  border-radius: 10px;
  cursor: pointer;

  min-width: max-content;
  height: auto;

  display: flex;
  text-align: center;
  align-items: center;

  border: 1px solid transparent;

  &:hover {
    background-color: #ddd;
    border: 1px solid black;
  }

  ${props =>
    !props.isActive &&
    css`
      background-color: #eee;

      &:hover {
        border: 1px solid transparent;
      }
    `}

  ${props =>
    props.isActive &&
    css`
      border: 1px solid black;
    `}
`
