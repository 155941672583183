import { api, getHeaders } from './index'

export const getAntecipation = async payload => {
  const { anualBilling, cnae, campaignId } = payload

  try {
    const response = await api.get('/fiserv/antecipation', {
      params: {
        campaignId,
        anualBilling,
        cnae
      },
      headers: getHeaders()
    })
    return response.data
  } catch (err) {
    throw err
  }
}
