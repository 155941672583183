import { api, getHeaders } from './index'

export const getProposals = async params => {
  try {
    const response = await api.post(
      `/accreditation/get-proposals`,
      { ...params },
      {
        headers: { ...getHeaders() }
      }
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const updateProposals = async params => {
  try {
    const response = await api.post(
      `/accreditation/update-proposal`,
      {
        ...params
      },
      {
        headers: getHeaders()
      }
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const getCompany = async params => {
  try {
    const response = await api.post(
      `/accreditation/get-company`,
      {
        ...params
      },
      {
        headers: getHeaders()
      }
    )

    return response.data
  } catch (err) {
    throw err
  }
}
export const listMatriz = async () => {
  try {
    const response = await api.post(
      `/accreditation/matrizes`,
      {},
      {
        headers: getHeaders()
      }
    )

    return response.data
  } catch (err) {
    throw err
  }
}

export const updateCompany = async (companyId, data) => {
  try {
    const response = await api.post(
      `/accreditation/companies/${companyId}`,
      {
        ...data
      },
      {
        headers: getHeaders()
      }
    )

    return response.data
  } catch (err) {
    throw err
  }
}

export const sendProposalInfoRequested = async params => {
  try {
    await api.post(
      `/send-proposal-info-requested`,
      {
        ...params
      },
      {
        headers: getHeaders()
      }
    )

    await updateProposals({
      id: params.id,
      proposal_status: 'processing'
    })
  } catch (err) {
    throw err
  }
}

export const getBlacklist = async () => {
  try {
    const response = await api.get(`/accreditation/blacklist`, {
      headers: getHeaders()
    })

    return response.data
  } catch (err) {
    throw err
  }
}

export const upsertBlacklistItem = async params => {
  await api.post(
    `/accreditation/blacklist`,
    { ...params },
    {
      headers: getHeaders()
    }
  )
}

export const deleteBlacklistItem = async id => {
  await api.post(
    `/accreditation/delete-item-blacklist/${id}`,
    {},
    {
      headers: getHeaders()
    }
  )
}

export const saveMccCnaeRisk = async ({ cnae, ...payload }) => {
  await api.put(
    `/accreditation/cnaes/${cnae}`,
    {
      mcc: payload.mcc,
      riskCategory: payload.risk_category
    },
    {
      headers: getHeaders()
    }
  )
}

export const insertLojaSub = async idCredenciamento => {
  await api.post(
    `/accreditation/create-commercial-establishment/${idCredenciamento}`,
    {},
    { headers: getHeaders() }
  )
}

export const validateCnpj = async params => {
  try {
    const response = await api.post(
      `/accreditation/validate-establishment`,
      { ...params },
      {
        headers: { ...getHeaders() }
      }
    )

    return response.data
  } catch (err) {
    throw err
  }
}
