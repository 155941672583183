import styled from 'styled-components'

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  & + & {
    padding-left: 20px;
  }
`

export const ContainerFilter = styled.div`
  display: flex;
`
