module.exports = [
  {
    type: 'pix',
    amount: 0
  },
  {
    type: 'credit-card',
    brands: {
      mastercard: {
        'a-vista': 0,
        '2-6': 0,
        '6-12': 0
      },
      visa: {
        'a-vista': 0,
        '2-6': 0,
        '6-12': 0
      },
      hipercard: {
        'a-vista': 0,
        '2-6': 0,
        '6-12': 0
      },
      elo: {
        'a-vista': 0,
        '2-6': 0,
        '6-12': 0
      }
    },
    amount: 0
  },
  {
    type: 'debit-card',
    brands: {
      mastercard: {
        'a-vista': 0
      },
      visa: {
        'a-vista': 0
      },
      hipercard: {
        'a-vista': 0
      },
      elo: {
        'a-vista': 0
      }
    },
    amount: 0
  },
  {
    type: 'boleto',
    amount: 0
  },
  {
    type: 'ted',
    amount: 0
  }
]
