import React, { useEffect, useState } from 'react'
import { cleanCpfCnpj, maskCpfCnpj, maskPhoneNumber } from '../../utils/masks'
import { Button, DatePicker, Form, Input, Radio } from 'antd'
import { PersonalInfo } from '../01-personal-info/index'
import PartnerData from '../09-partner-data/index'

import dayjs from 'dayjs'
import AppLayout from '../../components/app-layout/app-layout'
import $ from 'jquery'

const DeliveryContact = props => {
  const { previousStepData } = props

  const [formData, setFormData] = useState({})
  const [nextSteps, setNextStep] = useState(false)
  const [prevStep, setPrevStep] = useState(false)
  const [form] = Form.useForm()
  const [formInitialValues, setFormInitialValues] = useState({
    delivery_document: '',
    delivery_email: '',
    delivery_name: '',
    delivery_birthdate: '',
    delivery_telephone: '',
    delivery_cellphone: '',
    political_exposed: null
  })

  const formMaskFunctions = {
    delivery_document: value => maskCpfCnpj(value),
    delivery_telephone: value => maskPhoneNumber(value),
    delivery_cellphone: value => maskPhoneNumber(value)
  }

  const handleInputsMask = inputChanged => {
    const currentFormInitialValues = form.getFieldsValue()

    const inputName = Object.keys(inputChanged)[0]

    if (formMaskFunctions[inputName]) {
      currentFormInitialValues[inputName] = formMaskFunctions[inputName](
        inputChanged[inputName]
      )
      form.setFieldsValue(currentFormInitialValues)
    }

    if (inputName === 'political_exposed') {
      setFormInitialValues(currentFormInitialValues)
    }
  }
  const handleOnFinish = data => {
    const splitedName = data.delivery_name.split(' ')
    const splitedCellphone = data.delivery_cellphone.split(' ')
    const splitedFixphone = data.delivery_telephone.split(' ')

    const payload = {
      ...previousStepData,
      contacts: [
        {
          birthDate: dayjs(data.delivery_birthdate).format('YYYY-MM-DD'),
          cpf: cleanCpfCnpj(data.delivery_document),
          email: data.delivery_email,
          firstName: splitedName[0],
          lastName: splitedName[splitedName.length - 1],
          type: 'PRIMARY',
          fixedDdd: `0${splitedFixphone[0].replace('(', '').replace(')', '')}`,
          fixedNumber: splitedFixphone[1].replace('-', ''),
          mobileDdd: `0${splitedCellphone[0].replace('(', '').replace(')', '')}`,
          mobileNumber: splitedCellphone[1].replace('-', '')
        }
      ],
      political_exposed: data.political_exposed,
      political_exposed_position: data.political_exposed_position,
      political_exposed_government_agency:
        data.political_exposed_government_agency
    }
    setFormData(payload)
    setNextStep(true)
  }

  useEffect(() => {
    $('.ant-picker-input')
      .find('input')
      .attr('maxlength', '10')
      .mask('99/99/9999')
  })

  return (
    <>
      {!nextSteps && !prevStep && (
        <AppLayout>
          <p className="title-page-info">
            {' '}
            Indique os dados da pessoa de contato:
          </p>
          <div className="form-container">
            <Form
              useRef
              form={form}
              name="basic"
              style={{ width: 800 }}
              initialValues={formInitialValues}
              onFinish={handleOnFinish}
              onValuesChange={handleInputsMask}
            >
              <Form.Item
                label="CPF"
                name="delivery_document"
                rules={[{ required: true, message: 'Informe o CPF' }]}
              >
                <Input maxLength={14} on />
              </Form.Item>

              <Form.Item
                label="Nome"
                name="delivery_name"
                rules={[{ required: true, message: 'Informe o Nome.' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Data de nascimento"
                name="delivery_birthdate"
                rules={[
                  { required: true, message: 'Informe a Data de nascimento.' }
                ]}
              >
                <DatePicker
                  changeOnBlur
                  style={{ width: '100%' }}
                  placeholder="Selecione uma data"
                  format="DD/MM/YYYY"
                  onBlur={date => {
                    const currentFormInitialValues = form.getFieldsValue()
                    currentFormInitialValues.delivery_birthdate = dayjs(
                      date.target.value,
                      'DD/MM/YYYY'
                    )
                    if (!date.target.value || date.target.value === '') {
                    } else {
                      form.setFieldsValue(currentFormInitialValues)
                    }
                  }}
                />
              </Form.Item>

              <Form.Item
                label="E-mail"
                name="delivery_email"
                rules={[{ required: true, message: 'Informe o E-mail.' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Telefone"
                name="delivery_telephone"
                rules={[{ required: true, message: 'Informe o Telefone.' }]}
              >
                <Input maxLength={15} />
              </Form.Item>

              <Form.Item
                label="Celular"
                name="delivery_cellphone"
                rules={[{ required: true, message: 'Informe o Celular.' }]}
              >
                <Input maxLength={15} />
              </Form.Item>

              <Form.Item
                label="Pessoa Politicamente Exposta?"
                name="political_exposed"
                rules={[{ required: true, message: 'Informe uma resposta' }]}
              >
                <Radio.Group>
                  <Radio value={true}>Sim</Radio>
                  <Radio value={false}>Não</Radio>
                </Radio.Group>
              </Form.Item>

              {formInitialValues.political_exposed && (
                <>
                  <Form.Item
                    label="Nome"
                    name="political_exposed_name"
                    rules={[{ required: true, message: 'Informe o nome.' }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Cargo"
                    name="political_exposed_position"
                    rules={[{ required: true, message: 'Informe o cargo.' }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Orgão do Governo"
                    name="political_exposed_government_agency"
                    rules={[
                      { required: true, message: 'Informe o orgão do governo.' }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </>
              )}

              <Form.Item wrapperCol={{ span: 10 }}>
                <Button type="primary" htmlType="submit">
                  Próxima Etapa
                </Button>
              </Form.Item>

              <Form.Item>
                <Button
                  danger
                  onClick={() => {
                    setPrevStep(true)
                    setNextStep(false)
                  }}
                >
                  cancelar
                </Button>
              </Form.Item>
            </Form>
          </div>
        </AppLayout>
      )}

      {nextSteps && <PartnerData previousStepData={formData} />}

      {prevStep && <PersonalInfo formDataPreLoaded={previousStepData} />}
    </>
  )
}

export default DeliveryContact
