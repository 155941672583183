import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import {
  cleanCpfCnpj,
  maskCpfCnpj,
  maskPhoneNumber,
  onlyNumbers
} from '../../../utils/masks'
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select
} from 'antd'

import dayjs from 'dayjs'
import _ from 'lodash'

import {
  RegisterEcContext,
  StepRegisterEC
} from '../../../Context/register-ec-provider'
import { getValuePercent } from '../../../utils/string.util'

export const Step3Responsabel = () => {
  const { data, changeStep } = useContext(RegisterEcContext)
  const [form] = Form.useForm()
  const [isExposePolitical, setExposePolitical] = useState(false)
  const [isPartners, setIsPartners] = useState(false)
  const [partners, setPartners] = useState([])

  const formMaskFunctions = useMemo(() => {
    return {
      delivery_document: value => maskCpfCnpj(value),
      delivery_telephone: value => maskPhoneNumber(value),
      delivery_cellphone: value => maskPhoneNumber(value)
    }
  }, [])

  const handleInputsMask = useCallback(
    inputChanged => {
      const currentFormInitialValues = form.getFieldsValue()

      const inputName = Object.keys(inputChanged)[0]
      const inputValue = inputChanged[inputName]

      if (formMaskFunctions[inputName]) {
        currentFormInitialValues[inputName] =
          formMaskFunctions[inputName](inputValue)
        form.setFieldsValue(currentFormInitialValues)
      }

      if (inputName === 'political_exposed') {
        form.setFieldValue('political_exposed', inputValue)
        setExposePolitical(inputValue)
      }

      if (inputName === 'is_partners') {
        setIsPartners(inputValue)
      }
    },
    [form, formMaskFunctions]
  )

  const handleOnFinish = dataStep => {
    const splitedName = dataStep.delivery_name.split(' ')
    const splitedCellphone = dataStep.delivery_cellphone.split(' ')
    const splitedFixphone = dataStep.delivery_telephone.split(' ')

    const payload = {
      contacts: [
        {
          birthDate: dayjs(dataStep.delivery_birthdate).format('YYYY-MM-DD'),
          cpf: cleanCpfCnpj(dataStep.delivery_document),
          email: dataStep.delivery_email,
          firstName: splitedName[0],
          lastName: splitedName.slice(1).join(' '),
          type: 'PRIMARY',
          fixedDdd: `0${splitedFixphone[0].replace('(', '').replace(')', '')}`,
          fixedNumber: splitedFixphone[1].replace('-', ''),
          mobileDdd: `0${splitedCellphone[0].replace('(', '').replace(')', '')}`,
          mobileNumber: splitedCellphone[1].replace('-', '')
        }
      ],
      political_exposed: dataStep.political_exposed,
      political_exposed_name: dataStep.political_exposed_name,
      political_exposed_position: dataStep.political_exposed_position,
      political_exposed_government_agency:
        dataStep.political_exposed_government_agency,
      is_partners: isPartners
    }

    if (data?.legalPerson) {
      payload.legalPerson = _.merge(data?.legalPerson, {
        companyPartners: (partners || []).map(item => {
          const partner = { ...item }
          const [ddd, phone] = partner.phone.split(' ')
          const splitedName = partner.name.split(' ')
          const cleanedDocument = cleanCpfCnpj(partner.document)
          const type = cleanedDocument.length === 11 ? 'P' : 'L'

          return {
            cnpjCpf: cleanedDocument,
            ddd: `0${onlyNumbers(ddd)}`,
            phone: onlyNumbers(phone),
            birthDate: partner.birth_date
              ? dayjs(partner.birth_date).format('YYYY-MM-DD')
              : '',
            firstName: splitedName[0] || partner.name,
            lastName: splitedName.length > 1 ? splitedName.slice(1) : '',
            function: partner.function,
            type,
            rg: partner.rg,
            nationality: partner.nationality,
            partner_company_participation: partner.companyParticipation || '',
            political_exposed: partner.politicalExposed,
            political_exposed_name: partner.politicalExposedName || '',
            political_exposed_position: partner.politicalExposedPosition || '',
            political_exposed_government_agency:
              partner.politicalExposedGovernmentAgency || ''
          }
        })
      })
    }

    changeStep({ step: StepRegisterEC.Step4Product, data: payload })
  }

  function disableDateIsAfter18Years(currentDate) {
    if (!currentDate) return false
    const today = dayjs()
    return currentDate.isAfter(today.subtract(18, 'years'))
  }

  const handleAddPartners = useCallback(() => {
    setPartners(old => [
      ...(old || []),
      {
        document: '',
        rg: '',
        name: '',
        birth_date: '',
        nationality: '',
        phone: '',
        function: '',
        companyParticipation: '',
        politicalExposed: '',
        politicalExposedName: '',
        politicalExposedPosition: '',
        politicalExposedGovernmentAgency: ''
      }
    ])
  }, [])

  const handleRemovePartners = useCallback(
    index => {
      setPartners(prev => prev.filter((_, indexPrev) => indexPrev !== index))

      form.setFieldValue(`partner_${index}_document`, '')
      form.setFieldValue(`partner_${index}_rg`, '')
      form.setFieldValue(`partner_${index}_name`, '')
      form.setFieldValue(`partner_${index}_birth_date`, '')
      form.setFieldValue(`partner_${index}_nationality`, '')
      form.setFieldValue(`partner_${index}_phone`, '')
      form.setFieldValue(`partner_${index}_function`, '')
      form.setFieldValue(`partner_${index}_company_participation`, '')
      form.setFieldValue(`partner_${index}_political_exposed`, '')
      form.setFieldValue(`partner_${index}_political_exposed_name`, '')
      form.setFieldValue(`partner_${index}_political_exposed_position`, '')
      form.setFieldValue(
        `partner_${index}_political_exposed_government_agency`,
        ''
      )
    },
    [form]
  )

  const handleUpdatePartners = useCallback((index, prop, value) => {
    setPartners(prev => {
      const newPartners = [...prev]
      if (newPartners[index]) {
        newPartners[index] = { ...newPartners[index], [prop]: value }
      }
      return newPartners
    })
  }, [])

  useEffect(() => {
    const contact = (data?.contacts || [])?.[0] || {}

    if (!Object.keys(contact).length) {
      return
    }

    const dados = {
      delivery_document: maskCpfCnpj(contact.cpf),
      delivery_email: contact.email,
      delivery_name: `${contact.firstName} ${contact.lastName}`,
      delivery_birthdate: dayjs(contact.birthDate, 'YYYY-MM-DD'),
      delivery_telephone: maskPhoneNumber(
        `${contact.fixedDdd.substring(1)}${contact.fixedNumber}`
      ),
      delivery_cellphone: maskPhoneNumber(
        `${contact.mobileDdd.substring(1)}${contact.mobileNumber}`
      ),
      is_partners:
        typeof data.is_partners === 'boolean' ? data.is_partners : false,
      political_exposed:
        typeof data.political_exposed === 'boolean'
          ? data.political_exposed
          : false,
      political_exposed_name: data.political_exposed_name || '',
      political_exposed_position: data.political_exposed_position || '',
      political_exposed_government_agency:
        data.political_exposed_government_agency || ''
    }

    if (data.political_exposed !== undefined) {
      setExposePolitical(data.political_exposed)
    }

    setIsPartners(data?.is_partners)

    const companyPartners = (data?.legalPerson?.companyPartners || []).map(
      item => {
        return {
          document: item.cnpjCpf ? maskCpfCnpj(item.cnpjCpf) : '',
          rg: item.rg || '',
          name: (item?.firstName || '') + (item?.lastName || '') || '',
          birth_date: item.birthDate
            ? dayjs(item.birthDate).format('YYYY-MM-DD')
            : '',
          nationality: item.nationality || '',
          phone: maskPhoneNumber(item.ddd.slice(1) + item.phone),
          function: item.function || '',
          companyParticipation: item.partner_company_participation || '',
          politicalExposed:
            typeof item.political_exposed === 'boolean'
              ? item.political_exposed
              : false,
          politicalExposedName: item.political_exposed_name || '',
          politicalExposedPosition: item.political_exposed_position || '',
          politicalExposedGovernmentAgency:
            item.political_exposed_government_agency || ''
        }
      }
    )

    setPartners(companyPartners)

    companyPartners?.forEach((item, index) => {
      dados[`partner_${index}_document`] = item.document
        ? maskCpfCnpj(item.document)
        : ''
      dados[`partner_${index}_rg`] = item.rg || ''
      dados[`partner_${index}_name`] = item.name || ''
      dados[`partner_${index}_birth_date`] = item.birth_date
        ? dayjs(item.birth_date, 'YYYY-MM-DD')
        : ''
      dados[`partner_${index}_nationality`] = item.nationality || ''
      dados[`partner_${index}_phone`] = item.phone || ''
      dados[`partner_${index}_function`] = item.function || ''
      dados[`partner_${index}_company_participation`] =
        item.companyParticipation || ''
      dados[`partner_${index}_political_exposed`] = item.politicalExposed
      dados[`partner_${index}_political_exposed_name`] =
        item.politicalExposedName || ''
      dados[`partner_${index}_political_exposed_position`] =
        item.politicalExposedPosition || ''
      dados[`partner_${index}_political_exposed_government_agency`] =
        item.politicalExposedGovernmentAgency || ''
    })

    form.setFieldsValue(dados)
  }, [form, data])

  return (
    <div className="form-container">
      <Form
        useRef
        form={form}
        name="basic"
        style={{ width: 800 }}
        onFinish={handleOnFinish}
        onValuesChange={handleInputsMask}
      >
        <Form.Item
          label="CPF"
          name="delivery_document"
          rules={[{ required: true, message: 'Informe o CPF' }]}
        >
          <Input maxLength={14} on />
        </Form.Item>

        <Form.Item
          label="Nome"
          name="delivery_name"
          rules={[{ required: true, message: 'Informe o Nome.' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Data de nascimento"
          name="delivery_birthdate"
          rules={[{ required: true, message: 'Informe a Data de nascimento.' }]}
        >
          <DatePicker
            style={{ width: '100%' }}
            placeholder="Selecione uma data"
            format="DD/MM/YYYY"
            disabledDate={disableDateIsAfter18Years}
            onBlur={date => {
              if (date.target.value) {
                form.setFieldValue(
                  'delivery_birthdate',
                  dayjs(date.target.value, 'DD/MM/YYYY')
                )
              }
            }}
          />
        </Form.Item>

        <Form.Item
          label="E-mail"
          name="delivery_email"
          rules={[{ required: true, message: 'Informe o E-mail.' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Telefone"
          name="delivery_telephone"
          rules={[{ required: true, message: 'Informe o Telefone.' }]}
        >
          <Input maxLength={15} />
        </Form.Item>

        <Form.Item
          label="Celular"
          name="delivery_cellphone"
          rules={[{ required: true, message: 'Informe o Celular.' }]}
        >
          <Input maxLength={15} />
        </Form.Item>

        <Form.Item
          label="Pessoa Politicamente Exposta?"
          name="political_exposed"
          rules={[{ required: true, message: 'Informe uma resposta' }]}
        >
          <Radio.Group>
            <Radio value={true}>Sim</Radio>
            <Radio value={false}>Não</Radio>
          </Radio.Group>
        </Form.Item>

        {isExposePolitical && (
          <>
            <Form.Item
              label="Nome"
              name="political_exposed_name"
              rules={[{ required: true, message: 'Informe o nome.' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Cargo"
              name="political_exposed_position"
              rules={[{ required: true, message: 'Informe o cargo.' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Orgão do Governo"
              name="political_exposed_government_agency"
              rules={[
                { required: true, message: 'Informe o orgão do governo.' }
              ]}
            >
              <Input />
            </Form.Item>
          </>
        )}

        <Form.Item
          label="Possui sócios?"
          name="is_partners"
          rules={[{ required: true, message: 'Informe uma resposta' }]}
        >
          <Radio.Group>
            <Radio value={true}>Sim</Radio>
            <Radio value={false}>Não</Radio>
          </Radio.Group>
        </Form.Item>

        {isPartners && (
          <div>
            <Button
              type="primary"
              htmlType="button"
              style={{ marginBottom: 16 }}
              onClick={handleAddPartners}
            >
              Adicionar sócio
            </Button>

            {partners?.map((partner, index) => (
              <div key={index} className="form-container-product">
                <Form.Item
                  label="CPF/CNPJ"
                  name={`partner_${index}_document`}
                  rules={[{ required: true, message: 'Informe o CPF/CNPJ' }]}
                >
                  <Input
                    maxLength={18}
                    onChange={e => {
                      handleUpdatePartners(index, 'document', e.target.value)
                      form.setFieldValue(
                        `partner_${index}_document`,
                        maskCpfCnpj(e.target.value)
                      )
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="RG"
                  name={`partner_${index}_rg`}
                  rules={[{ required: true, message: 'Informe o RG' }]}
                >
                  <Input
                    maxLength={10}
                    onChange={e =>
                      handleUpdatePartners(index, 'rg', e.target.value)
                    }
                  />
                </Form.Item>

                <Form.Item
                  label="Nome"
                  name={`partner_${index}_name`}
                  rules={[{ required: true, message: 'Informe o Nome.' }]}
                >
                  <Input
                    onChange={e =>
                      handleUpdatePartners(index, 'name', e.target.value)
                    }
                  />
                </Form.Item>

                <Form.Item
                  label="Data de nascimento"
                  name={`partner_${index}_birth_date`}
                  rules={[
                    {
                      required: true,
                      message: 'Informe a data de nascimento.'
                    }
                  ]}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    placeholder="Selecione a data"
                    format="DD/MM/YYYY"
                    disabledDate={disableDateIsAfter18Years}
                    onChange={value => {
                      if (value) {
                        handleUpdatePartners(
                          index,
                          'birth_date',
                          dayjs(value, 'DD/MM/YYYY')
                        )
                        form.setFieldValue(
                          `partner_${index}_birth_date`,
                          dayjs(value, 'DD/MM/YYYY')
                        )
                      }
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="Nacionalidade"
                  name={`partner_${index}_nationality`}
                  rules={[
                    { required: true, message: 'Informe a nacionalidade.' }
                  ]}
                >
                  <Input
                    onChange={e =>
                      handleUpdatePartners(index, 'nationality', e.target.value)
                    }
                  />
                </Form.Item>

                <Form.Item
                  label="Celular"
                  name={`partner_${index}_phone`}
                  rules={[{ required: true, message: 'Informe o Celular.' }]}
                >
                  <Input
                    maxLength={15}
                    onChange={e => {
                      handleUpdatePartners(index, 'phone', e.target.value)
                      form.setFieldValue(
                        `partner_${index}_phone`,
                        maskPhoneNumber(e.target.value)
                      )
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Função"
                  name={`partner_${index}_function`}
                  rules={[{ required: true, message: 'Informe a função' }]}
                >
                  <Select
                    options={[
                      { label: 'Dono', value: 'Owner' },
                      { label: 'Sócio', value: 'Partner' },
                      { label: 'Diretor', value: 'Director' },
                      { label: 'Administrador', value: 'Officer' }
                    ]}
                    onChange={value =>
                      handleUpdatePartners(index, 'function', value)
                    }
                  />
                </Form.Item>

                <Form.Item
                  label="participação na empresa em %"
                  name={`partner_${index}_company_participation`}
                  rules={[
                    {
                      required: true,
                      message: 'Informe a porcentagem de participação.'
                    }
                  ]}
                >
                  <InputNumber
                    type="number"
                    prefix="%"
                    placeholder="000"
                    onChange={value => {
                      const valueNew = getValuePercent(value)
                      handleUpdatePartners(
                        index,
                        'companyParticipation',
                        valueNew
                      )
                      form.setFieldValue(
                        `partner_${index}_company_participation`,
                        valueNew
                      )
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="Pessoa Politicamente Exposta?"
                  name={`partner_${index}_political_exposed`}
                  rules={[{ required: true, message: 'Informe uma resposta' }]}
                >
                  <Radio.Group
                    onChange={e =>
                      handleUpdatePartners(
                        index,
                        'politicalExposed',
                        e.target.value
                      )
                    }
                  >
                    <Radio value={true}>Sim</Radio>
                    <Radio value={false}>Não</Radio>
                  </Radio.Group>
                </Form.Item>

                {partner?.politicalExposed && (
                  <>
                    <Form.Item
                      label="Nome"
                      name={`partner_${index}_political_exposed_name`}
                      rules={[{ required: true, message: 'Informe o nome.' }]}
                    >
                      <Input
                        onChange={e =>
                          handleUpdatePartners(
                            index,
                            'politicalExposedName',
                            e.target.value
                          )
                        }
                      />
                    </Form.Item>

                    <Form.Item
                      label="Cargo"
                      name={`partner_${index}_political_exposed_position`}
                      rules={[{ required: true, message: 'Informe o cargo.' }]}
                    >
                      <Input
                        onChange={e =>
                          handleUpdatePartners(
                            index,
                            'politicalExposedPosition',
                            e.target.value
                          )
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      label="Orgão do Governo"
                      name={`partner_${index}_political_exposed_government_agency`}
                      rules={[
                        {
                          required: true,
                          message: 'Informe o orgão do governo.'
                        }
                      ]}
                    >
                      <Input
                        onChange={e =>
                          handleUpdatePartners(
                            index,
                            'politicalExposedGovernmentAgency',
                            e.target.value
                          )
                        }
                      />
                    </Form.Item>
                  </>
                )}

                <Button
                  danger
                  htmlType="button"
                  onClick={() => handleRemovePartners(index)}
                >
                  Remover
                </Button>
              </div>
            ))}
          </div>
        )}

        <Form.Item wrapperCol={{ span: 10 }}>
          <Button type="primary" htmlType="submit">
            Próxima Etapa
          </Button>
        </Form.Item>

        <Form.Item>
          <Button
            danger
            onClick={() => {
              changeStep({ step: StepRegisterEC.Step2Address })
            }}
          >
            Voltar
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
