import React, { useCallback, useEffect, useState } from 'react'
import { Select, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import AppLayout from '../../components/app-layout/app-layout'
import { AddButton, SearchInput, SearchTable } from './components'
import { cnaesTableColumns } from './tableColumns'
import cnaesServices from '../../services/cnaes.services'
import { AddCnaeModal } from './AddCnaeModal'
import ExpandedRowCnae from './expandedRowCnae'

const Cnaes = () => {
  const [searchValue, setSearchValue] = useState('')
  const [filtroInativado, setFiltroInativado] = useState(null)
  const [cnaes, setCnaes] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isAddModalOpen, setAddModalOpen] = useState(false)

  const getCnaes = useCallback(async () => {
    setIsLoading(true)
    const data = await cnaesServices().list()
    setCnaes(data)
    setIsLoading(false)
  }, [])

  useEffect(() => {
    getCnaes()
  }, [getCnaes])

  return (
    <Spin
      spinning={isLoading}
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
    >
      <AddCnaeModal
        isOpen={isAddModalOpen}
        onClose={() => setAddModalOpen(false)}
        refresh={getCnaes}
      />

      <AppLayout
        openKeys={['risk-ec', 'risk-ec-rules', 'cnaes']}
        currentOption="cnaes"
      >
        <h2>CNAE's</h2>
        <AddButton onClick={() => setAddModalOpen(true)} />
        <div>
          <p>Filtros:</p>
          <SearchInput
            placeholder="Procurar..."
            value={searchValue}
            onSearch={value => {
              setSearchValue(value)
            }}
            onChange={e => setSearchValue(e.target.value)}
          />
          <Select
            defaultValue={{
              label: 'Selecione inativado',
              value: null
            }}
            style={{ width: 170 }}
            onChange={setFiltroInativado}
            options={[
              {
                label: 'Selecione inativado',
                value: null
              },
              {
                label: 'Sim',
                value: true
              },
              {
                label: 'Não',
                value: false
              }
            ]}
          />
        </div>
        <SearchTable
          pagination={{ simple: true, position: ['topRight'] }}
          title={() => "CNAE's"}
          bordered
          rowClassName={() => 'editable-row'}
          dataSource={cnaes}
          rowKey="_id"
          columns={cnaesTableColumns({
            searchValue,
            refresh: getCnaes,
            filtroInativado
          })}
          locale={{ emptyText: 'Nenhum cnae encontrado.' }}
          expandable={{
            expandedRowRender: record => <ExpandedRowCnae cnae={record} />
          }}
        />
      </AppLayout>
    </Spin>
  )
}

export default Cnaes
