import { Spin } from 'antd'
import {
  AlteracaoVolumeTransacoesDiarioTransaction,
  AlteracaoVolumeValorDiarioTransaction,
  AltoIndiceTransacoesTransaction,
  BlacklistTransaction,
  IndiceCancelamentoTransaction,
  IndiceChargeBackTransaction,
  IndiceRepeticaoTransacoesMesmoPortadorCartaoTransaction,
  ProdutoLuxoTransaction,
  TicketMedioTransaction,
  TransacoesForaHorarioPadraoTransaction,
  VolumePorModoEntradaTransaction
} from './Risk'
import { ContainerRiskTransaction } from './component'
import { LoadingOutlined } from '@ant-design/icons'

export function RiskTransaction({
  data,
  switchRisk,
  refresh,
  isLoading = false,
  isValidating = false
}) {
  return (
    <Spin
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      spinning={isLoading || isValidating}
    >
      <ContainerRiskTransaction>
        <TicketMedioTransaction
          data={data}
          refresh={refresh}
          isActive={switchRisk === 'ticket-medio'}
        />
        <VolumePorModoEntradaTransaction
          data={data}
          refresh={refresh}
          isActive={switchRisk === 'volume-por-modo-entrada'}
        />
        <AlteracaoVolumeTransacoesDiarioTransaction
          data={data}
          refresh={refresh}
          isActive={switchRisk === 'alteracao-volume-transacoes-diario'}
        />
        <AlteracaoVolumeValorDiarioTransaction
          data={data}
          refresh={refresh}
          isActive={switchRisk === 'alteracao-volume-valor-diario'}
        />
        <AltoIndiceTransacoesTransaction
          data={data}
          refresh={refresh}
          isActive={switchRisk === 'alto-indice-transacoes'}
        />
        <BlacklistTransaction
          data={data}
          refresh={refresh}
          isActive={switchRisk === 'blacklist'}
        />
        <IndiceCancelamentoTransaction
          data={data}
          refresh={refresh}
          isActive={switchRisk === 'indice-cancelamento'}
        />
        <IndiceChargeBackTransaction
          data={data}
          refresh={refresh}
          isActive={switchRisk === 'indice-charge-back'}
        />
        <IndiceRepeticaoTransacoesMesmoPortadorCartaoTransaction
          data={data}
          refresh={refresh}
          isActive={
            switchRisk === 'indice-repeticao-transacoes-mesmo-portador-cartao'
          }
        />
        <ProdutoLuxoTransaction
          data={data}
          refresh={refresh}
          isActive={switchRisk === 'produto-luxo'}
        />
        <TransacoesForaHorarioPadraoTransaction
          data={data}
          refresh={refresh}
          isActive={switchRisk === 'transacoes-fora-horario-padrao'}
        />
      </ContainerRiskTransaction>
    </Spin>
  )
}
