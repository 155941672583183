import moment from 'moment'
import { maskCpfCnpj, maskCurrency } from '../../utils/masks'

function filter(value, search) {
  value = value || ''
  return value.toLowerCase().includes(search.toLowerCase())
}

export const tableColumns = ({ searchValue, searchCNPJValue, selectValue }) => [
  {
    title: 'Data da transação',
    dataIndex: 'date',
    render: (_, record) => {
      return moment(record.date).format('DD/MM/YYYY HH:mm') || '-'
    }
  },
  {
    title: 'CNPJ',
    dataIndex: 'customerId',
    responsive: ['sm'],
    filteredValue: [searchCNPJValue],
    onFilter: (value, record) => {
      if (!searchCNPJValue) return true
      return filter(record.customerId || '', value.replace(/\D/g, ''))
    },
    render: (_, record) => {
      return record.customerId ? maskCpfCnpj(record.customerId) : '-'
    }
  },
  {
    title: 'Loja',
    dataIndex: 'companyName',
    responsive: ['sm'],
    filteredValue: [searchValue],
    onFilter: (value, record) => {
      if (!searchValue) return true
      return filter(record.companyName || '', value)
    },
    render: (_, record) => {
      return record.companyName || '-'
    }
  },
  {
    title: 'Valor',
    dataIndex: 'amount',
    responsive: ['sm'],
    render: (_, record) => {
      return maskCurrency(record.amount / 100)
    }
  },
  {
    title: 'Canal',
    dataIndex: 'channel',
    responsive: ['md'],
    render: (_, record) => {
      return record.channel || '-'
    }
  },
  {
    title: 'Modo de entrada',
    dataIndex: 'entryMode',
    responsive: ['md'],
    render: (_, record) => {
      return record.entryMode || '-'
    }
  },
  {
    title: 'Bandeira',
    dataIndex: 'cardBrand',
    responsive: ['md']
  },
  {
    title: 'Número do cartão',
    dataIndex: 'cardNumber',
    responsive: ['md']
  },
  {
    title: 'Status',
    dataIndex: 'status',
    responsive: ['lg'],
    filteredValue: [selectValue],
    onFilter: (value, record) => {
      if (!selectValue) return true
      return filter(record.status, value)
    }
  },
  {
    title: 'Tipo',
    dataIndex: 'transactionType',
    responsive: ['lg']
  }
]
