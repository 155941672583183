import { Menu } from 'antd'
import styled, { css } from 'styled-components'
import { AiOutlineMenu } from 'react-icons/ai'

export const Container = styled.div`
  min-height: 100%;
  width: 390px;
  transition: width 0.3s ease;
  background-color: white;

  ${props =>
    !props.expanded
      ? css`
          width: 60px;
        `
      : ''};
`

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2em 0;

  > img {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }

  > p {
    font-size: 1rem;
  }

  ${props =>
    !props.expanded
      ? css`
          > img {
            display: none;
          }
          > p {
            display: none;
          }
        `
      : ''};
`

export const PageMenu = styled(Menu)`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;

  ${props =>
    !props.expanded
      ? css`
          display: none;
        `
      : ''};
`

export const ButtonFloat = styled(AiOutlineMenu)`
  width: 24px;
  height: 24px;
  cursor: pointer;

  padding: 0 10px;
`
