import React, { useCallback, useContext } from 'react'

import { Button, Checkbox, Form, Spin } from 'antd'
import { sendProposal } from '../../../providers/api/sendProposal'
import {
  RegisterEcContext,
  StepRegisterEC
} from '../../../Context/register-ec-provider'
import _ from 'lodash'

export const Step6Finish = ({ messageApi }) => {
  const { data, changeStep, setLoading, resetData } =
    useContext(RegisterEcContext)
  const [form] = Form.useForm()

  const handleOnFinish = useCallback(
    async dataStep => {
      setLoading(true)

      const payload = {
        optInTermsAcceptance: dataStep.term_agree,
        delivery_extract_edi_agree: dataStep.delivery_extract_edi_agree
      }

      const dados = _.merge(data, payload)

      try {
        await sendProposal(dados)
        changeStep({
          step: StepRegisterEC.Step6Finish,
          data: payload,
          isFinished: true
        })
        messageApi.open({
          type: 'success',
          content:
            'Proposta enviada! Será analisada pela nossa equipe e entraremos em contato após a análise!',
          duration: 10
        })
        resetData()
      } catch (err) {
        messageApi.open({
          type: 'error',
          content:
            'Erro ao enviar a proposta. Por favor, tente novamente ou entre em contato com o suporte!'
        })
      } finally {
        setLoading(false)
      }
    },
    [changeStep, messageApi, setLoading, resetData, data]
  )

  const handleInputChange = async inputChanged => {
    const inputName = Object.keys(inputChanged)[0]
    const inputValue = inputChanged[inputName]

    form.setFieldValue(inputName, inputValue)
  }

  return (
    <div className="form-container" style={{ width: 800, marginTop: '2.5em' }}>
      <div
        style={{
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: '14pt'
        }}
      >
        Declaração de Aceitação aos Termos e Condições Para Uso de Informações e
        Dados Pessoais
      </div>
      <div
        style={{
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: '14pt'
        }}
      >
        Eu declaro que:
      </div>
      <div>
        <p>
          <strong>i.</strong> As informações preenchidas neste formulário foram
          devidamente conferidas por mim, estão corretas e são verdadeiras;
        </p>
        <p>
          <strong>ii.</strong> Obtive o consentimento expresso e inequívoco do
          cliente para a coleta, processamento, utilização e eventual
          compartilhamento e/ou transferência, inclusive com terceiros, das
          informações e Dados Pessoais ora coletados para as finalidades e
          propósitos descritos no contrato de credenciamento, incluindo
          informações financeiras e dados pessoais de sócios e representantes
          legais, conforme abaixo:
        </p>
        <p>
          <strong>a)</strong> As informações e Dados Pessoais contidas neste
          formulário poderão ser utilizados, acessados ou transferidos por
          funcionários e departamentos da X-Pay para fins de avaliações
          financeiras, reputacional, consultas a bases e bureaus de crédito e
          dados públicos e para oferecimento de produtos e serviços, de acordo
          com seu perfil.
        </p>
        <p>
          <strong>b)</strong> As informações e Dados Pessoais poderão ser
          acessados ou transferidos de/para trabalhadores não-empregados,
          contratados, fornecedores, auditores e/ou outros terceiros autorizados
          pela X-Pay para cumprir este propósito particular (coletivamente
          “Terceiros Autorizados”).
        </p>
        <p>
          <strong>c)</strong> As informações e Dados Pessoais serão mantidos
          apenas na medida e enquanto forem necessários para cumprir
          corretamente os requisitos relacionados aos propósitos específicos,
          como requerido por Lei.
        </p>
        <p>
          <strong>d)</strong> Dados Pessoais somente serão divulgados entre e/ou
          transferidos para associados da X-Pay, Terceiros Autorizados ou outros
          destinatários devidamente autorizados que tenham necessidade de
          acessar tais dados para cumprir propósitos específicos, execução de
          contrato ou cumprimento de obrigação legal. Para este propósito, e
          conforme legislação em vigor, define-se Dado Pessoal como “toda
          informação relacionada a pessoa identificada ou identificável" Deste
          modo, minha assinatura eletrônica neste formulário corresponde à
          confirmação e autorização para efetivação do credenciamento, a pedido
          do cliente acima descrito, bem como utilização das informações e dados
          conforme acima e contrato de credenciamento disponibilizado ao cliente
          eletronicamente.
        </p>
      </div>
      <Form
        useRef
        form={form}
        name="basic"
        onFinish={handleOnFinish}
        onValuesChange={handleInputChange}
      >
        <Form.Item
          name="term_agree"
          valuePropName="checked"
          rules={[
            {
              required: true,
              message: 'Aceitação dos termos obrigatórios'
            }
          ]}
        >
          <Checkbox style={{ lineHeight: '32px' }}>Concordo</Checkbox>
        </Form.Item>
        <Form.Item name="delivery_extract_edi_agree" valuePropName="checked">
          <Checkbox style={{ lineHeight: '32px' }}>
            O estabelecimento autoriza a X-pay a enviar o Extrato EDI para a
            CONCILIADORA
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Enviar Proposta
          </Button>
        </Form.Item>

        <Form.Item>
          <Button
            danger
            onClick={() => changeStep({ step: StepRegisterEC.Step5Bank })}
          >
            Voltar
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
