export const getExtractDelivery = () => {
  const extract = [
    {
      id: 1,
      conciliatorCnpj: null,
      conciliatorEmail: null,
      conciliatorEmail2: null,
      conciliatorEnterpriseDescription: 'SOFTWARE EXPRESS',
      ediLayout: 'Layout Fiserv'
    }
  ]
  return extract
}
