import React, { useEffect, useState } from 'react'
import { Button, Form, Select } from 'antd'
import { PersonalInfo } from '../01-personal-info/index'
import { getExtractDelivery } from '../../providers/api/delivery-extract-edi'
import Terms from '../14-proposal-terms-acceptance/index'

const EdiAdditionalData = props => {
  const { previousStepData } = props

  const [nextSteps, setNextStep] = useState(false)
  const [prevStep, setPrevStep] = useState(false)
  const [formData, setFormData] = useState({})
  const [form] = Form.useForm()
  const [extractDelivery, setExtractDelivery] = useState([])

  const handleOnFinish = data => {
    const payload = {
      ...previousStepData,
      step_fourteen: data
    }

    setFormData(payload)
    setNextStep(true)
  }

  const handleExtractDelivery = async () => {
    const response = await getExtractDelivery()
    const extractDeliveryResponse = []

    response.forEach(element => {
      extractDeliveryResponse.push({
        value: element.conciliatorEnterpriseDescription,
        label: element.conciliatorEnterpriseDescription
      })
    })

    setExtractDelivery(extractDeliveryResponse)
  }

  useEffect(() => {
    handleExtractDelivery()
  }, [])

  return (
    <>
      {!nextSteps && !prevStep && (
        <>
          <header
            className="portal-header"
            style={{
              marginBottom: '2em',
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center'
            }}
          >
            <img
              style={{ height: '50px' }}
              alt="x-payLogo"
              src={require('../../assets/img/logo.png')}
            ></img>
            <div style={{ fontSize: '16pt' }}>Portal de Credenciamento</div>
          </header>
          <p className="title-page-info">
            {' '}
            Envio do extrato EDI para conciliadora
          </p>
          <div className="form-container">
            <Form
              useRef
              form={form}
              name="basic"
              style={{ width: 800 }}
              onFinish={handleOnFinish}
            >
              <Form.Item
                name="conciliatory_company"
                label="Empresa Conciliadora"
              >
                <Select
                  defaultValue="SOFTWARE EXPRESS"
                  options={extractDelivery}
                />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Próxima Etapa
                </Button>
              </Form.Item>

              <Form.Item>
                <Button danger onClick={() => setPrevStep(true)}>
                  cancelar
                </Button>
              </Form.Item>
            </Form>
          </div>
        </>
      )}
      {nextSteps && <Terms previousStepData={formData} />}

      {prevStep && <PersonalInfo formDataPreLoaded={previousStepData} />}
    </>
  )
}

export default EdiAdditionalData
