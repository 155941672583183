export const RegraRisk = {
  RegistroTickeMedio: 1,
  AlteracaoVolumeMCCEc: 3,
  InconsitenciaTransacao: 4,
  AlteracaoVolumeDiarioMCCEc: 5,
  TransacaoEstabelecimentoForaHorarioSegmento: 6,
  AltoIndiceChargeback: 7,
  AltoIndiceCancelamento: 8,
  AltoIndiceTransacaoForaHorario: 9,
  AltoIndiceRepeticaoTransacaoMesmoECMesmoPortadorMesmoCartao: 10,
  ValorProdutoLuxoAcimaDoEstabelecimento: 11,
  AlteracaoVolumeValorDiarioMCCEc: 12,
  AltoIndiceTransacaoNegada5Minutos: 51,
  AltoIndiceTransacaoNegada30Minutos: 52,
  AltoIndiceTransacaoNegadaDiaAnteriorMaior5PercDiaAtual: 53,
  AltoIndiceTransacaoNegadaDiaAnteriorMaior10PercDiaAtual: 54
}
