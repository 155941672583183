import styled from 'styled-components'

const FormContainer = styled.div`
  background-color: white;
  padding: 5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
`

export { FormContainer }
