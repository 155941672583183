import { Tag, Typography } from 'antd'
import { maskCpfCnpj, maskPhoneNumber, maskRg } from '../../../../utils/masks'

export const blackListTableColumns = ({
  isLoading,
  onEditClick,
  onDeleteClick
}) => [
  // {
  // 	title: 'CPF',
  // 	dataIndex: 'cpf',
  // 	// width: '20em',
  // 	render: (_, record) => {
  // 		if (!record.cpf) {
  // 			return '-'
  // 		} else {
  // 			return maskCpfCnpj(record.cpf)
  // 		}
  // 	},
  // 	onCell: (record) => ({
  // 		record,
  // 		inputType: 'text',
  // 		dataIndex: 'cpf',
  // 		title: 'CPF',
  // 		// editing: isEditing(record),
  // 	}),
  // },
  {
    title: 'CNPJ/CPF',
    dataIndex: 'cnpj',
    // width: '20em',
    render: (_, record) => {
      return `${maskCpfCnpj(record.cnpj) || '-'} ${
        maskCpfCnpj(record.cpf) || '-'
      }`
    },
    onCell: record => ({
      record,
      title: 'CNPJ'
      // editing: isEditing(record),
    })
  },
  {
    title: 'RG',
    dataIndex: 'rg',
    // width: '20em',
    render: (_, record) => {
      if (!record.rg) {
        return '-'
      } else {
        return maskRg(record.rg)
      }
    },
    onCell: record => ({
      record,
      title: 'RG'
      // editing: isEditing(record),
    })
  },
  {
    title: 'Razão Social',
    dataIndex: 'socialName',
    // width: '20em',
    render: (_, record) => {
      return !record.socialName ? '-' : record.socialName
    },
    onCell: record => ({
      record,
      title: 'Razão Social'
      // editing: isEditing(record),
    })
  },
  {
    title: 'Nome Fantasia',
    dataIndex: 'fantasyName',
    // width: '20em',
    render: (_, record) => {
      return !record.fantasyName ? '-' : record.fantasyName
    },
    onCell: record => ({
      record,
      title: 'Nome Fantasia'
      // editing: isEditing(record),
    })
  },
  {
    title: 'Telefones',
    dataIndex: 'phones',
    // width: '20em',
    render: (_, record) => {
      if (record.phones) {
        return (
          <>
            {record.phones.map((element, key) => {
              return (
                <Tag
                  key={key}
                  color="volcano"
                  style={{
                    marginBottom: '0.5em',
                    height: '2.2em',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '13px'
                  }}
                >
                  {maskPhoneNumber(element)}
                </Tag>
              )
            })}
          </>
        )
      } else {
        return '-'
      }
    },
    onCell: record => ({
      record,
      title: 'Telefones'
      // editing: isEditing(record),
    })
  },
  {
    title: 'Ações',
    dataIndex: 'operation',
    render: (_, record) => {
      return (
        <div
          style={{
            display: 'flex',
            width: '10em',
            justifyContent: 'space-around'
          }}
        >
          <Typography.Link
            disabled={isLoading}
            // onClick={() => edit(record)}
            // TODO: Editar
            onClick={() => onEditClick(record)}
          >
            Editar
          </Typography.Link>
          <Typography.Link
            disabled={isLoading}
            // disabled={editingKey !== ''}
            // onClick={() => edit(record)}
            // TODO: Editar
            onClick={() => onDeleteClick(record)}
          >
            Excluir
          </Typography.Link>
        </div>
      )
    }
  }
]
