export const maskCpfCnpj = str => {
  if (!str) return ''
  str = str.replace(/\D/g, '')

  if (str.length <= 11) {
    str = str.replace(/(\d{3})(\d)/, '$1.$2')
    str = str.replace(/(\d{3})(\d)/, '$1.$2')
    str = str.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
  } else {
    str = str.replace(/^(\d{2})(\d)/, '$1.$2')
    str = str.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
    str = str.replace(/\.(\d{3})(\d)/, '.$1/$2')
    str = str.replace(/(\d{4})(\d)/, '$1-$2')
  }

  return str
}

export const unmaskCNPJ = cnpj => cnpj?.replace(/\D/g, '')

export const maskDate = input => {
  // check if input matches the format of DD/MM/YYYY
  if (!input.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
    // if it does not match, add missing characters and format input
    const inputArr = input.split('/')
    let day = inputArr[0] || ''
    let month = inputArr[1] || ''
    const year = inputArr[2] || ''
    if (day.length === 1 && day[0] !== '0' && day[0] !== '1') {
      day = '0' + day
    }
    if (month.length === 1) {
      month = '0' + month
    }
    input = day.concat('/', month, '/', year)
    return input
  }
}

export const onlyNumbers = value => value?.replace(/[^\d]/g, '')

export const maskCep = value => {
  value = value || ''
  let formatedValue = value.replace(/\D/g, '')

  formatedValue = formatedValue.replace(/^(\d{5})(\d)/, '$1-$2')

  return formatedValue
}

export const maskCurrency = value => {
  if (typeof value === 'number') {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(value)
  }

  const onlyDigits = value
    .split('')
    .filter(s => /\d/.test(s))
    .join('')
    .padStart(3, '0')
  const digitsFloat = onlyDigits.slice(0, -2) + '.' + onlyDigits.slice(-2)
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(digitsFloat)
}

export const maskPhoneNumber = value => {
  if (!value) return ''
  value = value.replace(/\D/g, '')
  value = value.replace(/(\d{2})(\d)/, '($1) $2')
  value = value.replace(/(\d)(\d{4})$/, '$1-$2')
  return value
}

export const cleanMoneyValue = value => {
  const cleaned = String(value)
    .replace(/[^\d,]/g, '')
    .replace(',', '.')
  return parseFloat(cleaned)
}

export const cleanCpfCnpj = value => {
  const cleaned = value.replace(/\D/g, '')
  return cleaned
}

export const maskRg = value => {
  value = value || ''

  let formatedValue = value.replace(/\D/g, '')

  formatedValue = formatedValue.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{1})$/,
    '$1.$2.$3-$4'
  )
  return formatedValue
}

export function maskCpf(value) {
  value = value || ''

  const x = value
    .replace(/\D+/g, '')
    .match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/)

  return (
    `${x[1]}` +
    (x[2] ? `.${x[2]}` : ``) +
    (x[3] ? `.${x[3]}` : ``) +
    (x[4] ? `-${x[4]}` : ``)
  )
}

export function maskPhoneNumberTyped(input) {
  input = input || ''
  // Remove any non-digit characters from the input value
  const cleanedValue = input.replace(/\D/g, '')

  // Define the mask pattern based on the input length
  let maskPattern
  if (cleanedValue.length <= 10) {
    // For 8 or 9-digit numbers
    maskPattern = /^(\d{0,2})(\d{0,4})(\d{0,4})$/
  } else {
    // For 10-digit numbers
    maskPattern = /^(\d{0,2})(\d{0,5})(\d{0,4})$/
  }

  // Split the cleaned value into groups
  const groups = cleanedValue.match(maskPattern)

  // Concatenate the groups with parentheses, a space, and a hyphen
  const maskedValue = groups.slice(1).filter(Boolean).join(') ')

  // Update the input value with the masked phone number
  return maskedValue
}
