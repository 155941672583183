import axios from 'axios'

export const getCnpjInfo = async cnpj => {
  try {
    const response = await axios.get(
      `https://api-publica.speedio.com.br/buscarcnpj?cnpj=${cnpj}`
    )
    return response.data
  } catch (err) {
    return {
      error: err
    }
  }
}
