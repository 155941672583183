import styled from 'styled-components'

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`

export const ModalContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 80%;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease-out;

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

export const ModalHeader = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  margin: 1rem 0 0 1rem;
  color: #303030;
`

export const ModalBody = styled.div`
  font-size: 1rem;
  margin-bottom: 20px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
`

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const Button = styled.button`
  padding: 8px 16px;
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`

export const Table = styled.table`
  text-align: center;
  border: solid 1px #e1e1e1;
  border-radius: 6px;
  margin-bottom: 20px;
  margin-top: 6px;
`

export const Th = styled.th`
  padding: 0.4rem;
`

export const Td = styled.td`
  padding: 0.4rem;
`
