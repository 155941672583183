import React, { useState } from 'react'
import { Button, Form, Input } from 'antd'
import { PersonalInfo } from '../01-personal-info/index'
import PartnerData from '../09-partner-data/index'
import { maskPhoneNumber } from '../../utils/masks'
import AppLayout from '../../components/app-layout/app-layout'

const DeliveryData = props => {
  // "deliveryInformation": {
  //     "informations": [
  //       {
  //         "deliveryType": "CONVENTIONAL",
  //         "addressType": "RESIDENTIAL",
  //         "addressComplement": "Apartamento 11",
  //         "contactName": "Marcio Soares",
  //         "contactPhoneDDD": "011",
  //         "contactPhoneNumber": 41278989,
  //         "latitude": 0,
  //         "longitude": 0,
  //         "referencePoint": "teste"
  //       }
  //     ]
  //   },

  const { previousStepData } = props

  const [formData, setFormData] = useState({})
  const [nextSteps, setNextStep] = useState(false)
  const [prevStep, setPrevStep] = useState(false)
  const [form] = Form.useForm()
  const formInitialValues = {
    name: '',
    telephone: ''
  }

  const formMaskFunctions = {
    telephone: value => maskPhoneNumber(value)
  }

  const handleInputsMask = inputChanged => {
    const currentFormInitialValues = form.getFieldsValue()

    const inputName = Object.keys(inputChanged)[0]

    if (formMaskFunctions[inputName]) {
      currentFormInitialValues[inputName] = formMaskFunctions[inputName](
        inputChanged[inputName]
      )
      form.setFieldsValue(currentFormInitialValues)
    }
  }
  const handleOnFinish = _data => {
    setFormData(previousStepData)
    setNextStep(true)
  }

  return (
    <>
      {!nextSteps && !prevStep && (
        <AppLayout>
          <header
            className="portal-header"
            style={{
              marginBottom: '2em',
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center'
            }}
          >
            <img
              style={{ height: '50px' }}
              alt="x-payLogo"
              src={require('../../assets/img/logo.png')}
            ></img>
            <div style={{ fontSize: '16pt' }}>Portal de Credenciamento</div>
          </header>
          <p className="title-page-info">
            {' '}
            Indique os detalhes do estabelecimento:
          </p>
          <div className="form-container">
            <Form
              useRef
              form={form}
              name="basic"
              style={{ width: 800 }}
              initialValues={formInitialValues}
              onFinish={handleOnFinish}
              onValuesChange={handleInputsMask}
            >
              <Form.Item
                label="Nome"
                name="name"
                rules={[{ required: true, message: 'Informe o Nome.' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Telefone"
                name="telephone"
                rules={[{ required: true, message: 'Informe o Telefone.' }]}
              >
                <Input maxLength={15} />
              </Form.Item>
              <Form.Item wrapperCol={{ span: 10 }}>
                <Button type="primary" htmlType="submit">
                  Próxima Etapa
                </Button>
              </Form.Item>
              <Form.Item>
                <Button danger onClick={() => setPrevStep(true)}>
                  cancelar
                </Button>
              </Form.Item>
            </Form>
          </div>
        </AppLayout>
      )}
      {nextSteps && <PartnerData previousStepData={formData} />}

      {prevStep && <PersonalInfo formDataPreLoaded={previousStepData} />}
    </>
  )
}

export default DeliveryData
