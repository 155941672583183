import { api, getHeaders } from './index'

export const sendProposal = async proposalPayload => {
  try {
    const response = await api.post(`/fiserv/send-proposal`, proposalPayload, {
      headers: getHeaders()
    })
    return response
  } catch (err) {
    throw err
  }
}

export const sendProposalRiskTeam = async proposalId => {
  try {
    const response = await api.post(
      `/fiserv/send-proposal-analysis-team/${proposalId}`,
      {},
      {
        headers: getHeaders()
      }
    )
    return response
  } catch (err) {
    throw err
  }
}
