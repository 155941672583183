import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Item = styled.div`
  p {
    font-weight: bold;
    margin: 0;
  }

  & + & {
    margin-top: 10px;
  }
`
