import React, { useState } from 'react'
import { Button, Result } from 'antd'
import { PersonalInfo } from '../01-personal-info/index'
import AppLayout from '../../components/app-layout/app-layout'
const ProposalSuccess = () => {
  const [prevStep, setPrevStep] = useState(false)

  return (
    <>
      {!prevStep && !prevStep && (
        <AppLayout>
          <Result
            status="success"
            title="Sua proposta foi enviada."
            subTitle="Será analisada pela nossa equipe e entraremos em contato após análise."
            extra={[
              <Button
                onClick={() => setPrevStep(true)}
                type="primary"
                key="console"
              >
                Voltar ao início
              </Button>
            ]}
          />
        </AppLayout>
      )}

      {prevStep && <PersonalInfo />}
    </>
  )
}
export default ProposalSuccess
