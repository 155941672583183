import React from 'react'
import * as AppLayoutComponentes from './app-layout-style'
import { AppSidebar } from './Sidebar'

const AppLayout = props => {
  return (
    <AppLayoutComponentes.AppWrapper>
      <AppSidebar {...props} />
      <AppLayoutComponentes.PageContent>
        {props.children}
      </AppLayoutComponentes.PageContent>
    </AppLayoutComponentes.AppWrapper>
  )
}

export default AppLayout
