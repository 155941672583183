import React, { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Cookies from 'js-cookies'

import { MainStepRegister } from './steps/MainStepRegister'
import { checkLogin } from '../../providers/api/login'

export const PersonalInfo = () => {
  const routerParams = useParams()
  const navigate = useNavigate()

  const handleCheckAuth = useCallback(async () => {
    const token = routerParams.token || Cookies.getItem('token')
    if (token) {
      const response = await checkLogin(token)

      if (!response.status) {
        Cookies.removeItem('token')
        return navigate('/login')
      }

      if (routerParams.token) {
        const url = window.location.toString()
        return (window.location = url.replace(routerParams.token, ''))
      }
    } else {
      navigate('/login')
    }
  }, [navigate, routerParams.token])

  useEffect(() => {
    handleCheckAuth()
  }, [handleCheckAuth])

  return <MainStepRegister />
}
