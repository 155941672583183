import React, { useCallback } from 'react'
import { Table, Td, Th } from '../styles/ModalPlan.style'

function converterParaPercentual(valor) {
  if (valor.includes(',')) {
    valor = valor.replace(',', '.')
  }
  const percentual = parseFloat(valor)
  const percentualFormatado = percentual.toFixed(2)
  return `${percentualFormatado.replace('.', ',') || '-'}%`
}

function formatacaoPercentual(valor) {
  if (valor) {
    return isNaN(valor.replace(',', '.')) ? '-' : converterParaPercentual(valor)
  }
  return '-'
}

function maiorArrayInstallments(feesFormatted) {
  feesFormatted.forEach(obj => {
    obj.installments.sort((a, b) => {
      if (a.transaction_type !== b.transaction_type) {
        if (a.transaction_type === '4') return -1
        if (b.transaction_type === '4') return 1
        if (a.transaction_type === '1') return -1
        if (b.transaction_type === '1') return 1
        return a.transaction_type - b.transaction_type
      }
      return a.installments - b.installments
    })
  })

  let maiorArray = null
  feesFormatted.forEach(obj => {
    if (
      !maiorArray ||
      obj.installments.length > maiorArray.installments.length
    ) {
      maiorArray = obj
    }
  })

  feesFormatted.forEach(obj => {
    if (obj !== maiorArray) {
      obj.installments.forEach(inst => {
        const exists = maiorArray.installments.some(
          maiorInst =>
            maiorInst.transaction_type === inst.transaction_type &&
            maiorInst.installments === inst.installments
        )
        if (!exists) {
          maiorArray.installments.push(inst)
        }
      })
    }
  })

  maiorArray.installments.sort((a, b) => {
    if (a.transaction_type !== b.transaction_type) {
      if (a.transaction_type === '4') return -1
      if (b.transaction_type === '4') return 1
      if (a.transaction_type === '1') return -1
      if (b.transaction_type === '1') return 1
      return a.transaction_type - b.transaction_type
    }
    return a.installments - b.installments
  })

  return maiorArray
}

function getDescriptionTransaction(transactionId) {
  try {
    const transactionTypes = {
      1: 'CRÉDITO À VISTA',
      2: 'CRÉDITO PARCELADO LOJISTA',
      3: 'CRÉDITO PARCELADO EMISSOR',
      4: 'DÉBITO',
      5: 'CANCELAMENTO',
      6: 'CHARGEBACK',
      7: 'BOLETO',
      9: 'VOUCHER',
      10: 'PIX',
      default: 'PADRÃO'
    }
    return transactionTypes[transactionId] || transactionTypes.default
  } catch (error) {
    return 'Não Informado'
  }
}

function getMaxInstallment(installments, transactionType) {
  return installments
    .filter(installment => installment.transaction_type === transactionType)
    .reduce((max, installment) => {
      return installment.mdr_percentage > parseFloat(max.mdr_percentage)
        ? installment
        : max
    }, 0)
}

function getPertence({ installments, isEcommerce, item, installment }) {
  if (item.transaction_type === '4') {
    return isEcommerce
      ? getMaxInstallment(installments, '4').mdr_percentage
      : getMaxInstallment(installments, '4').mdr_percentage_ecommerce
  }

  return isEcommerce
    ? installment.mdr_percentage
    : installment.mdr_percentage_ecommerce
}

export const TabelaPlanos = ({ values, isEcommerce = false }) => {
  const columns = [...new Set(values.map(plan => plan.Nom_BandeiraProduto))]

  const maiorArray = maiorArrayInstallments(values)

  const rows = maiorArray.installments.map(item => {
    const arr = []

    arr.push(
      item.transaction_type !== '2'
        ? getDescriptionTransaction(item.transaction_type)
        : `${item.installments} vezes`
    )

    columns.forEach(col => {
      const value = values.find(v => v.Nom_BandeiraProduto === col)

      if (!value) {
        arr.push('-')
        return
      }

      const installment = value.installments.find(inst =>
        item.transaction_type !== '2'
          ? inst.transaction_type === item.transaction_type
          : inst.installments === item.installments
      )

      if (!installment) {
        arr.push('-')
        return
      }

      const percentage = getPertence({
        installment,
        installments: value.installments,
        isEcommerce,
        item
      })

      arr.push(formatacaoPercentual(percentage))
    })

    return arr
  })

  return (
    <div>
      <Table>
        <thead>
          <tr>
            <Th />
            {columns.map((col, index) => (
              <Th key={index}>{col}</Th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={index}>
              {row.map((col, indexCol) => (
                <Td key={indexCol}>{col}</Td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
