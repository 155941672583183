import React, { useEffect, useState } from 'react'
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  message
} from 'antd'
import { PersonalInfo } from '../01-personal-info/index'
import BankData from '../10-bank-data/index'
import {
  cleanCpfCnpj,
  maskCpfCnpj,
  maskPhoneNumber,
  maskRg
} from '../../utils/masks'
import dayjs from 'dayjs'
import AppLayout from '../../components/app-layout/app-layout'
import $ from 'jquery'

const PartnerData = props => {
  const { previousStepData } = props
  const [formInitialValues, setFormInitialValues] = useState({
    partner_document: '',
    partner_name: '',
    partner_birth_date: '',
    partner_nacionality: '',
    partner_company_participation: '',
    constitution_type: null,
    partner_phone: '',
    partner_function: ''
  })

  const [nextSteps, setNextStep] = useState(false)
  const [prevStep, setPrevStep] = useState(false)
  const [partnerData, setPartnerData] = useState(['1'])
  const [formData, setFormData] = useState({})

  const [addInvoiceButton, setAddInvoiceButton] = useState(false)
  const [form] = Form.useForm()
  const [messageApi] = message.useMessage()

  const formMaskFunctions = {
    partner_document: value => maskCpfCnpj(value),
    partner_phone: value => maskPhoneNumber(value),
    partner_rg: value => maskRg(value)
  }

  const handleFormChanges = inputChanged => {
    const currentFormInitialValues = form.getFieldsValue()

    const inputName = Object.keys(inputChanged)[0]
    const splited = inputName.split('_')
    const inputNameSplited = `${splited[0]}_${splited[1]}`

    if (formMaskFunctions[inputNameSplited]) {
      currentFormInitialValues[inputName] = formMaskFunctions[inputNameSplited](
        inputChanged[inputName]
      )
      form.setFieldsValue(currentFormInitialValues)
    }

    if (inputName.includes('political_exposed')) {
      setFormInitialValues(currentFormInitialValues)
    }
  }

  const handleAddInvoice = () => {
    const currentPartnerData = [...partnerData]

    if (currentPartnerData.length + 1 > 3) {
      setAddInvoiceButton(true)
    }

    const lastItem = partnerData[partnerData.length - 1]

    currentPartnerData.push(String(Number(lastItem) + 1))

    setPartnerData(currentPartnerData)
  }

  const handleRemoveInvoice = invoice => {
    const currentPartnerData = [...partnerData]

    const findIndex = currentPartnerData.findIndex(
      element => element === invoice
    )

    currentPartnerData.splice(findIndex, 1)

    if (currentPartnerData.length < 3) {
      setAddInvoiceButton(false)
    }
    setPartnerData(currentPartnerData)
  }

  const handleOnFinish = data => {
    const partner = []
    const partners_rg = []
    let countPercent = 0

    for (let i = 1; i <= partnerData.length; i++) {
      const splitedPhone = data[`partner_phone_${i}`].split(' ')
      const splitedName = data[`partner_name_${i}`].split(' ')

      const cleanedDocument = cleanCpfCnpj(data[`partner_document_${i}`])

      const type = cleanedDocument.length === 11 ? 'P' : 'L'

      partner.push({
        cnpjCpf: cleanedDocument,
        ddd: `0${splitedPhone[0].replace('(', '').replace(')', '')}`,
        phone: splitedPhone[1].replace('-', ''),
        birthDate: dayjs(data[`partner_birth_date_${i}`]).format('YYYY-MM-DD'),
        firstName: splitedName[0],
        lastName: splitedName[splitedName.length - 1],
        function: data[`partner_function_${i}`],
        type,
        percPartSoc: data[`partner_company_participation_${i}`],
        nationality: data[`partner_nacionality_${i}`]
        // partner_company_participation: data[`partner_company_participation_${i}`],
        // political_exposed: data[`political_exposed_${i}`],
        // political_exposed_name: data[`political_exposed_name_${i}`],
        // political_exposed_position: data[`political_exposed_position_${i}`],
        // political_exposed_government_agency: data[`political_exposed_government_agency_${i}`],
      })

      partners_rg.push({
        cnpjCpf: cleanedDocument,
        rg: data[`partner_rg_${i}`]
          .replace('-', '')
          .replace('.', '')
          .replace('.', '')
      })

      countPercent = countPercent + data[`partner_company_participation_${i}`]
    }

    if (!countPercent || countPercent > 100 || countPercent < 100) {
      return messageApi.open({
        type: 'error',
        content: 'A participação na empresa deve totalizar 100%'
      })
    }

    const currentPrevState = { ...previousStepData }

    if (currentPrevState.legalPerson) {
      currentPrevState.legalPerson.companyPartners = partner
    }

    currentPrevState.partners_rg = partners_rg

    const payload = currentPrevState
    console.log(payload)
    setFormData(payload)
    setNextStep(true)
  }

  useEffect(() => {
    $('.ant-picker-input')
      .find('input')
      .attr('maxlength', '10')
      .mask('99/99/9999')
  })

  return (
    <>
      {!nextSteps && !prevStep && (
        <AppLayout>
          <p className="title-page-info"> Dados do proprietario:</p>
          <div className="form-container">
            <Form
              useRef
              form={form}
              name="basic"
              style={{ width: 800 }}
              initialValues={formInitialValues}
              onFinish={handleOnFinish}
              // onFinishFailed={onFinishFailed}
              onValuesChange={handleFormChanges}
              // autoComplete="off"
            >
              {partnerData.map((element, index) => (
                <div key={index} className="form-container-product">
                  <Form.Item
                    label="CPF/CNPJ"
                    name={`partner_document_${element}`}
                    rules={[{ required: true, message: 'Informe o CPF/CNPJ' }]}
                  >
                    <Input maxLength={18} on />
                  </Form.Item>

                  <Form.Item
                    label="RG"
                    name={`partner_rg_${element}`}
                    rules={[{ required: true, message: 'Informe o RG' }]}
                  >
                    <Input maxLength={10} />
                  </Form.Item>

                  <Form.Item
                    label="Nome"
                    name={`partner_name_${element}`}
                    rules={[{ required: true, message: 'Informe o Nome.' }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Data de nascimento"
                    name={`partner_birth_date_${element}`}
                    rules={[
                      {
                        required: true,
                        message: 'Informe a data de nascimento.'
                      }
                    ]}
                  >
                    <DatePicker
                      style={{ width: '100%' }}
                      placeholder="Selecione a data"
                      format="DD/MM/YYYY"
                      changeOnBlur
                      onBlur={date => {
                        const currentFormInitialValues = form.getFieldsValue()
                        currentFormInitialValues[
                          `partner_birth_date_${element}`
                        ] = dayjs(date.target.value, 'DD/MM/YYYY')
                        if (!date.target.value || date.target.value === '') {
                        } else {
                          form.setFieldsValue(currentFormInitialValues)
                        }
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Nacionalidade"
                    name={`partner_nacionality_${element}`}
                    rules={[
                      { required: true, message: 'Informe a nacionalidade.' }
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Celular"
                    name={`partner_phone_${element}`}
                    rules={[{ required: true, message: 'Informe o Celular.' }]}
                  >
                    <Input maxLength={15} />
                  </Form.Item>

                  {/* <Form.Item
                                label="Tipo de Constituição"
                                name={`constitution_type_${element}`}
                                rules={[{ required: true, message: 'Informe o Tipo de Constituição' }]}>
                                    <Select options={[
                                        {value: 1, label: 'Empresário Individual - EI'},
                                        {value: 2, label: 'Microempreendedor Individual - MEI'},
                                        {value: 3, label: 'Empresa Individual de Responsabilidade Limitada - EIRELI'},
                                        {value: 4, label: 'Sociedade Empresária'},
                                        {value: 5, label: 'Sociedade Simples'},
                                        {value: 6, label: 'Sociedade Anônima - SA'},
                                        {value: 7, label: 'Sociedade Limitada - LTDA'},
                                        {value: 8, label: 'Micro Empresa - ME'},
                                        {value: 9, label: 'Empresa Pequeno Porte'},
                                        {value: 10, label: 'Empresa Médio Porte'},
                                        {value: 11, label: 'Empresa Grande Porte'},
                                      ]}/>
                                </Form.Item> */}

                  <Form.Item
                    label="Função"
                    name={`partner_function_${element}`}
                    rules={[{ required: true, message: 'Informe a função' }]}
                  >
                    <Select
                      options={[
                        { label: 'Dono', value: 'Owner' },
                        { label: 'Sócio', value: 'Partner' },
                        { label: 'Diretor', value: 'Director' },
                        { label: 'Administrador', value: 'Officer' }
                      ]}
                    />
                  </Form.Item>

                  <Form.Item
                    label="participação na empresa em %"
                    name={`partner_company_participation_${element}`}
                    rules={[
                      {
                        required: true,
                        message: 'Informe a porcentagem de participação.'
                      }
                    ]}
                  >
                    <InputNumber
                      type="number"
                      prefix="%"
                      min={0.1}
                      max={100}
                      placeholder="000"
                    />
                  </Form.Item>

                  <Form.Item
                    label="Pessoa Politicamente Exposta?"
                    name={`political_exposed_${element}`}
                    rules={[
                      { required: true, message: 'Informe uma resposta' }
                    ]}
                  >
                    <Radio.Group>
                      <Radio value={true}>Sim</Radio>
                      <Radio value={false}>Não</Radio>
                    </Radio.Group>
                  </Form.Item>

                  {formInitialValues[`political_exposed_${element}`] && (
                    <>
                      <Form.Item
                        label="Nome"
                        name={`political_exposed_name_${element}`}
                        rules={[{ required: true, message: 'Informe o nome.' }]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="Cargo"
                        name={`political_exposed_position_${element}`}
                        rules={[
                          { required: true, message: 'Informe o cargo.' }
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="Orgão do Governo"
                        name={`political_exposed_government_agency_${element}`}
                        rules={[
                          {
                            required: true,
                            message: 'Informe o orgão do governo.'
                          }
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </>
                  )}

                  <Form.Item wrapperCol={{ span: 6 }}>
                    <Button danger onClick={() => handleRemoveInvoice(element)}>
                      Remover proprietário
                    </Button>
                  </Form.Item>
                </div>
              ))}
              <Form.Item className="form-container-product-add">
                <Button
                  width={300}
                  disabled={addInvoiceButton}
                  type="primary"
                  onClick={() => handleAddInvoice()}
                >
                  Adicionar proprietario
                </Button>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Próxima Etapa
                </Button>
              </Form.Item>

              <Form.Item>
                <Button danger onClick={() => setPrevStep(true)}>
                  cancelar
                </Button>
              </Form.Item>
            </Form>
          </div>
        </AppLayout>
      )}
      {nextSteps && <BankData previousStepData={formData} />}

      {prevStep && <PersonalInfo formDataPreLoaded={previousStepData} />}
    </>
  )
}

export default PartnerData
