import React, { useState } from 'react'
import cep from 'cep-promise'
import { Button, Form, Input, Radio } from 'antd'
import { maskCep, onlyNumbers } from '../../utils/masks'
import { PersonalInfo } from '../01-personal-info/index'
import Invoicing from '../03-invoicing'
import * as PersonalInfoAddressComponents from './components'
import AppLayout from '../../components/app-layout/app-layout'

const PersonalInfoAddress = props => {
  const { previousStepData } = props
  const [nextSteps, setNextStep] = useState(false)
  const [prevStep, setPrevStep] = useState(false)
  const [formData, setFormData] = useState({})
  const [form] = Form.useForm()
  const [formInitialValues, setFormInitialValues] = useState({
    cep: '',
    street: '',
    city: '',
    uf: '',
    neighborhood: '',
    number: '',
    receive_machine: '',
    use_mailing_address: '',
    use_fiscal_address: ''
  })

  const formMaskFunctions = {
    cep: value => maskCep(value),
    uf: value => value.toUpperCase().replace(/[^a-zA-Z]+/g, ''),
    residential_cep: value => maskCep(value),
    residential_uf: value => value.toUpperCase().replace(/[^a-zA-Z]+/g, ''),
    trading_cep: value => maskCep(value),
    trading_uf: value => value.toUpperCase().replace(/[^a-zA-Z]+/g, '')
  }

  const getAddressData = async (value, inputName) => {
    try {
      const currentFormInitialValues = form.getFieldsValue()

      const cleanCep = onlyNumbers(value)
      const addressData = await cep(cleanCep)

      if (inputName === 'bussiness') {
        currentFormInitialValues.street = addressData.street
        currentFormInitialValues.uf = addressData.state
        currentFormInitialValues.city = addressData.city
        currentFormInitialValues.neighborhood = addressData.neighborhood
      } else {
        currentFormInitialValues[`${inputName}_street`] = addressData.street
        currentFormInitialValues[`${inputName}_uf`] = addressData.state
        currentFormInitialValues[`${inputName}_city`] = addressData.city
        currentFormInitialValues[`${inputName}_neighborhood`] =
          addressData.neighborhood
      }

      form.setFieldsValue(currentFormInitialValues)
    } catch (err) {
      const currentFormInitialValues = form.getFieldsValue()

      if (inputName === 'bussiness') {
        currentFormInitialValues.street = ''
        currentFormInitialValues.uf = ''
        currentFormInitialValues.city = ''
        currentFormInitialValues.neighborhood = ''
      } else {
        currentFormInitialValues[`${inputName}_street`] = ''
        currentFormInitialValues[`${inputName}_uf`] = ''
        currentFormInitialValues[`${inputName}_city`] = ''
        currentFormInitialValues[`${inputName}_neighborhood`] = ''
      }

      form.setFieldsValue(currentFormInitialValues)
    }
  }

  const handleInputChange = async inputChanged => {
    const currentFormInitialValues = form.getFieldsValue()

    const inputName = Object.keys(inputChanged)[0]
    const inputValue = inputChanged[inputName]

    if (formMaskFunctions[inputName]) {
      currentFormInitialValues[inputName] =
        formMaskFunctions[inputName](inputValue)
      form.setFieldsValue(currentFormInitialValues)
    }

    if (
      (inputName === 'cep' ||
        inputName === 'residential_cep' ||
        inputName === 'trading_cep' ||
        inputName === 'fiscal_cep') &&
      inputValue.length === 9
    ) {
      let input = 'bussiness'

      const inputNameSplited = inputName.split('_')
      if (inputNameSplited[1]) {
        input = inputNameSplited[0]
      }

      getAddressData(inputValue, input)
    }

    setFormInitialValues(currentFormInitialValues)
  }

  const handleOnFinish = data => {
    const zipCode = data.cep.replace('-', '')
    const address = []

    if (!data.receive_machine) {
      address.push({
        street: data.trading_street,
        number: data.trading_number,
        neighborhood: data.trading_neighborhood,
        complement: data.trading_complement,
        city: data.trading_city,
        state: data.trading_uf,
        type: 'TRADING',
        zipCode: data.trading_cep.replace('-', ''),
        email: previousStepData.email,
        fixedDdd: previousStepData.fixedDdd,
        fixedNumber: previousStepData.fixedNumber,
        mobileDdd: previousStepData.mobileDdd,
        mobileNumber: previousStepData.mobileNumber
      })
    }

    if (data.receive_machine) {
      address.push({
        street: data.street,
        number: data.number,
        neighborhood: data.neighborhood,
        complement: data.complement,
        city: data.city,
        state: data.uf,
        type: 'TRADING',
        zipCode,
        email: previousStepData.email,
        fixedDdd: previousStepData.fixedDdd,
        fixedNumber: previousStepData.fixedNumber,
        mobileDdd: previousStepData.mobileDdd,
        mobileNumber: previousStepData.mobileNumber
      })
    }

    address.push({
      street: data.street,
      number: data.number,
      neighborhood: data.neighborhood,
      complement: data.complement,
      city: data.city,
      state: data.uf,
      type: 'BUSINESS',
      zipCode,
      email: previousStepData.email,
      fixedDdd: previousStepData.fixedDdd,
      fixedNumber: previousStepData.fixedNumber,
      mobileDdd: previousStepData.mobileDdd,
      mobileNumber: previousStepData.mobileNumber
    })

    const payload = {
      ...previousStepData,
      address
    }

    setFormData(payload)
    setNextStep(true)
  }

  return (
    <>
      {!nextSteps && !prevStep && (
        <AppLayout>
          <p className="title-page-info"> Dados cadastrais</p>
          <p className="title-page-info"> Informe o endereço comercial</p>
          <div className="form-container">
            <Form
              useRef
              form={form}
              name="basic"
              style={{ width: 800 }}
              initialValues={formInitialValues}
              onFinish={handleOnFinish}
              onValuesChange={handleInputChange}
            >
              {/* <Row gutter={16}> */}
              {/* <Col xs={24} sm={24} md={24} lg={24} xl={24} >    */}
              <Form.Item
                label="CEP"
                name="cep"
                rules={[{ required: true, message: 'Informe o CEP' }]}
              >
                <Input maxLength={9} />
              </Form.Item>
              {/* </Col> */}
              {/* </Row> */}

              {/* <Row gutter={16}> */}
              {/* <Col xs={24} sm={24} md={24} lg={24} xl={12}> */}
              <Form.Item
                label="Endereço"
                name="street"
                rules={[{ required: true, message: 'Informe o Endereço.' }]}
              >
                <Input />
              </Form.Item>

              {/* </Col> */}
              {/* <Col xs={24} sm={24} md={24} lg={24} xl={12} > */}
              <Form.Item
                label="Número"
                name="number"
                rules={[{ required: true, message: 'Informe o Número' }]}
              >
                <Input />
              </Form.Item>
              {/* </Col> */}
              {/* </Row>  */}

              <Form.Item label="Complemento" name="complement">
                <Input />
              </Form.Item>
              {/* <Row gutter={16}> */}
              {/* <Col xs={24} sm={24} md={24} lg={24} xl={12}> */}
              <Form.Item
                label="Bairro"
                name="neighborhood"
                rules={[{ required: true, message: 'Informe o Bairro.' }]}
              >
                <Input />
              </Form.Item>
              {/* </Col> */}
              {/* <Col xs={24} sm={24} md={24} lg={24} xl={12} >    */}
              <Form.Item
                label="Cidade"
                name="city"
                rules={[{ required: true, message: 'Informe a Cidade.' }]}
              >
                <Input />
              </Form.Item>
              {/* </Col> */}

              {/* </Row>  */}
              {/* <Row gutter={16}> */}
              {/* <Col xs={24} sm={24} md={24} lg={24} xl={24} >    */}

              <Form.Item
                label="Estado"
                name="uf"
                rules={[{ required: true, message: 'Informe o Estado.' }]}
              >
                <Input maxLength={2} />
              </Form.Item>
              {/* </Col> */}
              {/* </Row> */}

              {/* <Row gutter={16}> */}
              {/* <Col xs={24} sm={24} md={24} lg={24} xl={24} >    */}

              <Form.Item label="Ponto de referencia" name="place_reference">
                <Input />
              </Form.Item>
              {/* </Col> */}
              {/* </Row> */}

              <>
                <Form.Item
                  label="O endereço de entrega do equipamento físico é o mesmo do endereço comercial ?"
                  name="receive_machine"
                  rules={[{ required: true, message: 'Informe uma resposta' }]}
                >
                  <Radio.Group>
                    <Radio value={true}>Sim</Radio>
                    <Radio value={false}>Não</Radio>
                  </Radio.Group>
                </Form.Item>

                {!formInitialValues.receive_machine &&
                  formInitialValues.receive_machine !== '' && (
                    <PersonalInfoAddressComponents.AddressTypeContainer>
                      <PersonalInfoAddressComponents.AddressTypeContainerTitle>
                        Informe o endereço que deseja receber a maquininha
                      </PersonalInfoAddressComponents.AddressTypeContainerTitle>
                      <Form.Item
                        label="CEP"
                        name="trading_cep"
                        rules={[{ required: true, message: 'Informe o CEP' }]}
                      >
                        <Input maxLength={9} />
                      </Form.Item>
                      <Form.Item
                        label="Endereço"
                        name="trading_street"
                        rules={[
                          { required: true, message: 'Informe o Endereço.' }
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Número"
                        name="trading_number"
                        rules={[
                          { required: true, message: 'Informe o Número' }
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item label="Complemento" name="trading_complement">
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="Bairro"
                        name="trading_neighborhood"
                        rules={[
                          { required: true, message: 'Informe o Bairro.' }
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="Cidade"
                        name="trading_city"
                        rules={[
                          { required: true, message: 'Informe a Cidade.' }
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="Estado"
                        name="trading_uf"
                        rules={[
                          { required: true, message: 'Informe o Estado.' }
                        ]}
                      >
                        <Input maxLength={2} />
                      </Form.Item>
                    </PersonalInfoAddressComponents.AddressTypeContainer>
                  )}

                <Form.Item
                  label="O endereço fiscal é o mesmo do endereço comercial ?"
                  name="use_fiscal_address"
                  rules={[{ required: true, message: 'Informe uma resposta' }]}
                >
                  <Radio.Group>
                    <Radio value={true}>Sim</Radio>
                    <Radio value={false}>Não</Radio>
                  </Radio.Group>
                </Form.Item>

                {!formInitialValues.use_fiscal_address &&
                  formInitialValues.use_fiscal_address !== '' && (
                    <PersonalInfoAddressComponents.AddressTypeContainer>
                      <PersonalInfoAddressComponents.AddressTypeContainerTitle>
                        Informe o Endereço de correspondência
                      </PersonalInfoAddressComponents.AddressTypeContainerTitle>
                      <Form.Item
                        label="CEP"
                        name="fiscal_cep"
                        rules={[{ required: true, message: 'Informe o CEP' }]}
                      >
                        <Input maxLength={9} />
                      </Form.Item>
                      <Form.Item
                        label="Endereço"
                        name="fiscal_street"
                        rules={[
                          { required: true, message: 'Informe o Endereço.' }
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Número"
                        name="fiscal_number"
                        rules={[
                          { required: true, message: 'Informe o Número' }
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item label="Complemento" name="fiscal_complement">
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="Bairro"
                        name="fiscal_neighborhood"
                        rules={[
                          { required: true, message: 'Informe o Bairro.' }
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="Cidade"
                        name="fiscal_city"
                        rules={[
                          { required: true, message: 'Informe a Cidade.' }
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="Estado"
                        name="fiscal_uf"
                        rules={[
                          { required: true, message: 'Informe o Estado.' }
                        ]}
                      >
                        <Input maxLength={2} />
                      </Form.Item>
                    </PersonalInfoAddressComponents.AddressTypeContainer>
                  )}

                <Form.Item
                  label="O endereço de correspondência é o mesmo do endereço comercial ?"
                  name="use_mailing_address"
                  rules={[{ required: true, message: 'Informe uma resposta' }]}
                >
                  <Radio.Group>
                    <Radio value={true}>Sim</Radio>
                    <Radio value={false}>Não</Radio>
                  </Radio.Group>
                </Form.Item>

                {!formInitialValues.use_mailing_address &&
                  formInitialValues.use_mailing_address !== '' && (
                    <PersonalInfoAddressComponents.AddressTypeContainer>
                      <PersonalInfoAddressComponents.AddressTypeContainerTitle>
                        Informe o Endereço de correspondência
                      </PersonalInfoAddressComponents.AddressTypeContainerTitle>
                      <Form.Item
                        label="CEP"
                        name="residential_cep"
                        rules={[{ required: true, message: 'Informe o CEP' }]}
                      >
                        <Input maxLength={9} />
                      </Form.Item>
                      <Form.Item
                        label="Endereço"
                        name="residential_street"
                        rules={[
                          { required: true, message: 'Informe o Endereço.' }
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Número"
                        name="residential_number"
                        rules={[
                          { required: true, message: 'Informe o Número' }
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Complemento"
                        name="residential_complement"
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="Bairro"
                        name="residential_neighborhood"
                        rules={[
                          { required: true, message: 'Informe o Bairro.' }
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="Cidade"
                        name="residential_city"
                        rules={[
                          { required: true, message: 'Informe a Cidade.' }
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        label="Estado"
                        name="residential_uf"
                        rules={[
                          { required: true, message: 'Informe o Estado.' }
                        ]}
                      >
                        <Input maxLength={2} />
                      </Form.Item>
                    </PersonalInfoAddressComponents.AddressTypeContainer>
                  )}
              </>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Próxima Etapa
                </Button>
              </Form.Item>

              <Form.Item>
                <Button danger onClick={() => setPrevStep(true)}>
                  cancelar
                </Button>
              </Form.Item>
            </Form>
          </div>
        </AppLayout>
      )}
      {prevStep && <PersonalInfo formDataPreLoaded={previousStepData} />}
      {nextSteps && <Invoicing previousStepData={formData} />}
    </>
  )
}

export default PersonalInfoAddress
