import React, { createContext, useState } from 'react'
import { getTechnologies } from '../providers/api/technologies'

export const UserContext = createContext({})

export function UserProvider({ children }) {
  const [technologies, setTechnologies] = useState([])
  const [products, setProducts] = useState([])

  const handleGetProducts = async () => {
    try {
      const technologiesResponse = await getTechnologies()
      return technologiesResponse.data
    } catch (err) {
      return []
    }
  }

  const filterTechnologies = async value => {
    const techs = await handleGetProducts()
    let filteredTechs = []

    if (value !== 'BOTH') {
      filteredTechs = techs.filter(element => {
        if (value === 'TRADITIONAL') {
          return (
            element.technologyGroup === 'POS' ||
            element.technologyGroup === 'ISO'
          )
        } else if (value === 'E_COMMERCE' || value === 'LINK_PAYMENT') {
          return (
            element.technologyGroup === 'COM' && element.idService === '399'
          )
        }
        return element
      })
    } else {
      filteredTechs = techs
    }

    setTechnologies(filteredTechs)
    setProducts([{ tech: filteredTechs }])
  }

  return (
    <UserContext.Provider
      value={{
        technologies,
        filterTechnologies,
        products,
        setProducts
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
