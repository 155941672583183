import { api, getHeaders } from './index'

export const getDefaultRates = async () => {
  try {
    const response = await api.get('/rates', {
      headers: getHeaders()
    })

    return response.data
  } catch (err) {
    return []
  }
}

export const getPlanDetails = async planId => {
  try {
    const response = await api.get(`/rates/plan/${planId}`, {
      headers: getHeaders()
    })

    return response.data
  } catch (err) {
    return []
  }
}
