import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select
} from 'antd'
import dayjs from 'dayjs'
import Cookies from 'js-cookies'
import {
  cleanCpfCnpj,
  cleanMoneyValue,
  maskCpfCnpj,
  maskCurrency,
  maskPhoneNumber,
  onlyNumbers,
  unmaskCNPJ
} from '../../../utils/masks'
import { getCnpjInfo } from '../../../utils/cnpj-info'
import { validateCnpj } from '../../../providers/api/accreditations'
import {
  RegisterEcContext,
  StepRegisterEC
} from '../../../Context/register-ec-provider'
import ModalPlans from '../components/ModalPlans'

export const Step1Company = ({ messageApi }) => {
  const {
    data,
    changeStep,
    cnaesData,
    getCnaesData,
    getRates,
    rates,
    getPlan,
    plan
  } = useContext(RegisterEcContext)

  const [form] = Form.useForm()
  const [pfOrPj, setPfOrPj] = useState('')
  const [isExposePerson, setExposePerson] = useState(false)
  const [modalPlan, setModalPlan] = useState(false)
  const formMaskFunctions = useMemo(
    () => ({
      document: value => maskCpfCnpj(value),
      cellphone: value => maskPhoneNumber(value),
      telephone: value => maskPhoneNumber(value),
      anual_billing_credit_card: value => maskCurrency(value),
      average_ticket: value => maskCurrency(value),
      anual_billing: value => maskCurrency(value),
      cards_billing: value => maskCurrency(value)
    }),
    []
  )

  const attPlanDetail = useCallback(
    async value => {
      await getPlan(value)
    },
    [getPlan]
  )

  const handleDeselect = useCallback(async () => {
    await getPlan()
  }, [getPlan])

  const handleInputsMask = useCallback(
    inputChanged => {
      const currentFormInitialValues = form.getFieldsValue()

      const inputName = Object.keys(inputChanged)[0]

      if (formMaskFunctions[inputName]) {
        currentFormInitialValues[inputName] = formMaskFunctions[inputName](
          inputChanged[inputName]
        )

        form.setFieldsValue(currentFormInitialValues)
      }

      if (inputName === 'document') {
        if (currentFormInitialValues.document.length < 14) {
          setPfOrPj('')
        }
      }

      if (inputName === 'political_exposed') {
        form.setFieldValue('political_exposed', inputChanged.political_exposed)
      }

      if (inputName === 'formPlano') {
        attPlanDetail(inputChanged[inputName])
      }
    },
    [form, formMaskFunctions, attPlanDetail]
  )

  const handleConfirmDocument = async () => {
    try {
      const currentFormInitialValues = form.getFieldsValue()
      const cleanedDocument = cleanCpfCnpj(currentFormInitialValues.document)

      const idCompany = Cookies.getItem('companyId')
      const data = { idCompany, cpf_cnpj: cleanedDocument }

      const response = await validateCnpj(data)

      const regexCPF = /^\d{11}$/

      const regexCNPJ = /^\d{14}$/

      if (!regexCPF.test(cleanedDocument) && !regexCNPJ.test(cleanedDocument)) {
        messageApi.open({
          type: 'error',
          content: 'CPF/CNPJ invalido.'
        })
        return
      }

      if (response.count > 0) {
        messageApi.open({
          type: 'error',
          content: 'CPF/CNPJ invalido ou já cadastrado.'
        })
        return
      }
      const isPJ = currentFormInitialValues.document.length === 18

      if (!currentFormInitialValues.document.length) {
        setPfOrPj('')
      } else {
        setPfOrPj(isPJ ? 'pj' : 'pf')
      }

      if (isPJ) {
        handleCnpjInfo()
      }

      Promise.all([
        await getCnaesData(currentFormInitialValues.document),
        await getRates()
      ])
    } catch (err) {
      messageApi.open({
        type: 'error',
        content: 'Erro ao validar CPF/CNPJ.'
      })
    }
  }

  const toggleModalPlan = useCallback(() => {
    setModalPlan(old => !old)
  }, [])

  const handleCnpjInfo = async () => {
    const currentFormInitialValues = form.getFieldsValue()

    const cnpj = unmaskCNPJ(currentFormInitialValues.document)
    const cnpjInfo = await getCnpjInfo(cnpj)
    const companyData = {}

    if (!cnpjInfo.error) {
      for (const key of Object.keys(cnpjInfo)) {
        const cleankey = key.replace(/\s/g, '')
        companyData[cleankey] = cnpjInfo[key]
      }

      currentFormInitialValues.social_name = companyData.RAZAOSOCIAL
      currentFormInitialValues.company_name = companyData.NOMEFANTASIA
      currentFormInitialValues.founding_date = dayjs(
        companyData.DATAABERTURA,
        'DD/MM/YYYY'
      )
      currentFormInitialValues.cityInscription = companyData.MUNICIPIO
      currentFormInitialValues.stateInscription = companyData.UF
      form.setFieldsValue({
        ...form.getFieldsValue(),
        ...currentFormInitialValues
      })
    }
  }

  const handleOnFinish = dataStep => {
    dataStep.birthDate = dayjs(dataStep.birthDate).format('YYYY-MM-DD')
    dataStep.founding_date = dayjs(dataStep.founding_date).format('YYYY-MM-DD')

    const payload = {
      cnae: dataStep.cnae,
      fees: []
    }

    const cleanedDocument = cleanCpfCnpj(dataStep.document)
    if (cleanedDocument.length === 11) {
      const splitedName = dataStep.name.split(' ')

      payload.physicalPerson = {
        birthDate: dataStep.birthDate,
        cpf: cleanedDocument,
        name: dataStep.name,
        nationality: dataStep.nationality,
        pepIndicator: dataStep.political_exposed,
        surname: splitedName[splitedName.length - 1],
        localBirth: dataStep.localBirth,
        motherName: dataStep.motherName,
        formPlano: dataStep.formPlano
      }

      if (dataStep.political_exposed) {
        payload.physicalPerson.politicallyExposedPerson = {
          governmentAgency: dataStep.political_exposed_government_agency,
          name: dataStep.political_exposed_name,
          responsibility: dataStep.political_exposed_position
        }
      }
    } else {
      payload.legalPerson = {
        cnpj: cleanedDocument,
        cityInscription: dataStep.cityInscription,
        dateConstitution: dataStep.founding_date,
        fantasyName: dataStep.company_name,
        openDate: dataStep.founding_date,
        socialReason: dataStep.social_name,
        stateInscription: dataStep.stateInscription,
        formConstitution: dataStep.formConstitution,
        formPlano: dataStep.formPlano
      }
    }

    const splitedCellphone = dataStep.cellphone.split(' ')
    const splitedFixphone = dataStep.telephone.split(' ')

    payload.email = dataStep.email
    payload.fixedDdd = `0${splitedFixphone[0].replace('(', '').replace(')', '')}`
    payload.fixedNumber = splitedFixphone[1].replace('-', '')
    payload.mobileDdd = `0${splitedCellphone[0].replace('(', '').replace(')', '')}`
    payload.mobileNumber = splitedCellphone[1].replace('-', '')
    payload.annualVolumeOfSalesCard = cleanMoneyValue(
      dataStep.anual_billing_credit_card
    )
    payload.averageTicket = cleanMoneyValue(dataStep.average_ticket)
    payload.volume_transactions_days = Number(dataStep.volume_transactions_days)

    const monthlyIncome = cleanMoneyValue(dataStep.anual_billing) / 12

    if (data?.legalPerson) {
      data.legalPerson.monthlyAverageBilling = monthlyIncome
    } else if (data?.physicalPerson) {
      data.physicalPerson.monthlyIncome = monthlyIncome
    }
    payload.cnae = dataStep.cnae
    payload.annualBillingVolume = cleanMoneyValue(dataStep.anual_billing)
    payload.internalTaxs = [] // TODO
    payload.fees = [
      // {
      // TODO
      // chargeModel: currentType === 'mdr' ? '502' : '501',
      // feeDetails: {
      //   services
      // }
      // }
    ]

    changeStep({ step: StepRegisterEC.Step2Address, data: payload })
  }

  function disableDateIsAfter18Years(currentDate) {
    if (!currentDate) return false
    const today = dayjs()
    return currentDate.isAfter(today.subtract(18, 'years'))
  }

  function disableDateIsAfterNow(currentDate) {
    if (!currentDate) return false
    return currentDate.isAfter(dayjs())
  }

  useEffect(() => {
    const document = form.getFieldValue('document')

    if (document) {
      setPfOrPj(document.length === 18 ? 'pj' : 'pf')
    }
  }, [form])

  useEffect(() => {
    async function getStepCompany() {
      if (!Object.keys(data).length) {
        return
      }

      const isPF = !!data?.physicalPerson?.cpf
      const formPlano = data?.physicalPerson?.formPlano
        ? data?.physicalPerson?.formPlano
        : data?.legalPerson?.formPlano

      const ratesResponse = await getRates()
      const rate = ratesResponse.find(e => e.Id === formPlano)

      const dados = {
        telephone: maskPhoneNumber(
          `${String(data?.fixedDdd || '').substring(1)}${data?.fixedNumber || ''}` ||
            ''
        ),
        cellphone: maskPhoneNumber(
          `${String(data?.mobileDdd || '').substring(1)}${data?.mobileNumber || ''}` ||
            ''
        ),
        email: data?.email || '',
        anual_billing_credit_card: data?.annualVolumeOfSalesCard
          ? maskCurrency(data?.annualVolumeOfSalesCard)
          : '',
        average_ticket: data?.averageTicket
          ? maskCurrency(data?.averageTicket)
          : '',
        volume_transactions_days: data?.volume_transactions_days || 0,
        cnae: data?.cnae || null,
        anual_billing: data?.annualBillingVolume
          ? maskCurrency(data.annualBillingVolume)
          : null,
        formPlano: rate.Id || null
      }
      if (isPF) {
        Object.assign(dados, {
          document: data?.physicalPerson?.cpf
            ? maskCpfCnpj(data?.physicalPerson?.cpf)
            : '',
          founding_date: '',
          formConstitution: '',
          social_name: '',
          company_name: '',
          name: data?.physicalPerson?.name || '',
          birthDate: data?.physicalPerson?.birthDate
            ? dayjs(data?.physicalPerson?.birthDate)
            : '',
          nationality: data?.physicalPerson?.nationality || '',
          political_exposed: data?.physicalPerson?.pepIndicator || '',
          cityInscription: data?.cityInscription || '',
          stateInscription: '',
          localBirth: data?.physicalPerson?.localBirth || '',
          motherName: data?.physicalPerson?.motherName || ''
        })
      } else {
        Object.assign(dados, {
          document: data?.legalPerson?.cnpj
            ? maskCpfCnpj(data?.legalPerson?.cnpj)
            : '',
          founding_date: data?.legalPerson?.openDate
            ? dayjs(data?.legalPerson?.openDate)
            : '',
          formConstitution: data?.legalPerson?.formConstitution || '',
          formPlano: data?.legalPerson?.formPlano || '',
          social_name: data?.legalPerson?.socialReason || '',
          company_name: data?.legalPerson?.fantasyName || '',
          name: '',
          birthDate: '',
          nationality: '',
          political_exposed: '',
          political_exposed_position: '',
          political_exposed_government_agency: '',
          cityInscription: data?.legalPerson?.cityInscription || '',
          stateInscription: data?.legalPerson?.stateInscription || '',
          localBirth: '',
          motherName: ''
        })
      }

      form.setFieldsValue(dados)
      setPfOrPj(isPF ? 'pf' : 'pj')
    }

    getStepCompany()
  }, [data, form, getRates])

  return (
    <div className="form-container">
      <Form
        useRef
        form={form}
        name="step_one"
        style={{ width: '60%' }}
        onFinish={handleOnFinish}
        onValuesChange={handleInputsMask}
      >
        <Row gutter={20}>
          <Col xs={5} sm={9} md={14} lg={22} xl={22}>
            <Form.Item
              label="CPF/CNPJ"
              name="document"
              rules={[{ required: true, message: 'Informe o CPF/CNPJ' }]}
            >
              <Input maxLength={18} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={15} md={10} lg={2} xl={1}>
            <Form.Item>
              <Button
                onClick={handleConfirmDocument}
                type="primary"
                htmlType="button"
              >
                Confirmar
              </Button>
            </Form.Item>
          </Col>
        </Row>
        {pfOrPj === 'pj' && (
          <>
            <Form.Item
              label="Razão Social"
              name="social_name"
              rules={[{ required: true, message: 'Informe a Razão Social.' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Nome Fantasia"
              name="company_name"
              rules={[{ required: true, message: 'Informe o Nome Fantasia.' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Data da Fundação"
              name="founding_date"
              rules={[
                {
                  required: true,
                  message: 'Informe a Data da fundação.'
                }
              ]}
            >
              <DatePicker
                style={{ width: '100%' }}
                placeholder="Selecione uma data"
                format="DD/MM/YYYY"
                disabledDate={disableDateIsAfterNow}
                onBlur={date => {
                  if (date.target.value) {
                    const foundingDate = dayjs(date.target.value, 'DD/MM/YYYY')
                    form.setFieldValue('founding_date', foundingDate)
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              label="Tipo de Constituição"
              name="formConstitution"
              rules={[
                {
                  required: true,
                  message: 'Informe o Tipo de Constituição'
                }
              ]}
            >
              <Select
                options={[
                  { value: 1, label: 'Empresário Individual - EI' },
                  {
                    value: 2,
                    label: 'Microempreendedor Individual - MEI'
                  },
                  {
                    value: 3,
                    label:
                      'Empresa Individual de Responsabilidade Limitada - EIRELI'
                  },
                  { value: 4, label: 'Sociedade Empresária' },
                  { value: 5, label: 'Sociedade Simples' },
                  { value: 6, label: 'Sociedade Anônima - SA' },
                  { value: 7, label: 'Sociedade Limitada - LTDA' },
                  { value: 8, label: 'Micro Empresa - ME' },
                  { value: 9, label: 'Empresa Pequeno Porte' },
                  { value: 10, label: 'Empresa Médio Porte' },
                  { value: 11, label: 'Empresa Grande Porte' }
                ]}
              />
            </Form.Item>
            <Form.Item
              label="Município"
              name="cityInscription"
              rules={[{ required: true, message: 'Informe o Município.' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Estado"
              name="stateInscription"
              rules={[{ required: true, message: 'Informe o Estado.' }]}
            >
              <Input />
            </Form.Item>
            <Divider />
          </>
        )}
        {pfOrPj === 'pf' && (
          <>
            <Form.Item
              label="Nome"
              name="name"
              rules={[{ required: true, message: 'Informe o Nome.' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Data de nascimento"
              name="birthDate"
              rules={[
                {
                  required: true,
                  message: 'Informe a Data de nascimento.'
                }
              ]}
            >
              <DatePicker
                style={{ width: '100%' }}
                placeholder="Selecione uma data"
                format="DD/MM/YYYY"
                disabledDate={disableDateIsAfter18Years}
                onBlur={date => {
                  if (date.target.value) {
                    const birthDate = dayjs(date.target.value, 'DD/MM/YYYY')
                    form.setFieldValue('birthDate', birthDate)
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              label="Nacionalidade"
              name="nationality"
              rules={[{ required: true, message: 'Informe a Nacionalidade.' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Cidade de Nascimento"
              name="localBirth"
              rules={[
                {
                  required: true,
                  message: 'Informe a cidade de nascimento.'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Nome da mãe"
              name="motherName"
              rules={[{ required: true, message: 'Informe o nome da mãe.' }]}
            >
              <Input />
            </Form.Item>
            <Divider />
          </>
        )}
        {(pfOrPj === 'pf' || pfOrPj === 'pj') && (
          <>
            <Form.Item
              label="CNAE"
              name="cnae"
              rules={[{ required: true, message: 'Informe o CNAE' }]}
            >
              <Select
                showSearch
                placeholder="Selecione um cnae"
                defaultActiveFirstOption={true}
                filterOption={true}
                options={cnaesData.map(element => ({
                  value: element.id,
                  label: element.description
                }))}
              />
            </Form.Item>
            <Form.Item
              label="Faturamento Anual"
              name="anual_billing"
              rules={[
                {
                  required: true,
                  message: 'Informe o faturamento anual.'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Faturamento anual cartão de crédito"
              name="anual_billing_credit_card"
              rules={[
                {
                  required: true,
                  message: 'Informe o faturamento anual no cartão de crédito.'
                }
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Ticket Médio"
              name="average_ticket"
              rules={[{ required: true, message: 'Informe o ticket médio.' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Volume de transações diárias"
              name="volume_transactions_days"
              rules={[
                {
                  required: true,
                  message: 'Informe o máximo de transações diárias.'
                }
              ]}
            >
              <Input type="number" min={1} />
            </Form.Item>

            <Form.Item
              label="Selecione o Plano"
              name="formPlano"
              rules={[
                {
                  required: true,
                  message: 'Informe o Plano'
                }
              ]}
            >
              <Select
                onClear={handleDeselect}
                allowClear
                options={rates.map(rate => ({
                  value: rate.Id,
                  label: rate.Nome
                }))}
              />
            </Form.Item>
            {!!plan.length && (
              <p className="plan-details-step1" onClick={toggleModalPlan}>
                Clique aqui para conferir as taxas do plano escolhido
              </p>
            )}

            <Form.Item
              label="E-mail"
              name="email"
              rules={[{ required: true, message: 'Informe o E-mail.' }]}
            >
              <Input type="email" />
            </Form.Item>
            <Form.Item
              label="Telefone"
              name="telephone"
              rules={[{ required: true, message: 'Informe o Telefone.' }]}
            >
              <Input maxLength={15} />
            </Form.Item>
            <Form.Item
              label="Celular"
              name="cellphone"
              rules={[{ required: true, message: 'Informe o Celular.' }]}
            >
              <Input maxLength={15} />
            </Form.Item>
            <Divider />
          </>
        )}
        {pfOrPj === 'pf' && (
          <>
            <Form.Item
              label="Pessoa Politicamente Exposta?"
              name="political_exposed"
              rules={[{ required: true, message: 'Informe uma resposta' }]}
            >
              <Radio.Group onChange={e => setExposePerson(e.target.value)}>
                <Radio value={true}>Sim</Radio>
                <Radio value={false}>Não</Radio>
              </Radio.Group>
            </Form.Item>

            {isExposePerson && (
              <>
                <Form.Item
                  label="Nome"
                  name="political_exposed_name"
                  rules={[{ required: true, message: 'Informe o nome.' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Cargo"
                  name="political_exposed_position"
                  rules={[{ required: true, message: 'Informe o cargo.' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Orgão do Governo"
                  name="political_exposed_government_agency"
                  rules={[
                    {
                      required: true,
                      message: 'Informe o orgão do governo.'
                    }
                  ]}
                >
                  <Input />
                </Form.Item>
              </>
            )}
          </>
        )}
        {!!pfOrPj && (
          <Form.Item wrapperCol={{ span: 10 }}>
            <Button type="primary" htmlType="submit">
              Próxima Etapa
            </Button>
          </Form.Item>
        )}
      </Form>
      {modalPlan && (
        <ModalPlans
          isOpen={modalPlan}
          onClose={toggleModalPlan}
          plan={plan}
          title="Tabela de taxas do plano:"
        />
      )}
    </div>
  )
}
