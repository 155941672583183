import { useCallback, useMemo, useState } from 'react'
import { Button, Form, Input, Modal, Switch, Table, message } from 'antd'
import { maskCpfCnpj } from '../../../../../utils/masks'
import * as moment from 'moment'
import { getMessagesByRisk } from '../..'
import { RegraRisk } from '../../../../../utils/RegraRisk'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { api } from '../../../../../providers/api'

import { ContainerFilter, InputContainer } from './component'

export function AlteracaoVolumeTransacoesDiarioTransaction({
  isActive,
  data,
  refresh
}) {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [searchMCC, setSearchMCC] = useState('')
  const [searchCNPJ, setSearchCNPJ] = useState('')
  const [searchNomeCNPJ, setSearchNomeCNPJ] = useState('')
  const [formEdit] = Form.useForm(null)
  const [ecTransactionSelect, setEcTransactionSelect] = useState(null)
  const [messageApi, contextHolder] = message.useMessage()

  const dados = useMemo(() => {
    return getMessagesByRisk(data, [RegraRisk.AlteracaoVolumeDiarioMCCEc])
  }, [data])

  const toggleModal = useCallback(() => {
    setIsOpenModal(oldValue => !oldValue)
  }, [])

  const toggleFormModal = useCallback(() => {
    formEdit.resetFields()
    toggleModal()
  }, [formEdit, toggleModal])

  const sendForm = useCallback(
    async dataAnalysis => {
      try {
        await api.put(
          `/risk-analysis/ec-transactions/${ecTransactionSelect.id}`,
          dataAnalysis
        )

        messageApi.open({
          type: 'success',
          content: 'O registro foi atualizado com sucesso',
          duration: 2,
          onClose: toggleFormModal
        })
        refresh()
      } catch (err) {
        messageApi.open({
          type: 'error',
          content: 'Tivemos um erro inesperado, tente novamente',
          duration: 2
        })
      }
    },
    [messageApi, ecTransactionSelect, refresh, toggleFormModal]
  )

  const onSubmit = useCallback(
    async dados => {
      try {
        const dataAnalysis = {
          ...dados,
          _id: undefined,
          bloquearEc: false,
          isVerify: true
        }
        if (dados.bloquearEc) {
          Modal.confirm({
            title: `Tem certeza de que irá bloquear a EC?`,
            icon: <ExclamationCircleFilled />,
            okText: 'Sim',
            okType: 'danger',
            cancelText: 'Não',
            async onOk() {
              await sendForm({
                ...dataAnalysis,
                bloquearEc: true
              })
            },
            async onCancel() {
              await sendForm(dataAnalysis)
            }
          })
        } else {
          await sendForm(dataAnalysis)
        }
      } catch (err) {
        console.log(err)
      }
    },
    [sendForm]
  )

  return (
    <>
      {isActive && (
        <div>
          <ContainerFilter>
            <InputContainer>
              <h4>CNPJ:</h4>
              <Input.Search
                placeholder="Procurar..."
                value={searchCNPJ}
                onSearch={value => {
                  setSearchCNPJ(value)
                }}
                onChange={e => setSearchCNPJ(e.target.value)}
                style={{ maxWidth: '300px', marginBottom: '20px' }}
              />
            </InputContainer>
            <InputContainer>
              <h4>Loja:</h4>
              <Input.Search
                placeholder="Procurar..."
                value={searchNomeCNPJ}
                onSearch={value => {
                  setSearchNomeCNPJ(value)
                }}
                onChange={e => setSearchNomeCNPJ(e.target.value)}
                style={{ maxWidth: '300px', marginBottom: '20px' }}
              />
            </InputContainer>
            <InputContainer>
              <h4>MCC:</h4>
              <Input.Search
                placeholder="Procurar..."
                value={searchMCC}
                onSearch={value => {
                  setSearchMCC(value)
                }}
                onChange={e => setSearchMCC(e.target.value)}
                style={{ maxWidth: '300px', marginBottom: '20px' }}
              />
            </InputContainer>
          </ContainerFilter>
          <Table
            pagination={{ simple: true, position: ['topRight'], pageSize: 10 }}
            bordered
            rowClassName={() => 'editable-row'}
            dataSource={dados}
            rowKey="id"
            columns={tableColumns({
              toggleModal,
              setEcTransactionSelect,
              isOpenModal,
              formEdit,
              onSubmit,
              searchMCC,
              searchCNPJ,
              searchNomeCNPJ
            })}
            expandable={{
              expandedRowRender: record => (
                <div>
                  <h2>Motivos</h2>
                  <ul>
                    {record.transactions.map((transaction, index) => (
                      <li key={index}>
                        <p>{transaction.ruleDescription}</p>
                      </li>
                    ))}
                  </ul>
                  <h2>Volume diário informado pela EC</h2>
                  <ul>
                    {record.transactions.map((transaction, index) => (
                      <li key={index}>
                        <p>{transaction?.dados?.volumeTransactionsDays || 0}</p>
                      </li>
                    ))}
                  </ul>
                  <h2>Quantidade de volume de transações diárias</h2>
                  <ul>
                    {record.transactions.map((transaction, index) => (
                      <li key={index}>
                        <p>
                          {Math.ceil(
                            transaction?.dados
                              ?.qtdVolumeMedioTransacoesDiarias || 0
                          )}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              )
            }}
            locale={{ emptyText: 'Nenhuma EC se enquadrou nesta regra.' }}
          />
          {contextHolder}
        </div>
      )}
    </>
  )
}

function filter(value, search = '') {
  return String(value).toLowerCase().includes(search.toLowerCase())
}

const tableColumns = ({
  toggleModal,
  setEcTransactionSelect,
  isOpenModal,
  formEdit,
  onSubmit,
  searchMCC,
  searchCNPJ,
  searchNomeCNPJ
}) => [
  {
    title: 'CNPJ',
    dataIndex: 'customerId',
    responsive: ['sm'],
    filteredValue: [searchCNPJ],
    onFilter: (value, record) => {
      return filter(record.customerId, value.replace(/\D/g, ''))
    },
    render: (_, record) => {
      return record.customerId ? maskCpfCnpj(record.customerId) : '-'
    }
  },
  {
    title: 'Loja',
    dataIndex: 'companyName',
    responsive: ['sm'],
    filteredValue: [searchNomeCNPJ],
    onFilter: (value, record) => {
      return filter(record.companyName, value)
    },
    render: (_, record) => {
      return record.companyName || '-'
    }
  },
  {
    title: 'MCC',
    dataIndex: 'mcc',
    responsive: ['sm'],
    filteredValue: [searchMCC],
    onFilter: (value, record) => {
      if (!searchMCC) return true
      return filter(record.mcc, value)
    },
    render: (_, record) => {
      return record.mcc || '-'
    }
  },
  {
    title: 'Status',
    dataIndex: 'status',
    responsive: ['lg'],
    render: (_, record) => {
      return record.status || '-'
    }
  },
  {
    title: 'Data',
    dataIndex: 'dataInclusao',
    render: (_, record) => {
      return moment(record.dataInclusao).format('DD/MM/YYYY HH:mm') || '-'
    }
  },
  {
    title: 'Ação',
    dataIndex: 'id',
    render: (_, record) => {
      return (
        <>
          <Button
            type="primary"
            ghost
            onClick={() => {
              toggleModal()
              setEcTransactionSelect(record)
            }}
          >
            Aplicar ação
          </Button>
          <Modal
            forceRender
            open={isOpenModal}
            title="Editar parâmetros de EC"
            onOk={formEdit.submit}
            onCancel={toggleModal}
          >
            <Form style={{ width: '100%' }} form={formEdit} onFinish={onSubmit}>
              <Form.Item
                label="Excessão"
                name="excessao"
                style={{ marginBottom: '5px' }}
              >
                <Switch />
              </Form.Item>
              <Form.Item
                label="Contato com EC"
                name="contactEc"
                style={{ marginBottom: '5px' }}
              >
                <Switch />
              </Form.Item>
              <Form.Item
                label="Evidencia recebida"
                name="evidenciaRecebida"
                style={{ marginBottom: '5px' }}
              >
                <Switch />
              </Form.Item>
              <Form.Item
                label="Comprovado"
                name="naoComprovado"
                style={{ marginBottom: '5px' }}
              >
                <Switch />
              </Form.Item>
              <Form.Item
                label="Bloquear EC"
                name="bloquearEc"
                style={{ marginBottom: '5px' }}
              >
                <Switch />
              </Form.Item>
              <Form.Item
                label="Observações"
                name="observacoes"
                style={{ marginBottom: '5px' }}
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input maxLength={50} />
              </Form.Item>
            </Form>
          </Modal>
        </>
      )
    }
  }
]
