import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { MdEdit, MdLock } from 'react-icons/md'
import { AiOutlineMenu } from 'react-icons/ai'
import { IoMdUnlock } from 'react-icons/io'
import { Dropdown, Form, Input, Modal, Space, Switch, message } from 'antd'
import cnaesServices from '../../../services/cnaes.services'

import { ExclamationCircleFilled } from '@ant-design/icons'

const CnaeActionMenu = ({ cnae, refresh }) => {
  const [formEdit] = Form.useForm(null)
  const [cnaeEdit, setCnaeEdit] = useState(cnae)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [messageInfo, setMessageInfo] = useState(null)

  const [messageApi, contextHolder] = message.useMessage()

  const closeEditModal = useCallback(() => {
    setIsModalOpen(false)
    formEdit.resetFields()
  }, [formEdit])

  const openEditModal = useCallback(() => {
    setIsModalOpen(true)
  }, [])

  const success = useCallback(() => {
    messageApi.open({
      type: 'success',
      content: messageInfo,
      duration: 2,
      onClose: closeEditModal
    })
  }, [messageApi, messageInfo, closeEditModal])

  const sendEditCnae = useCallback(async () => {
    try {
      await cnaesServices().edit(cnaeEdit)
      setMessageInfo(`Cnae alterado com sucesso!`)
      formEdit.resetFields()
      await refresh?.()
    } catch (err) {
      setMessageInfo(`Ocorreu um erro ao alterar o Cnae, tente novamente!`)
    } finally {
      setMessageInfo('')
    }
  }, [formEdit, refresh, cnaeEdit])

  useEffect(() => {
    if (messageInfo) {
      success()
    }
  }, [messageInfo, success])

  const handleChange = useCallback(
    (item, value) => {
      formEdit.setFieldValue(item, value)
      setCnaeEdit(oldValue => ({
        ...oldValue,
        [item]: value
      }))
    },
    [formEdit]
  )

  const changeStatusCNAE = useCallback(() => {
    const inativadoValue = !cnaeEdit.inativado
    Modal.confirm({
      title: `Deseja ${inativadoValue ? 'inativar' : 'habilitar'} este CNAE?`,
      icon: <ExclamationCircleFilled />,
      content: `Ao confirmar, irá ${
        inativadoValue ? 'inativar' : 'habilitar'
      } este CNAE.`,
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Não',
      async onOk() {
        try {
          handleChange('inativado', inativadoValue)
          formEdit.setFieldValue('inativado', inativadoValue)
          await cnaesServices().inativar({
            _id: cnaeEdit._id,
            inativado: inativadoValue
          })
          await refresh?.()
        } catch (err) {
          setMessageInfo(
            `Ocorreu um erro ao inativar/ativar o CNAE, tente novamente!`
          )
        } finally {
          setMessageInfo('')
        }
      }
    })
  }, [cnaeEdit._id, cnaeEdit.inativado, formEdit, handleChange, refresh])

  const items = useMemo(
    () => [
      {
        label: (
          <Space style={{ cursor: 'pointer' }} onClick={() => openEditModal()}>
            <MdEdit size={15} />
            Editar
          </Space>
        ),
        key: '0'
      },
      {
        type: 'divider'
      },
      {
        label: (
          <Space style={{ cursor: 'pointer' }} onClick={changeStatusCNAE}>
            {cnae.inativado ? (
              <>
                <IoMdUnlock size={15} />
                Habilitar
              </>
            ) : (
              <>
                <MdLock size={15} />
                Inativar
              </>
            )}
          </Space>
        ),
        key: '1'
      }
    ],
    [changeStatusCNAE, cnae.inativado, openEditModal]
  )

  return (
    <>
      <Dropdown menu={{ items }} trigger={['click']} placement="">
        <AiOutlineMenu style={{ cursor: 'pointer' }} size={24} />
      </Dropdown>
      <Modal
        forceRender
        title="Editar Cnae"
        open={isModalOpen}
        onOk={formEdit.submit}
        onCancel={closeEditModal}
      >
        <Form form={formEdit} onFinish={sendEditCnae} initialValues={cnaeEdit}>
          <Form.Item label="Cnae" name="cnae">
            <Input
              maxLength={7}
              onChange={e => handleChange('cnae', e.target.value)}
            />
          </Form.Item>

          <Form.Item label="MCC" name="mcc">
            <Input
              maxLength={4}
              onChange={e => handleChange('mcc', e.target.value)}
            />
          </Form.Item>

          <Form.Item label="Nome MCC" name="nomeMCC">
            <Input onChange={e => handleChange('nomeMCC', e.target.value)} />
          </Form.Item>

          <Form.Item label="Categoria de Risco" name="riskCategory">
            <Input
              onChange={e => handleChange('riskCategory', e.target.value)}
            />
          </Form.Item>

          <Form.Item label="Grupo" name="grupo">
            <Input onChange={e => handleChange('grupo', e.target.value)} />
          </Form.Item>

          <Form.Item label="Sub-Grupo" name="subGrupo">
            <Input onChange={e => handleChange('subGrupo', e.target.value)} />
          </Form.Item>

          <Form.Item label="Cartão Não Presente" name="cartaoNaoPresente">
            <Switch
              checked={cnaeEdit.cartaoNaoPresente}
              onChange={value => handleChange('cartaoNaoPresente', value)}
            />
          </Form.Item>

          <Form.Item label="Máquina Presencial" name="maquinaPresencial">
            <Switch
              checked={cnaeEdit.maquinaPresencial}
              onChange={value => handleChange('maquinaPresencial', value)}
            />
          </Form.Item>

          <Form.Item label="Compra Online" name="compraOnline">
            <Switch
              checked={cnaeEdit.compraOnline}
              onChange={value => handleChange('compraOnline', value)}
            />
          </Form.Item>
        </Form>
      </Modal>
      {contextHolder}
    </>
  )
}

export default CnaeActionMenu
