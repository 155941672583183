import React, { useState } from 'react'
import { Button, Checkbox, Col, Form, Radio, Row, TimePicker } from 'antd'
import { PersonalInfo } from '../01-personal-info/index'
import Terms from '../14-proposal-terms-acceptance/index'
import dayjs from 'dayjs'
import AppLayout from '../../components/app-layout/app-layout'

const WorkingHours = props => {
  const { previousStepData } = props
  const formInitialValues = {
    working_hours_week_days: ['', '', '', '', ''],
    working_hours_week_days_start: '',
    working_hours_week_days_end: '',
    working_hours_weekend_days: ['', ''],
    working_hours_weekend_days_start: '',
    working_hours_weekend_days_end: '',
    working_hours_close_for_lunch: null
  }

  const [nextSteps, setNextStep] = useState(false)
  const [prevStep, setPrevStep] = useState(false)
  const [formData, setFormData] = useState({})
  const [form] = Form.useForm()
  const [weekendHour, setWeekendHour] = useState(false)

  const handleOnFinish = data => {
    const workingHours = []

    data.working_hours_week_days_start = dayjs(
      data.working_hours_week_days_start
    ).format('HH:mm')
    data.working_hours_week_days_end = dayjs(
      data.working_hours_week_days_end
    ).format('HH:mm')
    data.working_hours_weekend_days_end = dayjs(
      data.working_hours_weekend_days_end
    ).format('HH:mm')
    data.working_hours_weekend_days_start = dayjs(
      data.working_hours_weekend_days_start
    ).format('HH:mm')

    data.working_hours_week_days.forEach(day => {
      if (day !== '') {
        workingHours.push({
          from: data.working_hours_week_days_start,
          to: data.working_hours_week_days_end,
          weekDay: day
        })
      }
    })

    data.working_hours_weekend_days.forEach(day => {
      if (day !== '') {
        workingHours.push({
          from: data.working_hours_weekend_days_start,
          to: data.working_hours_weekend_days_end,
          weekDay: day
        })
      }
    })

    const payload = {
      ...previousStepData,
      workingDays: workingHours
    }

    setFormData(payload)
    setNextStep(true)
  }

  const handleValueChange = teste => {
    if (teste.working_hours_weekend_days) {
      if (teste.working_hours_weekend_days.length > 0) {
        setWeekendHour(true)
      } else {
        setWeekendHour(false)
      }
    }
  }

  const allWeekDaysChange = e => {
    const currentFormInitialValues = form.getFieldsValue()
    if (e.target.checked) {
      currentFormInitialValues.working_hours_week_days = [
        'THURSDAY',
        'MONDAY',
        'TUESDAY',
        'FRIDAY',
        'WEDNESDAY'
      ]
    } else {
      currentFormInitialValues.working_hours_week_days = []
    }

    form.setFieldsValue(currentFormInitialValues)
  }

  return (
    <>
      {!nextSteps && !prevStep && (
        <AppLayout>
          <p className="title-page-info"> Horário de Funcionamento</p>
          <div className="form-container">
            <Form
              useRef
              form={form}
              name="basic"
              style={{ width: 800 }}
              initialValues={formInitialValues}
              onFinish={handleOnFinish}
              onValuesChange={handleValueChange}
            >
              <Form.Item name="all_week_days" label="Dias Úteis">
                <Checkbox
                  onChange={allWeekDaysChange}
                  style={{ lineHeight: '32px' }}
                >
                  Todos
                </Checkbox>
              </Form.Item>
              <Form.Item
                name="working_hours_week_days"
                label=" "
                rules={[{ required: true, message: 'Informe os dias úteis' }]}
              >
                <Checkbox.Group>
                  <Row>
                    <Col span={8}>
                      <Checkbox value="MONDAY" style={{ lineHeight: '32px' }}>
                        Segunda-Feira
                      </Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox value="TUESDAY" style={{ lineHeight: '32px' }}>
                        Terça-Feira
                      </Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox
                        value="WEDNESDAY"
                        style={{ lineHeight: '32px' }}
                      >
                        Quarta-Feira
                      </Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox value="THURSDAY" style={{ lineHeight: '32px' }}>
                        Quinta-Feira
                      </Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox value="FRIDAY" style={{ lineHeight: '32px' }}>
                        Sexta-Feira
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </Form.Item>
              <Row>
                <Col>
                  <Form.Item
                    name="working_hours_week_days_start"
                    label="Abre às"
                    rules={[
                      {
                        required: true,
                        message: 'Informe o inicio do horário de funcionamento'
                      }
                    ]}
                  >
                    <TimePicker format="HH:mm" />
                  </Form.Item>
                </Col>
                <Col offset={2}>
                  <Form.Item
                    name="working_hours_week_days_end"
                    label="Fecha às"
                    rules={[
                      {
                        required: true,
                        message: 'Informe o final do horário de funcionamento'
                      }
                    ]}
                  >
                    <TimePicker format="HH:mm" />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                name="working_hours_weekend_days"
                label="Final de semana"
              >
                <Checkbox.Group>
                  <Row>
                    <Col>
                      <Checkbox value="SATURDAY" style={{ lineHeight: '32px' }}>
                        Sábado
                      </Checkbox>
                    </Col>
                    <Col>
                      <Checkbox value="SUNDAY" style={{ lineHeight: '32px' }}>
                        Domingo
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </Form.Item>
              <Row>
                <Col>
                  <Form.Item
                    name="working_hours_weekend_days_start"
                    label="Abre às"
                    rules={[
                      {
                        required: weekendHour,
                        message: 'Informe o inicio do horário de funcionamento'
                      }
                    ]}
                  >
                    <TimePicker format="HH:mm" />
                  </Form.Item>
                </Col>
                <Col offset={2}>
                  <Form.Item
                    name="working_hours_weekend_days_end"
                    label="Fecha às"
                    rules={[
                      {
                        required: weekendHour,
                        message: 'Informe o final do horário de funcionamento'
                      }
                    ]}
                  >
                    <TimePicker format="HH:mm" />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label="Fecha para almoço?"
                name="working_hours_close_for_lunch"
                rules={[
                  { required: true, message: 'Informe se fecha para o almoço' }
                ]}
              >
                <Radio.Group>
                  <Radio value={true}>Não</Radio>
                  <Radio value={false}>Sim</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Próxima Etapa
                </Button>
              </Form.Item>

              <Form.Item>
                <Button danger onClick={() => setPrevStep(true)}>
                  cancelar
                </Button>
              </Form.Item>
            </Form>
          </div>
        </AppLayout>
      )}
      {nextSteps && <Terms previousStepData={formData} />}

      {prevStep && <PersonalInfo formDataPreLoaded={previousStepData} />}
    </>
  )
}

export default WorkingHours
