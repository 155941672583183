import styled, { css } from 'styled-components'

export const Container = styled.div`
  .plan-details-step1 {
    cursor: pointer;
    margin-top: -20px;
    text-align: center;

    &:hover {
      color: #007bff;
    }
  }
`

export const Header = styled.div`
  display: flex;
  gap: 5px;
  margin-top: 16px;
`

export const Content = styled.div``

export const StepContainerCard = styled.div`
  display: flex;
  margin-bottom: 2rem;
  justify-content: center;
  width: 100%;
`

export const CardsWrapper = styled.div`
  display: flex;
  border: 2px solid #cfcfcf;
`

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const StepCard = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  cursor: pointer;
  padding: 8px 40px;
  background-color: #fff;
  position: relative;
  border-right: 2px solid #cfcfcf;

  p {
    margin: 0;
    font-size: 0.8rem;
    font-weight: 600;
  }

  small {
    padding-top: 4px;
    font-size: 0.6rem;
  }

  span:after,
  span:before {
    content: '';
    display: block;
    position: absolute;
    left: 100%;
    width: 0;
    height: 0;
    border-style: solid;
    z-index: 2;
  }

  span:after {
    top: 30%;
    border-color: transparent transparent transparent #fff;
    border-width: 10px;
  }

  span:before {
    top: 23.5%;
    border-color: transparent transparent transparent #cfcfcf;
    border-width: 13px;
  }

  &:last-child {
    border-right: none;

    span:after,
    span:before {
      border: none;
    }
  }

  ${props =>
    props.isActive
      ? css`
          background-color: #e7e7e7;
          span:after {
            border-color: transparent transparent transparent #e7e7e7;
          }
          p {
            color: #1a73e8;
          }
        `
      : css`
          svg,
          p,
          small {
            opacity: 0.6;
            filter: grayscale(1);
          }
        `}
`

export const AddressTypeContainer = styled.div`
  padding: 5em;
  border-radius: 2em;
  background-color: white;
  -webkit-box-shadow: 0px 3px 16px -1px rgba(209, 209, 209, 1);
  -moz-box-shadow: 0px 3px 16px -1px rgba(209, 209, 209, 1);
  box-shadow: 0px 3px 16px -1px rgba(209, 209, 209, 1);
  margin-bottom: 2em;
  margin-top: 2em;
`

export const AddressTypeContainerTitle = styled.div`
  margin-bottom: 2em;
  font-size: 12pt;
  color: #737272;
`
