import { useEffect, useMemo, useState } from 'react'
import { Input, Select, Table, message } from 'antd'
import { maskCpfCnpj } from '../../../../../utils/masks'
import * as moment from 'moment'
import { getMessagesByRisk } from '../..'
import { RegraRisk } from '../../../../../utils/RegraRisk'
import {
  Container,
  ContainerFilter,
  ContainerTotalizador,
  InputContainer
} from './component'

export function VolumePorModoEntradaTransaction({ isActive, data }) {
  const [modoTipo, setModoTipo] = useState(null)
  const [cnpjFiltro, setCnpjFiltro] = useState('')
  const [nomeCnpjFiltro, setNomeCnpjFiltro] = useState('')
  const [mccFiltro, setMccFiltro] = useState('')
  const [, contextHolder] = message.useMessage()

  const dados = useMemo(() => {
    return getMessagesByRisk(data, [RegraRisk.AlteracaoVolumeMCCEc])
  }, [data])

  const [totalPorModo, setTotalPorModo] = useState(() => {
    return (dados || [])
      .map(data => data?.transactions || [])
      .map(t => t.transaction?.entry_mode)
  })

  useEffect(() => {
    if (modoTipo) {
      const entryModeCache = {}
      const entryMode = dados
        .reduce(
          (_, dado) => dado.transactions.map(tr => tr.transaction.entry_mode),
          []
        )
        .filter(item => {
          if (!entryModeCache[item] && modoTipo === item) {
            entryModeCache[item] = true
            return true
          }
          return false
        })

      const total = entryMode.length || 0
      setTotalPorModo(total)
    }
  }, [modoTipo, dados])

  return (
    <>
      {isActive && (
        <Container>
          <ContainerTotalizador>
            <p>Transações totais por modo de entrada: {totalPorModo}</p>
          </ContainerTotalizador>
          <ContainerFilter>
            <InputContainer>
              <h4>Modo de entrada:</h4>
              <Select
                style={{ width: 150 }}
                defaultValue={{
                  label: 'Selecione modo',
                  value: null
                }}
                onChange={setModoTipo}
                options={[
                  {
                    label: 'Selecione modo',
                    value: null
                  },
                  {
                    value: 'chip',
                    label: 'Chip'
                  },
                  {
                    value: 'tarja',
                    label: 'Tarja'
                  },
                  {
                    value: 'fallback',
                    label: 'Fallback'
                  },
                  {
                    value: 'nfc',
                    label: 'NFC'
                  }
                ]}
              />
            </InputContainer>
            <InputContainer>
              <h4>CNPJ:</h4>
              <Input.Search
                placeholder="Procurar..."
                value={cnpjFiltro}
                onSearch={value => {
                  setCnpjFiltro(value)
                }}
                onChange={e => setCnpjFiltro(e.target.value)}
                style={{ maxWidth: '300px', marginBottom: '20px' }}
              />
            </InputContainer>
            <InputContainer>
              <h4>Loja:</h4>
              <Input.Search
                placeholder="Procurar..."
                value={nomeCnpjFiltro}
                onSearch={value => {
                  setNomeCnpjFiltro(value)
                }}
                onChange={e => setNomeCnpjFiltro(e.target.value)}
                style={{ maxWidth: '300px', marginBottom: '20px' }}
              />
            </InputContainer>
            <InputContainer>
              <h4>MCC:</h4>
              <Input.Search
                placeholder="Procurar..."
                value={mccFiltro}
                onSearch={value => {
                  setMccFiltro(value)
                }}
                onChange={e => setMccFiltro(e.target.value)}
                style={{ maxWidth: '300px', marginBottom: '20px' }}
              />
            </InputContainer>
          </ContainerFilter>

          <Table
            pagination={{ simple: true, position: ['topRight'], pageSize: 10 }}
            bordered
            rowClassName={() => 'editable-row'}
            dataSource={dados}
            rowKey="id"
            columns={tableColumns({
              modoTipo,
              cnpjFiltro,
              nomeCnpjFiltro,
              mccFiltro
            })}
            expandable={{
              expandedRowRender: record => (
                <div>
                  <h2>Modos de entrada</h2>
                  <ul>
                    {record.transactions.map((transaction, index) => (
                      <li key={index}>
                        <p>{transaction.dados?.entryModes || '-'}</p>
                      </li>
                    ))}
                  </ul>
                  <h2>Motivos</h2>
                  <ul>
                    {record.transactions.map((transaction, index) => (
                      <li key={index}>
                        <p>{transaction.ruleDescription}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              )
            }}
            locale={{ emptyText: 'Nenhuma EC se enquadrou nesta regra.' }}
          />
          {contextHolder}
        </Container>
      )}
    </>
  )
}

function filter(value, search) {
  return value.toLowerCase().includes(search.toLowerCase())
}

const tableColumns = ({ modoTipo, cnpjFiltro, nomeCnpjFiltro, mccFiltro }) => [
  {
    title: 'CNPJ',
    dataIndex: 'customerId',
    responsive: ['sm'],
    filteredValue: [cnpjFiltro],
    onFilter: (_, record) => {
      if (!cnpjFiltro.length) {
        return true
      }
      return filter(record.customerId, cnpjFiltro.replace(/\D/g, ''))
    },
    render: (_, record) => {
      return record.customerId ? maskCpfCnpj(record.customerId) : '-'
    }
  },
  {
    title: 'Loja',
    dataIndex: 'companyName',
    responsive: ['sm'],
    filteredValue: [nomeCnpjFiltro],
    onFilter: (_, record) => {
      if (!nomeCnpjFiltro.length) {
        return true
      }

      return filter(record.companyName, nomeCnpjFiltro)
    },
    render: (_, record) => {
      return record.companyName || '-'
    }
  },
  {
    title: 'MCC',
    dataIndex: 'mcc',
    responsive: ['sm'],
    filteredValue: [mccFiltro],
    onFilter: (_, record) => {
      if (!mccFiltro.length && !modoTipo) {
        return true
      }

      const entryModes = (record?.transactions || []).reduce((acc, tr) => {
        const splitEntry = tr.dados.entryModes.split(' - ')
        if (!acc.every(value => splitEntry.includes(value))) {
          acc = acc.concat(splitEntry)
        }
        return acc
      }, [])

      return (
        filter(record.mcc, mccFiltro) ||
        !!(modoTipo && entryModes.includes(modoTipo))
      )
    },
    render: (_, record) => {
      return record.mcc || '-'
    }
  },
  {
    title: 'Status',
    dataIndex: 'status',
    responsive: ['lg'],
    render: (_, record) => {
      return record.status || '-'
    }
  },
  {
    title: 'Data',
    dataIndex: 'dataInclusao',
    render: (_, record) => {
      return moment(record.dataInclusao).format('DD/MM/YYYY HH:mm') || '-'
    }
  }
]
