import usSwr from 'swr'
import { RiskTransaction } from './riskTransaction'
import { useState } from 'react'
import {
  Container,
  HeaderRiskTransaction,
  ItemRiskTransaction
} from './component'

export function VerificationTransaction() {
  const {
    data = [],
    mutate,
    isLoading,
    isValidating
  } = usSwr(`/risk-analysis/ec-transactions`)
  const [isActiveAba, setIsActiveAba] = useState('ticket-medio')

  const abas = [
    {
      key: 'ticket-medio',
      label: 'Ticket médio'
    },
    {
      key: 'volume-por-modo-entrada',
      label: 'Volume por modo de entrada'
    },
    {
      key: 'alteracao-volume-transacoes-diario',
      label: 'Alterações de volume de transações diárias'
    },
    {
      key: 'alteracao-volume-valor-diario',
      label: 'Alteração de volume em valor diário'
    },
    {
      key: 'alto-indice-transacoes',
      label: 'Alto índice de transações negadas'
    },
    {
      key: 'transacoes-fora-horario-padrao',
      label: 'Transações fora do horário padrão'
    },
    {
      key: 'indice-charge-back',
      label: 'Índice de Chargeback'
    },
    {
      key: 'indice-cancelamento',
      label: 'Índice de cancelamento'
    },
    {
      key: 'indice-repeticao-transacoes-mesmo-portador-cartao',
      label: 'Índice de repetição de transações mesmo portador de cartão'
    },
    {
      key: 'produto-luxo',
      label: 'Produto de luxo'
    },
    {
      key: 'blacklist',
      label: 'blacklist'
    }
  ]

  return (
    <Container>
      <HeaderRiskTransaction>
        {abas.map(aba => (
          <ItemRiskTransaction
            onClick={() => setIsActiveAba(aba.key)}
            key={aba.key}
            isActive={isActiveAba === aba.key}
          >
            {aba.label}
          </ItemRiskTransaction>
        ))}
      </HeaderRiskTransaction>
      <RiskTransaction
        data={data}
        isLoading={isLoading || isValidating}
        refresh={mutate}
        switchRisk={isActiveAba}
      />
    </Container>
  )
}

export function getMessagesByRisk(data, risks) {
  const result = data.reduce((acc, item) => {
    const transactionsFiltered = item.transactions.filter(transaction =>
      risks.includes(transaction.ruleId)
    )

    if (transactionsFiltered.length) {
      acc.push({
        ...item,
        transactions: getMessagesTransactions(transactionsFiltered)
      })
    }

    return acc
  }, [])

  return result
}

function getMessagesTransactions(transactions) {
  const messages = []

  transactions.forEach(transaction => {
    if (
      !messages.some(m => m.ruleDescription === transaction.ruleDescription)
    ) {
      messages.push({
        ...transaction,
        dados: JSON.parse(transaction.dados || '{}')
      })
    }
  })

  return messages
}
