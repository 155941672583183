import React from 'react'
import AppLayout from '../../components/app-layout/app-layout'
import { VerificationTransaction } from './VerificationTransaction'

const RiskTransactions = () => {
  return (
    <AppLayout
      openKeys={['risk-transactions']}
      currentOption="risk-transactions"
    >
      <h2>Análise de risco de transações</h2>

      <VerificationTransaction />
    </AppLayout>
  )
}

export default RiskTransactions
