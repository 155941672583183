import React from 'react'

import { Container, Item } from './component'

const ExpandedRowCnae = ({ cnae }) => {
  return (
    <Container>
      <Item>
        <p>Categoria de risco</p>
        <span>{cnae.riskCategory || '-'}</span>
      </Item>

      <Item>
        <p>MCC(núm)</p>
        <span>{cnae.mcc || '-'}</span>
      </Item>

      <Item>
        <p>Nome MCC</p>
        <span>{cnae.nomeMCC || '-'}</span>
      </Item>

      <Item>
        <p>Grupo</p>
        <span>{cnae.grupo || '-'}</span>
      </Item>

      <Item>
        <p>Sub-Grupo</p>
        <span>{cnae.subGrupo || '-'}</span>
      </Item>

      <Item>
        <p>Cartão não presente</p>
        <span>{cnae.cartaoNaoPresente ? 'Sim' : 'Não'}</span>
      </Item>

      <Item>
        <p>Máquina presencial</p>
        <span>{cnae.maquinaPresencial ? 'Sim' : 'Não'}</span>
      </Item>

      <Item>
        <p>Compra online</p>
        <span>{cnae.compraOnline ? 'Sim' : 'Não'}</span>
      </Item>
    </Container>
  )
}

export default ExpandedRowCnae
