import React from 'react'
import {
  Button,
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  Overlay
} from '../styles/ModalPlan.style'
import { TabelaPlanos } from './TabelaPlanos'

const ModalPlans = ({ onClose, title, plan }) => {
  return (
    <Overlay onClick={onClose}>
      <ModalContainer onClick={e => e.stopPropagation()}>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          Taxas loja física:
          <TabelaPlanos values={plan} isEcommerce={false} />
          Taxas loja virtual:
          <TabelaPlanos values={plan} />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Fechar</Button>
        </ModalFooter>
      </ModalContainer>
    </Overlay>
  )
}

export default ModalPlans
