import React, { useEffect, useState } from 'react'
import { Button, DatePicker, Form, Input, Radio, Select } from 'antd'
import { PersonalInfo } from '../01-personal-info/index'
import FeeEdit from '../06-fee-edit/index'
import { maskCurrency } from '../../utils/masks'
import dayjs from 'dayjs'

const OfferDataStepFive = props => {
  // TODO: fazer validação do valor minimo e da data de emissão de no maximo 90 dias.

  const { previousStepData } = props

  const [nextStepsLocal, setNextStepLocal] = useState(false)
  const [nextSteps, setNextStep] = useState(false)
  const [prevStep, setPrevStep] = useState(false)
  const [invoices, setInvoices] = useState(['1'])
  const [formData, setFormData] = useState({})

  const [pfOrPj, setPfOrPj] = useState('pj')
  const [activeOption, setActiveOption] = useState(null)
  const [addInvoiceButton, setAddInvoiceButton] = useState(false)
  const [formPf] = Form.useForm()
  // const [formPj] = Form.useForm();

  const [Invoiceform] = Form.useForm()
  const [businessLicenseForm] = Form.useForm()
  const [professionalRecordForm] = Form.useForm()
  // const [receiptDeadline, setReceiptDeadline] = useState(false);

  const formMaskFunctions = {
    invoice_value: value => maskCurrency(value)
  }

  const handleAddInvoice = () => {
    const currentInvoices = [...invoices]

    if (currentInvoices.length + 2 > 3) {
      setAddInvoiceButton(true)
    }

    const lastItem = invoices[invoices.length - 1]

    currentInvoices.push(String(Number(lastItem) + 1))

    setInvoices(currentInvoices)
  }

  const handleRemoveInvoice = invoice => {
    const currentInvoices = [...invoices]
    const currentFormInitialValues = Invoiceform.getFieldsValue()

    const findIndex = currentInvoices.findIndex(element => element === invoice)

    currentInvoices.splice(findIndex, 1)

    Invoiceform.setFieldsValue(currentFormInitialValues)

    if (currentInvoices.length <= 3) {
      setAddInvoiceButton(false)
    }
    setInvoices(currentInvoices)
  }

  const handleInvoiceFinish = data => {
    const localInvoices = []

    for (let i = 1; i <= invoices.length; i++) {
      localInvoices.push({
        invoice_number: data[`invoice_number_${i}`],
        product_type: data[`product_type_${i}`],
        emission_date: dayjs(data[`emission_date_${i}`]).format('DD/MM/YYYY'),
        invoice_value: data[`invoice_value_${i}`]
      })
    }

    const payload = {
      ...previousStepData,
      step_five: localInvoices
    }

    setFormData(payload)
    setActiveOption(null)
    setPfOrPj(null)
    setNextStep(true)
  }

  const handleOnFinish = data => {
    const payload = {
      ...previousStepData
    }

    payload.step_five = data
    setFormData(payload)
    setNextStep(true)
    setActiveOption(null)
    setPfOrPj(null)
  }

  const handleInvoiceFormChanges = inputChanged => {
    const currentFormInitialValues = Invoiceform.getFieldsValue()

    const inputName = Object.keys(inputChanged)[0]
    const splited = inputName.split('_')
    const inputNameSplited = `${splited[0]}_${splited[1]}`

    if (formMaskFunctions[inputNameSplited]) {
      currentFormInitialValues[inputName] = formMaskFunctions[inputNameSplited](
        inputChanged[inputName]
      )
      Invoiceform.setFieldsValue(currentFormInitialValues)
    }
  }

  useEffect(() => {
    setPfOrPj('pf')
    setNextStepLocal(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {pfOrPj === 'pf' && !activeOption && !nextStepsLocal && (
        <div>
          <header
            className="portal-header"
            style={{
              marginBottom: '2em',
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center'
            }}
          >
            <img
              style={{ height: '50px' }}
              alt="x-payLogo"
              src={require('../../assets/img/logo.png')}
            ></img>
            <div style={{ fontSize: '16pt' }}>Portal de Credenciamento</div>
          </header>
          <p className="title-page-info"> Oferta</p>
          <p className="title-page-info"> Selecionar tipo de registro</p>
          <div className="form-container">
            <Form
              useRef
              form={formPf}
              name="formPf"
              style={{ width: 800 }}
              onFinish={handleInvoiceFinish}
            >
              <Form.Item
                className="container-options"
                label=""
                name="pf_options"
                rules={[{ required: true, message: 'Informe uma resposta' }]}
              >
                <Radio.Group onChange={e => setActiveOption(e.target.value)}>
                  <Radio value="invoice">Nota Fiscal</Radio>
                  <Radio value="business_license">
                    Alvará de Funcionamento
                  </Radio>
                  <Radio value="professional_record">
                    Registro Profissional
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item className="container-options">
                <Button
                  danger
                  onClick={() => {
                    setPrevStep(true)
                    setPfOrPj(null)
                  }}
                >
                  cancelar
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      )}

      {/* {nextStepsLocal &&
                <div>
                    <div className='form-container'>
                        <Form
                            useRef
                            form={formPj}
                            name="formPJ"
                            style={{ width: 800 }}
                            onFinish={handleOnFinish}>
                            <>
                                <Form.Item
                                className='container-options'
                                label="Indique o tipo de taxa de desconto da transação (MDR)"
                                name="pj_options"
                                rules={[{ required: true, message: 'Informe uma resposta' }]}
                                >
                                <Radio.Group onChange={(e) => setActiveOption(e.target.value)}>
                                        <Radio value="mdr">MDR</Radio>
                                        <Radio value="mdrFlex">MDR FLEX</Radio>
                                    </Radio.Group>
                                </Form.Item>

                                {activeOption === 'mdr' &&
                                    <Form.Item
                                    className='container-options'
                                    label="Habilitar antecipação automatica ?"
                                    name="mdr_options"
                                    rules={[{ required: true, message: 'Informe uma resposta' }]}
                                    >
                                    <Radio.Group>
                                            <Radio value={true}>Habilitar</Radio>
                                            <Radio value={false}>Não Habilitar</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                }

                                {activeOption === 'mdrFlex' &&
                                    <Form.Item
                                    className='container-options'
                                    label="Prazo de 2 dias pra recebimentos ?"
                                    name="mdrFlex_options">
                                    <Checkbox checked={receiptDeadline} onChange={(e) => setReceiptDeadline(e.target.checked)} style={{ lineHeight: '32px' }} />
                                </Form.Item>    
                                }
                                <Form.Item className='form-container-product-add'>
                                    <Button type="primary" htmlType="submit">
                                        Próxima Etapa
                                    </Button>
                                </Form.Item>
                                
                                <Form.Item className='form-container-product-add'>
                                    <Button danger onClick={() =>{ setPrevStep(true)
                                     setNextStepLocal(false)
                                     setActiveOption(null)
                                     setPfOrPj(null)
                                    }}>
                                        cancelar
                                    </Button>
                                </Form.Item>
                            </>
                        </Form>
                    </div>          
                </div>
            }               */}

      {activeOption === 'invoice' && !nextStepsLocal && (
        <>
          <header
            className="portal-header"
            style={{
              marginBottom: '2em',
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center'
            }}
          >
            <img
              style={{ height: '50px' }}
              alt="x-payLogo"
              src={require('../../assets/img/logo.png')}
            ></img>
            <div style={{ fontSize: '16pt' }}>Portal de Credenciamento</div>
          </header>
          <p className="title-page-info"> Oferta</p>
          <p className="title-page-info">Nota fiscal</p>
          <div className="form-container">
            <Form
              useRef
              form={Invoiceform}
              name="basic"
              style={{ width: 800 }}
              onFinish={handleInvoiceFinish}
              onValuesChange={handleInvoiceFormChanges}
            >
              {invoices.map((element, index) => (
                <div key={index} className="form-container-product">
                  <Form.Item
                    label="Número da Nota Fiscal"
                    name={`invoice_number_${element}`}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Tipo de Produto"
                    name={`product_type_${element}`}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Data da emissão"
                    name={`emission_date_${element}`}
                  >
                    <DatePicker
                      placeholder="Selecione a data"
                      inputReadOnly={true}
                      format="DD/MM/YYYY"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Valor da nota fiscal"
                    name={`invoice_value_${element}`}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item wrapperCol={{ span: 6 }}>
                    <Button danger onClick={() => handleRemoveInvoice(element)}>
                      Remover Nota
                    </Button>
                  </Form.Item>
                </div>
              ))}
              <Form.Item className="form-container-product-add">
                <Button
                  width={300}
                  disabled={addInvoiceButton}
                  type="primary"
                  onClick={() => handleAddInvoice()}
                >
                  Incluir
                </Button>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Próxima Etapa
                </Button>
              </Form.Item>

              <Form.Item>
                <Button
                  danger
                  onClick={() => {
                    setActiveOption(null)
                    formPf.setFieldsValue({ options: null })
                  }}
                >
                  cancelar
                </Button>
              </Form.Item>
            </Form>
          </div>
        </>
      )}
      {activeOption === 'business_license' && !nextStepsLocal && (
        <>
          <header
            className="portal-header"
            style={{
              marginBottom: '2em',
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center'
            }}
          >
            <img
              style={{ height: '50px' }}
              alt="x-payLogo"
              src={require('../../assets/img/logo.png')}
            ></img>
            <div style={{ fontSize: '16pt' }}>Portal de Credenciamento</div>
          </header>
          <p className="title-page-info"> Oferta</p>
          <p className="title-page-info">Alvará de Funcionamento</p>
          <div className="form-container">
            <Form
              useRef
              form={businessLicenseForm}
              name="basic"
              style={{ width: 800 }}
              onFinish={handleOnFinish}
            >
              <Form.Item
                label="Número do Processo"
                name="process_number"
                rules={[
                  { required: true, message: 'Informe o número do processo' }
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Número do DML"
                name="dml_number"
                rules={[{ required: true, message: 'Informe o número do DML' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Validade do documento"
                name="document_date"
                rules={[
                  { required: true, message: 'Informe a validade do documento' }
                ]}
              >
                <DatePicker inputReadOnly={true} format="DD/MM/YYYY" />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Próxima Etapa
                </Button>
              </Form.Item>

              <Form.Item>
                <Button
                  danger
                  onClick={() => {
                    setActiveOption(null)
                    formPf.setFieldsValue({ options: null })
                  }}
                >
                  cancelar
                </Button>
              </Form.Item>
            </Form>
          </div>
        </>
      )}

      {activeOption === 'professional_record' && !nextStepsLocal && (
        <>
          <header
            className="portal-header"
            style={{
              marginBottom: '2em',
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center'
            }}
          >
            <img
              style={{ height: '50px' }}
              alt="x-payLogo"
              src={require('../../assets/img/logo.png')}
            ></img>
            <div style={{ fontSize: '16pt' }}>Portal de Credenciamento</div>
          </header>
          <p className="title-page-info"> Oferta</p>
          <p className="title-page-info">Registro Profissional</p>
          <div className="form-container">
            <Form
              useRef
              form={professionalRecordForm}
              name="basic"
              style={{ width: 800 }}
              onFinish={handleOnFinish}
            >
              <Form.Item
                label="Escolha o documento"
                name="document_type"
                rules={[
                  { required: true, message: 'Informe o tipo do documento' }
                ]}
              >
                <Select
                  style={{ width: 500 }}
                  options={[
                    { value: 'Carteirinha CRO', label: 'Carteirinha CRO' },
                    { value: 'Carteirinha OAB', label: 'Carteirinha OAB' },
                    { value: 'Carteirinha CRM', label: 'Carteirinha CRM' },
                    {
                      value: 'Carteirinha CONDUTAX',
                      label: 'Carteirinha CONDUTAX'
                    }
                  ]}
                />
              </Form.Item>

              <Form.Item
                label="Número do Documento"
                name="document_number"
                rules={[
                  { required: true, message: 'Informe o número do documento' }
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Nome da mãe"
                name="mother_name"
                rules={[{ required: true, message: 'Informe o nome da mãe' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Nacionalidade"
                name="professional_record_nacionality"
                rules={[{ required: true, message: 'Informe a nacionalidade' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Próxima Etapa
                </Button>
              </Form.Item>

              <Form.Item>
                <Button
                  danger
                  onClick={() => {
                    setActiveOption(null)
                    formPf.setFieldsValue({ options: null })
                  }}
                >
                  cancelar
                </Button>
              </Form.Item>
            </Form>
          </div>
        </>
      )}
      {nextSteps && <FeeEdit previousStepData={formData} />}

      {prevStep && <PersonalInfo formDataPreLoaded={previousStepData} />}
    </>
  )
}

export default OfferDataStepFive
