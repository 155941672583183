import React, { useContext, useEffect, useState } from 'react'
import cep from 'cep-promise'
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Row,
  TimePicker
} from 'antd'
import { AddressTypeContainer, AddressTypeContainerTitle } from '../styles'
import { maskCep, onlyNumbers } from '../../../utils/masks'
import {
  RegisterEcContext,
  StepRegisterEC
} from '../../../Context/register-ec-provider'
import dayjs from 'dayjs'

export const Step2Address = () => {
  const { data, changeStep } = useContext(RegisterEcContext)
  const [form] = Form.useForm()

  const formMaskFunctions = {
    cep: value => maskCep(value),
    uf: value => value.toUpperCase().replace(/[^a-zA-Z]+/g, ''),
    residential_cep: value => maskCep(value),
    residential_uf: value => value.toUpperCase().replace(/[^a-zA-Z]+/g, ''),
    trading_cep: value => maskCep(value),
    trading_uf: value => value.toUpperCase().replace(/[^a-zA-Z]+/g, '')
  }

  const getAddressData = async (value, inputName) => {
    try {
      const currentFormInitialValues = form.getFieldsValue()

      const cleanCep = onlyNumbers(value)
      const addressData = await cep(cleanCep)

      if (inputName === 'bussiness') {
        currentFormInitialValues.street = addressData.street
        currentFormInitialValues.uf = addressData.state
        currentFormInitialValues.city = addressData.city
        currentFormInitialValues.neighborhood = addressData.neighborhood
      } else {
        currentFormInitialValues[`${inputName}_street`] = addressData.street
        currentFormInitialValues[`${inputName}_uf`] = addressData.state
        currentFormInitialValues[`${inputName}_city`] = addressData.city
        currentFormInitialValues[`${inputName}_neighborhood`] =
          addressData.neighborhood
      }

      form.setFieldsValue(currentFormInitialValues)
    } catch (err) {
      const currentFormInitialValues = form.getFieldsValue()

      if (inputName === 'bussiness') {
        currentFormInitialValues.street = ''
        currentFormInitialValues.uf = ''
        currentFormInitialValues.city = ''
        currentFormInitialValues.neighborhood = ''
      } else {
        currentFormInitialValues[`${inputName}_street`] = ''
        currentFormInitialValues[`${inputName}_uf`] = ''
        currentFormInitialValues[`${inputName}_city`] = ''
        currentFormInitialValues[`${inputName}_neighborhood`] = ''
      }

      form.setFieldsValue(currentFormInitialValues)
    }
  }

  const handleInputChange = async inputChanged => {
    const currentFormInitialValues = form.getFieldsValue()

    const inputName = Object.keys(inputChanged)[0]
    const inputValue = inputChanged[inputName]

    if (formMaskFunctions[inputName]) {
      currentFormInitialValues[inputName] =
        formMaskFunctions[inputName](inputValue)
      form.setFieldsValue(currentFormInitialValues)
    }

    if (
      (inputName === 'cep' ||
        inputName === 'residential_cep' ||
        inputName === 'trading_cep' ||
        inputName === 'fiscal_cep') &&
      inputValue.length === 9
    ) {
      let input = 'bussiness'

      const inputNameSplited = inputName.split('_')
      if (inputNameSplited[1]) {
        input = inputNameSplited[0]
      }

      getAddressData(inputValue, input)
    }
  }

  const handleOnFinish = dataStep => {
    const workingHours = []

    dataStep.working_hours_week_days_start = dayjs(
      dataStep.working_hours_week_days_start
    ).format('HH:mm')
    dataStep.working_hours_week_days_end = dayjs(
      dataStep.working_hours_week_days_end
    ).format('HH:mm')

    dataStep.working_hours_week_days.forEach(day => {
      if (day !== '') {
        workingHours.push({
          from: dataStep.working_hours_week_days_start,
          to: dataStep.working_hours_week_days_end,
          weekDay: day
        })
      }
    })

    const payload = {
      workingDays: workingHours,
      working_hours_close_for_lunch:
        dataStep?.working_hours_close_for_lunch !== null
          ? dataStep?.working_hours_close_for_lunch
          : null,
      address: [
        {
          street: dataStep.street,
          number: dataStep.number,
          neighborhood: dataStep.neighborhood,
          complement: dataStep.complement,
          city: dataStep.city,
          place_reference: dataStep.place_reference,
          state: dataStep.uf,
          type: 'BUSINESS',
          zipCode: onlyNumbers(dataStep.cep),
          email: data.email,
          fixedDdd: data.fixedDdd,
          fixedNumber: data.fixedNumber,
          mobileDdd: data.mobileDdd,
          mobileNumber: data.mobileNumber
        }
      ]
    }

    changeStep({ step: StepRegisterEC.Step3Responsabel, data: payload })
  }

  useEffect(() => {
    if (!Object.keys(data).length) {
      return
    }
    const address = data?.address || []
    if (!address.length) {
      return
    }

    const addressBusiness = address[0]
    const workingDays = data?.workingDays || []

    const weekHours = workingDays[0] || {}

    const dados = {
      cep: maskCep(addressBusiness.zipCode),
      street: addressBusiness.street,
      city: addressBusiness.city,
      uf: addressBusiness.state,
      neighborhood: addressBusiness.neighborhood,
      complement: addressBusiness.complement,
      place_reference: addressBusiness.place_reference,
      number: addressBusiness.number,
      working_hours_week_days: workingDays.map(day => day.weekDay),
      working_hours_week_days_start: weekHours?.from
        ? dayjs(weekHours.from, 'HH:mm')
        : '',
      working_hours_week_days_end: weekHours?.to
        ? dayjs(weekHours.to, 'HH:mm')
        : '',
      working_hours_close_for_lunch:
        data?.working_hours_close_for_lunch !== null
          ? data?.working_hours_close_for_lunch
          : null
    }

    form.setFieldsValue(dados)
  }, [data, form])

  const allWeekDaysChange = e => {
    const currentFormInitialValues = form.getFieldsValue()
    if (e.target.checked) {
      currentFormInitialValues.working_hours_week_days = [
        'THURSDAY',
        'MONDAY',
        'TUESDAY',
        'FRIDAY',
        'WEDNESDAY'
      ]
    } else {
      currentFormInitialValues.working_hours_week_days = []
    }

    form.setFieldsValue(currentFormInitialValues)
  }

  return (
    <div className="form-container">
      <Form
        useRef
        form={form}
        name="basic"
        style={{ width: '60%' }}
        onFinish={handleOnFinish}
        onValuesChange={handleInputChange}
      >
        <Form.Item
          label="CEP"
          name="cep"
          rules={[{ required: true, message: 'Informe o CEP' }]}
        >
          <Input maxLength={9} />
        </Form.Item>
        <Form.Item
          label="Endereço"
          name="street"
          rules={[{ required: true, message: 'Informe o Endereço.' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Número"
          name="number"
          rules={[{ required: true, message: 'Informe o Número' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Complemento" name="complement">
          <Input />
        </Form.Item>
        <Form.Item
          label="Bairro"
          name="neighborhood"
          rules={[{ required: true, message: 'Informe o Bairro.' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Cidade"
          name="city"
          rules={[{ required: true, message: 'Informe a Cidade.' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Estado"
          name="uf"
          rules={[{ required: true, message: 'Informe o Estado.' }]}
        >
          <Input maxLength={2} />
        </Form.Item>

        <Form.Item label="Ponto de referencia" name="place_reference">
          <Input />
        </Form.Item>

        <Form.Item name="all_week_days" label="Dias Úteis">
          <Checkbox onChange={allWeekDaysChange} style={{ lineHeight: '32px' }}>
            Todos
          </Checkbox>
        </Form.Item>
        <Form.Item
          name="working_hours_week_days"
          label=" "
          rules={[{ required: true, message: 'Informe os dias úteis' }]}
        >
          <Checkbox.Group>
            <Row>
              <Col span={8}>
                <Checkbox value="MONDAY" style={{ lineHeight: '32px' }}>
                  Segunda-Feira
                </Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value="TUESDAY" style={{ lineHeight: '32px' }}>
                  Terça-Feira
                </Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value="WEDNESDAY" style={{ lineHeight: '32px' }}>
                  Quarta-Feira
                </Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value="THURSDAY" style={{ lineHeight: '32px' }}>
                  Quinta-Feira
                </Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox value="FRIDAY" style={{ lineHeight: '32px' }}>
                  Sexta-Feira
                </Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
        <Row>
          <Col>
            <Form.Item
              name="working_hours_week_days_start"
              label="Abre às"
              rules={[
                {
                  required: true,
                  message: 'Informe o inicio do horário de funcionamento'
                }
              ]}
            >
              <TimePicker format="HH:mm" needConfirm={false} />
            </Form.Item>
          </Col>
          <Col offset={2}>
            <Form.Item
              name="working_hours_week_days_end"
              label="Fecha às"
              rules={[
                {
                  required: true,
                  message: 'Informe o final do horário de funcionamento'
                }
              ]}
            >
              <TimePicker format="HH:mm" needConfirm={false} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Fecha para almoço?"
          name="working_hours_close_for_lunch"
          rules={[
            { required: true, message: 'Informe se fecha para o almoço' }
          ]}
        >
          <Radio.Group>
            <Radio value={true}>Sim</Radio>
            <Radio value={false}>Não</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Próxima Etapa
          </Button>
        </Form.Item>

        <Form.Item>
          <Button
            danger
            onClick={() => changeStep({ step: StepRegisterEC.Step1Company })}
          >
            Voltar
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
