import { Form, Input, Switch } from 'antd'
import Modal from '../../components/modal'
import { api } from '../../providers/api'
import { useCallback, useState } from 'react'

export function AddCnaeModal({ isOpen, onClose, refresh }) {
  const [addForm] = Form.useForm()
  const [isLoading, setLoading] = useState(false)

  const onSubmit = useCallback(
    async form => {
      if (isLoading) return
      try {
        setLoading(true)
        await api.post('/accreditation/cnaes', form)
        onClose()
        addForm.resetFields()
        await refresh?.()
      } catch (error) {
        alert(
          error?.response?.data?.message ||
            'Algo aconteceu, não foi possível salvar este CNAE'
        )
      } finally {
        setLoading(false)
      }
    },
    [addForm, isLoading, onClose, refresh]
  )

  const closeModal = useCallback(() => {
    onClose()
    addForm.resetFields()
  }, [addForm, onClose])

  return (
    <Modal
      title="Adicionar CNAE"
      isVisible={isOpen}
      closeModal={closeModal}
      onConfirm={addForm.submit}
    >
      <Form form={addForm} onFinish={onSubmit} style={{ padding: 32 }}>
        <Form.Item
          label="CNAE"
          name="cnae"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Input maxLength={7} />
        </Form.Item>
        <Form.Item
          label="Categoria de Risco"
          name="riskCategory"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="MCC(núm)"
          name="mcc"
          rules={[{ required: true, message: 'Campo obrigatório' }]}
        >
          <Input maxLength={4} />
        </Form.Item>
        <Form.Item label="Nome MCC" name="nomeMCC">
          <Input />
        </Form.Item>
        <Form.Item label="Grupo" name="grupo">
          <Input />
        </Form.Item>
        <Form.Item label="SubGrupo" name="subGrupo">
          <Input />
        </Form.Item>
        <Form.Item label="Inativo" name="inativado" initialValue={false}>
          <Switch />
        </Form.Item>
        <Form.Item
          label="Cartão Não Presente"
          name="cartaoNaoPresente"
          initialValue={false}
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label="Compra Online"
          name="compraOnline"
          initialValue={false}
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label="Máquina Presencial"
          name="maquinaPresencial"
          initialValue={false}
        >
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  )
}
