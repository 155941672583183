import React, { useContext, useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Select,
  Switch
} from 'antd'
import { PersonalInfo } from '../01-personal-info/index'
import { cleanMoneyValue, maskCurrency } from '../../utils/masks'
import { UserContext } from '../../Context/aplicationProvider'
import FeeEdit from '../06-fee-edit/index'
import './style.scss'
import AppLayout from '../../components/app-layout/app-layout'

const CaptureSolutions = props => {
  const { technologies, products, setProducts, filterTechnologies } =
    useContext(UserContext)

  useEffect(() => {
    filterTechnologies('BOTH')
  }, [filterTechnologies])

  const { previousStepData } = props
  const [nextSteps, setNextStep] = useState(false)
  const [prevStep, setPrevStep] = useState(false)
  const [hasProductLuxury, setHasProductLuxury] = useState(false)
  const [formData, setFormData] = useState({})
  const transactionType = [
    { label: 'Presencial', value: 'TRADITIONAL' },
    { label: 'Online', value: 'E_COMMERCE' },
    { label: 'Link de Pagamento', value: 'LINK_PAYMENT' }
  ]
  const [form] = Form.useForm()
  const [formInitialValues, setFormInitialValues] = useState({
    edi: false,
    foreignCard: false,
    recurringTransaction: false
  })

  const handleRemoveTech = product => {
    const currentProdutos = [...products]

    if (currentProdutos.length > 1) {
      const findIndex = currentProdutos.findIndex(
        element => element === product
      )

      currentProdutos.splice(findIndex, 1)

      setProducts(currentProdutos)
    }
  }

  const handleInputChange = async inputChanged => {
    const currentFormInitialValues = form.getFieldsValue()

    const inputName = Object.keys(inputChanged)[0]

    const splited = inputName.split('_')

    const inputNameSplited = `${splited[0]}`

    const formMaskFunctions = {
      value: value => maskCurrency(value)
    }

    if (formMaskFunctions[inputNameSplited]) {
      currentFormInitialValues[inputName] = formMaskFunctions[inputNameSplited](
        inputChanged[inputName]
      )
      form.setFieldsValue(currentFormInitialValues)
    }

    setFormInitialValues(currentFormInitialValues)

    if (inputName === 'transaction_type') {
      filterTechnologies(inputChanged[inputName])
    }

    if (inputNameSplited === 'tech') {
      const findTech = technologies.find(
        element => element.idService === inputChanged[inputName]
      )

      if (findTech) {
        if (findTech.technologyGroup === 'COM') {
          for (let i = 0; i < products.length; i++) {
            if (i !== Number(splited[1])) {
              const currentProducts = [...products]
              currentProducts[i].tech = technologies.filter(
                element => element.technologyGroup === 'COM'
              )
              setProducts(currentProducts)
            }
          }
        } else {
          for (let i = 0; i < products.length; i++) {
            if (i !== Number(splited[1])) {
              const currentProducts = [...products]
              currentProducts[i].tech = technologies.filter(
                element =>
                  element.technologyGroup === 'POS' ||
                  element.technologyGroup === 'ISO'
              )
              setProducts(currentProducts)
            }
          }
        }
      }
    }
  }

  const handleOnFinish = data => {
    const cuurentTechnologies = []

    for (let i = 0; i < products.length; i++) {
      cuurentTechnologies.push({
        serviceId: data[`tech_${i}`],
        numberOfTerminals: Number(data[`tech_count_${i}`]),
        value:
          formInitialValues.transaction_type === 'TRADITIONAL'
            ? cleanMoneyValue(data[`value_${i}`])
            : 0,
        mobileOperator: '01'
      })
    }

    const percentageCardPresent =
      formInitialValues.transaction_type === 'BOTH'
        ? data.percentageCardPresent
        : formInitialValues.transaction_type === 'TRADITIONAL'
          ? 100
          : 0

    const payload = {
      ...previousStepData,
      captureSolutions: cuurentTechnologies,
      foreignCard: data.foreignCard,
      percentageCardPresent,
      recurringTransaction: data.recurringTransaction,
      ecommerce: data.transaction_type
    }

    if (data.edi) {
      payload.ediAdditionalData = {
        hasEdi: data.edi
      }
    }

    setFormData(payload)
    setNextStep(true)
  }

  return (
    <>
      {!nextSteps && !prevStep && (
        <AppLayout>
          <p className="title-page-info"> Oferta</p>
          <p className="title-page-info"> Produto e configurações:</p>
          <div className="form-container">
            <Form
              useRef
              form={form}
              name="basic"
              onFinish={handleOnFinish}
              onValuesChange={handleInputChange}
              initialValues={formInitialValues}
              style={{ width: 800 }}
            >
              <div>
                <Form.Item label="Produtos de Luxo">
                  <Switch
                    checked={hasProductLuxury}
                    onChange={setHasProductLuxury}
                  />
                </Form.Item>
                {hasProductLuxury && (
                  <div className="list-luxury-products">
                    <div>
                      <Form.Item label="Descrição do produto">
                        <Input max={500} />
                      </Form.Item>
                      <Form.Item label="Código do produto">
                        <Input max={255} />
                      </Form.Item>
                      <Form.Item label="Quantidade de unidade autorizada por venda">
                        <Input type="number" min={1} />
                      </Form.Item>
                      <Form.Item label="Valor máximo de venda para este tipo de produto">
                        <Input type="number" min={1} />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item label="Descrição do produto">
                        <Input max={500} />
                      </Form.Item>
                      <Form.Item label="Código do produto">
                        <Input max={255} />
                      </Form.Item>
                      <Form.Item label="Quantidade de unidade autorizada por venda">
                        <Input type="number" min={1} />
                      </Form.Item>
                      <Form.Item label="Valor máximo de venda para este tipo de produto">
                        <Input type="number" min={1} />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item label="Descrição do produto">
                        <Input max={500} />
                      </Form.Item>
                      <Form.Item label="Código do produto">
                        <Input max={255} />
                      </Form.Item>
                      <Form.Item label="Quantidade de unidade autorizada por venda">
                        <Input type="number" min={1} />
                      </Form.Item>
                      <Form.Item label="Valor máximo de venda para este tipo de produto">
                        <Input type="number" min={1} />
                      </Form.Item>
                    </div>
                  </div>
                )}

                <Form.Item label="Extrato Eletrônico (EDI)" name="edi">
                  <Checkbox
                    checked={form.getFieldValue('edi')}
                    onChange={e => {
                      form.setFieldValue('edi', e.target.checked)
                      setFormInitialValues({
                        ...formInitialValues,
                        edi: e.target.checked
                      })
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="recurringTransaction"
                  label="Aceitar Transações Recorrentes"
                >
                  <Checkbox
                    checked={form.getFieldValue('recurringTransaction')}
                    onChange={e => {
                      form.setFieldValue(
                        'recurringTransaction',
                        e.target.checked
                      )
                      setFormInitialValues({
                        ...formInitialValues,
                        recurringTransaction: e.target.checked
                      })
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="foreignCard"
                  label="Aceitar Cartão Estrangeiro"
                >
                  <Checkbox
                    checked={form.getFieldValue('foreignCard')}
                    onChange={e => {
                      form.setFieldValue('foreignCard', e.target.checked)
                      setFormInitialValues({
                        ...formInitialValues,
                        foreignCard: e.target.checked
                      })
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="Tipo de transações"
                  name={`transaction_type`}
                  rules={[
                    { required: true, message: 'Informe o tipo de transação.' }
                  ]}
                >
                  <Select style={{ width: 500 }} options={transactionType} />
                </Form.Item>

                {formInitialValues.transaction_type === 'BOTH' && (
                  <Form.Item
                    name="percentageCardPresent"
                    label="Porcentagem de Vendas Presenciais"
                    rules={[
                      {
                        required: true,
                        message: 'Informe a porcetangem de vendas presenciais'
                      }
                    ]}
                  >
                    <InputNumber
                      type="number"
                      prefix="%"
                      min={0.1}
                      max={100}
                      placeholder="000"
                    />
                  </Form.Item>
                )}
                {products.map((element, index) => (
                  <div key={index} className="form-container-product">
                    <Form.Item
                      label="Selecione a Tecnologia"
                      name={`tech_${index}`}
                      rules={[
                        { required: true, message: 'Informe a tecnologia.' }
                      ]}
                      notFoundContent="Selecione o tipo de transação para carregar as tecnologias"
                    >
                      <Select
                        style={{ width: 500 }}
                        options={element.tech.map(element => ({
                          value: element.idService,
                          label: `${element.tradeName} (${element.technologyGroup})`
                        }))}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Selecione a Quantidade"
                      name={`tech_count_${index}`}
                      rules={[
                        { required: true, message: 'Informe a quantidade.' }
                      ]}
                    >
                      <InputNumber
                        type="number"
                        precision={0}
                        min={1}
                        step={1}
                      />
                    </Form.Item>
                    {form.getFieldValue(`transaction_type`) ===
                      'TRADITIONAL' && (
                      <Form.Item
                        label="Valor unitário"
                        name={`value_${index}`}
                        rules={[
                          {
                            required: true,
                            message: 'Informe o valor unitário.'
                          }
                        ]}
                      >
                        <Input style={{ width: 200 }} />
                      </Form.Item>
                    )}
                    {index !== 0 && (
                      <Form.Item wrapperCol={{ span: 6 }}>
                        <Button
                          danger
                          onClick={() => handleRemoveTech(element)}
                        >
                          Remover Tecnologia
                        </Button>
                      </Form.Item>
                    )}
                  </div>
                ))}
              </div>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Próxima Etapa
                </Button>
              </Form.Item>

              <Form.Item>
                <Button danger onClick={() => setPrevStep(true)}>
                  cancelar
                </Button>
              </Form.Item>
            </Form>
          </div>
        </AppLayout>
      )}
      {prevStep && <PersonalInfo formDataPreLoaded={previousStepData} />}
      {nextSteps && (
        <FeeEdit previousStepData={formData} techs={technologies} />
      )}
    </>
  )
}

export default CaptureSolutions
