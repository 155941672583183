import styled from 'styled-components'

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: ${props => (props.isVisible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 999;
`

const Body = styled.div`
  background-color: #fff;
  color: #333;
  position: absolute;
  margin: auto;
  min-width: 30%;
  min-height: 40%;
  max-height: 80%;
  border: none;
  border-radius: 10px;
  overflow-x: auto;
  box-shadow:
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
`

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  margin: 0.5em;
  background-color: transparent;
  border: none;
  cursor: pointer;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`
const Content = styled.div``

const Footer = styled.div`
  position: absolute;
  padding: 10px;
  bottom: 0;
  right: 0;
`

const FooterButton = styled.button`
  margin-left: 3px;
  color: ${props => (props.primary ? '#fff' : '#1677ff')};
  background-color: ${props => (props.primary ? '#1677ff' : '#fff')};
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
  font-size: 14px;
  padding: 4px 10px;
  border-radius: 6px;
  font-weight: 400;
  border: ${props =>
    props.primary ? '1px solid transparent' : '1px solid #1677ff'};
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  cursor: pointer;
`

const Title = styled.h1`
  font-size: 24px;
`

export {
  Container,
  Body,
  CloseButton,
  Header,
  Footer,
  FooterButton,
  Content,
  Title
}
