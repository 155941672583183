import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { Button, Checkbox, Form, Input, InputNumber, Radio, Switch } from 'antd'
import { maskCep, onlyNumbers } from '../../../utils/masks'
import {
  RegisterEcContext,
  StepRegisterEC
} from '../../../Context/register-ec-provider'
import { AddressTypeContainer, AddressTypeContainerTitle } from '../styles'

export const Step4Product = () => {
  const { changeStep, data } = useContext(RegisterEcContext)
  const [technologies, setTechnologies] = useState([])
  const [transactionTypes, setTransactionTypes] = useState([])
  const [receiveMachine, setReceiveMachine] = useState(true)
  const [isVoucher, setIsVoucher] = useState(false)
  const [vouchers, setVouchers] = useState([])

  const [form] = Form.useForm()

  const formMaskFunctions = useMemo(
    () => ({
      trading_cep: value => maskCep(value)
    }),
    []
  )

  const handleInputChange = useCallback(
    inputChanged => {
      const inputName = Object.keys(inputChanged)[0]
      const inputValue = inputChanged[inputName]

      if (inputName === 'transaction_type') {
        setTransactionTypes(inputValue)
      }

      if (inputName === 'techs') {
        setTechnologies(inputValue)
      }

      if (inputName === 'receive_machine') {
        setReceiveMachine(inputValue)
      }

      if (inputName === 'is_voucher') {
        setIsVoucher(inputValue)
      }

      if (formMaskFunctions[inputName]) {
        form.setFieldValue(inputName, formMaskFunctions[inputName](inputValue))
      } else {
        form.setFieldValue(inputName, inputValue)
      }
    },
    [form, formMaskFunctions]
  )

  const handleOnFinish = useCallback(
    dataStep => {
      const payload = {
        captureSolutions: [],
        foreignCard: dataStep.foreignCard,
        recurringTransaction: dataStep.recurringTransaction,
        ecommerce: transactionTypes || [],
        address: data.address,
        receive_machine: dataStep.receive_machine,
        tech_count_pos_android: dataStep?.tech_count_pos_android || 0,
        tech_count_pos_linux: dataStep?.tech_count_pos_linux || 0,
        tech_count_pinpad: dataStep?.tech_count_pinpad || 0,
        is_voucher: dataStep?.is_voucher,
        vouchers
      }

      if (!dataStep.receive_machine) {
        const address = {
          street: dataStep.trading_street,
          number: dataStep.trading_number,
          neighborhood: dataStep.trading_neighborhood,
          complement: dataStep.trading_complement,
          city: dataStep.trading_city,
          state: dataStep.trading_uf,
          type: 'TRADING',
          zipCode: onlyNumbers(dataStep.trading_cep)
        }
        const tradingAddressIndex = payload.address.findIndex(
          addr => addr.type === 'TRADING'
        )

        if (tradingAddressIndex > -1) {
          Object.assign(payload.address[tradingAddressIndex], address)
        } else {
          payload.address.push(address)
        }
      }

      if (dataStep?.techs?.includes('POS')) {
        const items = [
          {
            id: 'POS',
            type: 'ANDROID',
            qtd: dataStep?.tech_count_pos_android || 0
          },
          {
            id: 'POS',
            type: 'LINUX',
            qtd: dataStep?.tech_count_pos_linux || 0
          }
        ]
        payload.captureSolutions = payload.captureSolutions.concat(items)
      }

      if (dataStep?.techs?.includes('PINPAD')) {
        const items = [
          {
            id: 'PINPAD',
            type: '',
            qtd: dataStep?.tech_count_pinpad || 0
          }
        ]
        payload.captureSolutions = payload.captureSolutions.concat(items)
      }

      changeStep({ step: StepRegisterEC.Step5Bank, data: payload })
    },
    [changeStep, data, vouchers, transactionTypes]
  )

  const handleAddVoucher = useCallback(() => {
    setVouchers(old => [...(old || []), { bandeira: '', codeEc: '' }])
  }, [])

  const handleUpdateVoucher = useCallback((index, prop, value) => {
    setVouchers(prev => {
      const newVouchers = [...prev]
      if (newVouchers[index]) {
        newVouchers[index] = { ...newVouchers[index], [prop]: value }
      }
      return newVouchers
    })
  }, [])

  const handleRemoveVoucher = useCallback(index => {
    setVouchers(prev => prev.filter((_, indexPrev) => indexPrev !== index))
  }, [])

  const validateAtLeastOneField = () => {
    const tech_count_pos_linux = form.getFieldValue('tech_count_pos_linux')
    const tech_count_pos_android = form.getFieldValue('tech_count_pos_android')

    if (
      (!tech_count_pos_linux || tech_count_pos_linux <= 0) &&
      (!tech_count_pos_android || tech_count_pos_android <= 0)
    ) {
      return Promise.reject(
        new Error(
          'Pelo menos um dos campos deve ser preenchido e ser maior que zero!'
        )
      )
    }
    return Promise.resolve()
  }

  useEffect(() => {
    const address = data?.address || []
    const trading = address.find(addr => addr.type === 'TRADING') || {}
    const dados = {
      recurringTransaction: data?.recurringTransaction,
      foreignCard: data?.foreignCard,
      transaction_type: data?.ecommerce || [],
      receive_machine:
        typeof data?.receive_machine === 'boolean'
          ? data?.receive_machine
          : true,
      trading_street: trading.street || '',
      trading_number: trading?.number || '',
      trading_neighborhood: trading?.neighborhood || '',
      trading_complement: trading?.complement || '',
      trading_city: trading?.city || '',
      trading_uf: trading?.state || '',
      trading_cep: maskCep(trading?.zipCode || ''),
      techs: [...new Set(data?.captureSolutions?.map(cap => cap.id))],
      is_voucher: data?.is_voucher
    }

    const captureSolutions = data?.captureSolutions || []

    captureSolutions.forEach(({ id: tech, type, qtd }) => {
      const techLower = tech.toLowerCase()
      const typeTech = type.toLowerCase()
      dados[`tech_count_${techLower}${typeTech ? `_${typeTech}` : ''}`] =
        qtd || 0
    })

    setTransactionTypes(dados?.transaction_type || [])
    setTechnologies(dados?.techs || [])
    setReceiveMachine(dados?.receive_machine)
    setIsVoucher(data?.is_voucher)
    setVouchers(data?.vouchers)

    data?.vouchers?.forEach((item, index) => {
      dados[`voucher_${index}_bandeira`] = item.bandeira || ''
      dados[`voucher_${index}_cod_ec`] = item.codeEc || ''
    })

    form.setFieldsValue(dados)
  }, [form, data])

  return (
    <div className="form-container">
      <Form
        useRef
        form={form}
        name="basic"
        onFinish={handleOnFinish}
        onValuesChange={handleInputChange}
        style={{ width: 800 }}
      >
        <Form.Item
          name="recurringTransaction"
          valuePropName="checked"
          label="Aceitar Transações Recorrentes"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name="foreignCard"
          valuePropName="checked"
          label="Aceitar Cartão Estrangeiro"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          label="Tipo de transações"
          name="transaction_type"
          rules={[{ required: true, message: 'Informe o tipo de transação.' }]}
        >
          <Checkbox.Group>
            <Checkbox value="TRADITIONAL">Presencial</Checkbox>
            <Checkbox value="LINK_PAYMENT">Link de pagamento</Checkbox>
          </Checkbox.Group>
        </Form.Item>
        <div className="form-container-product">
          <Form.Item
            label="Selecione a Tecnologia"
            name="techs"
            rules={[{ required: true, message: 'Informe a(s) tecnologia(s).' }]}
          >
            <Checkbox.Group>
              <Checkbox value="POS">POS</Checkbox>
              <Checkbox value="PINPAD">PINPAD</Checkbox>
            </Checkbox.Group>
          </Form.Item>

          {technologies?.includes('POS') && (
            <>
              <Form.Item
                label="POS (Linux) - Selecione a quantidade"
                name="tech_count_pos_linux"
                rules={[
                  {
                    validator: validateAtLeastOneField,
                    message: 'Informe a quantidade android ou linux.'
                  }
                ]}
              >
                <InputNumber
                  type="number"
                  placeholder="0"
                  precision={0}
                  step={1}
                  min={0}
                />
              </Form.Item>
              <Form.Item
                label="POS (Android) - Selecione a quantidade"
                name="tech_count_pos_android"
                rules={[
                  {
                    validator: validateAtLeastOneField,
                    message: 'Informe a quantidade android ou linux.'
                  }
                ]}
              >
                <InputNumber
                  type="number"
                  placeholder="0"
                  precision={0}
                  step={1}
                  min={0}
                />
              </Form.Item>
            </>
          )}
          {technologies?.includes('PINPAD') && (
            <Form.Item
              label="PinPad - Selecione a quantidade"
              name="tech_count_pinpad"
              rules={[{ required: true, message: 'Informe a quantidade.' }]}
            >
              <InputNumber type="number" precision={0} min={1} step={1} />
            </Form.Item>
          )}

          <Form.Item label="Vai utilizar Voucher?" name="is_voucher">
            <Switch />
          </Form.Item>

          {isVoucher && (
            <div>
              <Button
                type="primary"
                htmlType="button"
                style={{ marginBottom: 16 }}
                onClick={handleAddVoucher}
              >
                Adicionar novo voucher
              </Button>

              {vouchers?.map((_, index) => (
                <div key={index} style={{ display: 'flex', gap: 8 }}>
                  <Form.Item
                    label="Bandeira"
                    name={`voucher_${index}_bandeira`}
                  >
                    <Input
                      onChange={e =>
                        handleUpdateVoucher(index, 'bandeira', e.target.value)
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    label="Cod. do Estabelecimento"
                    name={`voucher_${index}_cod_ec`}
                  >
                    <Input
                      onChange={e =>
                        handleUpdateVoucher(index, 'codeEc', e.target.value)
                      }
                    />
                  </Form.Item>

                  <Button
                    danger
                    htmlType="button"
                    onClick={() => handleRemoveVoucher(index)}
                  >
                    Remover voucher
                  </Button>
                </div>
              ))}
            </div>
          )}
        </div>
        <Form.Item
          label="O endereço de entrega do equipamento físico é o mesmo do endereço comercial ?"
          name="receive_machine"
          rules={[{ required: true, message: 'Informe uma resposta' }]}
        >
          <Radio.Group>
            <Radio value={true}>Sim</Radio>
            <Radio value={false}>Não</Radio>
          </Radio.Group>
        </Form.Item>
        {typeof receiveMachine === 'boolean' && !receiveMachine && (
          <AddressTypeContainer>
            <AddressTypeContainerTitle>
              Informe o endereço que deseja receber a maquininha
            </AddressTypeContainerTitle>
            <Form.Item
              label="CEP"
              name="trading_cep"
              rules={[{ required: true, message: 'Informe o CEP' }]}
            >
              <Input maxLength={9} />
            </Form.Item>
            <Form.Item
              label="Endereço"
              name="trading_street"
              rules={[{ required: true, message: 'Informe o Endereço.' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Número"
              name="trading_number"
              rules={[{ required: true, message: 'Informe o Número' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Complemento" name="trading_complement">
              <Input />
            </Form.Item>

            <Form.Item
              label="Bairro"
              name="trading_neighborhood"
              rules={[{ required: true, message: 'Informe o Bairro.' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Cidade"
              name="trading_city"
              rules={[{ required: true, message: 'Informe a Cidade.' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Estado"
              name="trading_uf"
              rules={[{ required: true, message: 'Informe o Estado.' }]}
            >
              <Input maxLength={2} />
            </Form.Item>
          </AddressTypeContainer>
        )}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Próxima Etapa
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            danger
            onClick={() =>
              changeStep({ step: StepRegisterEC.Step3Responsabel })
            }
          >
            Voltar
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
