import { api, getHeaders } from './index'

export const getCnpjInfo = async cnpj => {
  try {
    const response = await api.get(`/cnpj/${cnpj}`, {
      headers: getHeaders()
    })
    return response.data
  } catch (err) {
    throw err
  }
}

export const getCnpjCnaes = async cnpj => {
  try {
    const response = await api.get(`/cnpj/cnaes/${cnpj}`, {
      headers: getHeaders()
    })
    return response.data
  } catch (err) {
    throw err
  }
}

export const getCnaes = async () => {
  try {
    const response = await api.get(`/fiserv/cnaes`, {
      headers: getHeaders()
    })
    return response.data
  } catch (err) {
    throw err
  }
}
