import styled from 'styled-components'

const ItemsWrapper = styled.div`
  margin-top: 2.5em;
`

const Item = styled.div`
  background-color: white;
  padding: 2em;
  width: 50em;
  box-shadow: 0px 0px 8px -1px rgba(209, 209, 209, 1);
  margin-bottom: 2em;
`

const ItemStatusWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
`

const ItemInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.3em;
`

export { ItemsWrapper, Item, ItemInfoWrapper, ItemStatusWrapper }
